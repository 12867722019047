/* eslint-disable no-undef */
/* eslint-disable no-dupe-keys */
import { PlusIcon , DeleteIcon } from "../../EntryFile/imagePath";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import React, { useEffect, useState , useRef} from "react";
import { Link , useHistory} from "react-router-dom";
import "../../assets/plugins/twitter-bootstrap-wizard/form-wizard.css";
import "../../assets/plugins/twitter-bootstrap-wizard/jquery.bootstrap.wizard.min.js";
import "../../assets/plugins/twitter-bootstrap-wizard/prettify.js";
import "../../assets/plugins/twitter-bootstrap-wizard/form-wizard.js";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import { baseURL } from "../../InitialPage/Authentecation.jsx";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

let i = 0 ; 
let j = 0 ; 
let y = 0 ; 
let z = 0 ;
const Addsales = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const [str, setStr] = useState("seller-details");
  let options1 = []; 
  const [options11 , setOptions11] = useState([]);
  const newName = "text" ;
  const newName2 = "id";

  options11.map(item =>{
    options1.push({
      [newName] : item.NameEn , 
      [newName2] : item.Id
    })
  })

  let options2 = [];
  const [options22 , setOptions22] = useState([]);

  options22.map(item =>{
    options2.push({
      [newName] : item.NameAr , 
      [newName2] : item.Id
    })
  })

  let options3 = [] ;
  const [options33 , setOptions33] = useState([]);

  options33.map(item =>{
    options3.push({
      [newName] : item.modelEn , 
      "id" : item.id
    })
  })

  let options4 = [] ; 
  const [options44 , setOptions44] = useState([]);

  options44.map(item => {
    options4.push({
    [newName] : item.scheduleEn,
    "id" : item.id
    })
  })
  useEffect(()=>{
    // To get Job Category 
    axios.get(`${baseURL}/Careers/JobCategory?WithImages=false&PageIndex=1&PageSize=90`,{
      headers : {
        Authorization : `Bearer ${window.localStorage.getItem('result')}`
      }
    }).then(res => setOptions11(res.data.result)).catch(err => err);

    // To get Company 
    axios.get(`${baseURL}/Careers/Company?WithImages=false&PageIndex=1&PageSize=90`,{
      headers :{
        Authorization : `Bearer ${window.localStorage.getItem('result')}`
      }
    }).then(res => setOptions22(res.data.result)).catch(err => err);

    // To get Work Place Models
    axios.get(`${baseURL}/Careers/WorkPlaceModel`, {
      headers : {
        Authorization : `Bearer ${window.localStorage.getItem('result')}`
      }
    }).then(res => setOptions33(res.data.result)).catch(err => err);

    // To get Work Schedule 
    axios.get(`${baseURL}/Careers/WorkSchedule` , {
      headers : {
        Authorization : `Bearer ${window.localStorage.getItem('result')}`
      }
    }).then(res => setOptions44(res.data.result)).catch(err => err);
  },[]);

  // To control on Form 1 
  const [selectedOption1 , setSelectedOption1] = useState("");
  const [selectedOption2 , setSelectedOption2] = useState("");
  const [selectedOption3 , setSelectedOption3] = useState("");
  const [selectedOption4 , setSelectedOption4] = useState("");
  const [formData1 , setFormData1] = useState({});
  const [responseForm1 , setResponseForm1] = useState("");

  const handleChange = (e)=>{
    const {name , value} = e.target ; 
    setFormData1({
      ...formData1 , 
      [name] : value 
    })
  }

  // To handle Change on Job Category
  const handleSelectChange1 = (e)=>{
    setSelectedOption1(e.target.value);
    setFormData1({
      ...formData1 , 
      jobCategoryId : e.target.value 
    })
  }

  // To handle Change on Company 
  const handleSelectChange2 = (e)=>{
    setSelectedOption2(e.target.value);
    setFormData1({
      ...formData1 , 
      companyId : e.target.value
    })
  }

  // To handle Change on Work place model
  const handleSelectChange3 = (e)=>{
    setSelectedOption3(e.target.value);
    setFormData1({
      ...formData1 , 
      workPlaceModelId : e.target.value
    })
  }

  // To handle Change on Work Schedule 
  const handleSelectChange4 = (e)=>{
    setSelectedOption4(e.target.value);
    setFormData1({
      ...formData1 , 
      workScheduleId : e.target.value
    })
  }

  // Start of Handle Job Description
  const [jobDescription , setJobDescription] = useState("");
  const [elements1, setElements1] = useState([]);
  const refInput11 = useRef(null);

  const handleAdd1 = (e)=>{
    e.preventDefault();
    if(refInput11.current.value === ""){
      return false ; 
    }else{
      const newElement = {
        id: ++i,
        descriptionAr: jobDescription,
        jsx: (
          <div key={Date.now()} className="row">
            <div className="col-12">
          <div className="form-group">
            <br></br>
            <input type="text" name="jobDescription" value={jobDescription} onChange={(e) => setJobDescription(e.target.value)}/>
          </div>
        </div>
          </div>
        )
      };
      setElements1([...elements1 , newElement]) ;
      // To reset value 
      setJobDescription("");
    }
  }
  
  const handleDelete1 = (id , e)=>{
    e.preventDefault();
    const updatedElements = elements1.filter(elements1 => elements1.id !== id);
    setElements1(updatedElements);
  }

  // End of Handle Job Description
  let title3 , icon3 = "" ;
  const handleSubmit1 = (e)=>{
    e.preventDefault();
    elements1.push({"id":++i , "descriptionAr" : refInput11.current?.value});
    let element1Send = [];

    elements1.map(item => {
      element1Send.push({
        ...item , 
        "order" : item.id , 
        "descriptionAr" : item.descriptionAr
      })
    })

    element1Send.map(item =>{
      delete item.id ; 
      delete item?.jsx ; 
    })
  

    Swal.fire({
      title: `${t('Now Loading')}` , 
      allowEscapeKey: false , 
      allowOutsideClick : false ,
      didOpen:()=>{
        Swal.showLoading();
        axios.post(`${baseURL}/Careers/Job`,formData1,{
          headers:{
            Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
          }
        }).then((res)=> {
          if(res.data.message === "Saved Successfully"){
            setResponseForm1(res.data.resultID);
            const JobDescriptionObj = {
              jobId : res.data.resultID , 
              descriptions : [
                ...element1Send
              ]
            }
            axios.post(`${baseURL}/Careers/JobDescription`,JobDescriptionObj,{
              headers:{
                Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
              }
            }).then(res=>res).catch(err => err)
            title3 = `${t('Successfully')}`;
            icon3 = "success" ;
            setStr("bank-detail");
            Swal.close();
          }
        }).catch(()=>{
          Swal.fire({
            title: `${t('Faild Process')}` ,
            text : `${t('Try Again')}` , 
            timer: 3000,
            showConfirmButton: false 
          })
        })
      },
      didClose:()=>{
        Swal.fire({
          title: title3 ,  
          icon: icon3 , 
          timer : "5000" , 
          showConfirmButton : false
        })
      }
    })

  }

  // ======================= Form 2 ========================================
  //====================== Start Requirements ===========================
  const [jobRequirements , setJobRequirements] = useState("");
  const [elements2, setElements2] = useState([]);
  const refInput22 = useRef(null);

  const handleAdd2 = (e)=>{
    e.preventDefault();
    if(refInput22.current.value === ""){
      return false ; 
    }else{
      const newElement = {
        id: ++j,
        requirementAr: jobRequirements,
        jsx: (
          <div key={Date.now()} className="row">
            <div className="col-12">
          <div className="form-group">
            <br></br>
            <input type="text" name="jobRequirements" value={jobRequirements} onChange={(e) => setJobRequirements(e.target.value)}/>
          </div>
        </div>
          </div>
        )
      };
      setElements2([...elements2 , newElement]) ;
      // To reset value 
      setJobRequirements("");
    }
  }
  
  const handleDelete2 = (id , e)=>{
    e.preventDefault();
    const updatedElements = elements2.filter(elements2 => elements2.id !== id);
    setElements2(updatedElements);
  }

  // ======================= End Requirements ===========================

  // ======================= Start Benefits =============================
  const [jobBenefits , setJobBenefits] = useState("");
  const [elements3, setElements3] = useState([]);
  const refInput33 = useRef(null);

  const handleAdd3 = (e)=>{
    e.preventDefault();
    if(refInput33.current.value === ""){
      return false ; 
    }else{
      const newElement = {
        id: ++y,
        benefitAr: jobBenefits,
        jsx: (
          <div key={Date.now()} className="row">
            <div className="col-12">
          <div className="form-group">
            <br></br>
            <input type="text" name="jobBenefits" value={jobBenefits} onChange={(e) => setJobBenefits(e.target.value)}/>
          </div>
        </div>
          </div>
        )
      };
      setElements3([...elements3 , newElement]) ;
      // To reset value 
      setJobBenefits("");
    }
  }
  const handleDelete3 = (id , e)=>{
    e.preventDefault();
    const updatedElements = elements3.filter(elements3 => elements3.id !== id);
    setElements3(updatedElements);
  }

  // ======================= End Benefits ===============================

  // ================== Start Additional Requirements ===================
  const [jobAdditionalRequirements , setAdditionalRequirements] = useState("");
  const [elements4, setElements4] = useState([]);
  const refInput44 = useRef(null);

  const handleAdd4 = (e)=>{
    e.preventDefault();
    if(refInput44.current.value === ""){
      return false ; 
    }else{
      const newElement = {
        id: ++z,
        requirementAr: jobAdditionalRequirements,
        jsx: (
          <div key={Date.now()} className="row">
            <div className="col-12">
          <div className="form-group">
            <br></br>
            <input type="text" name="jobAdditionalRequirements" value={jobAdditionalRequirements} onChange={(e) => setAdditionalRequirements(e.target.value)}/>
          </div>
        </div>
          </div>
        )
      };
      setElements4([...elements4 , newElement]) ;
      // To reset value 
      setAdditionalRequirements("");
    }
  }
  const handleDelete4 = (id , e)=>{
    e.preventDefault();
    const updatedElements = elements4.filter(elements4 => elements4.id !== id);
    setElements4(updatedElements);
  }
  // ================== End Additional Requirements =====================

  const handleSubmit2 = (e)=>{
    e.preventDefault();

    elements2.push({"id":++j , "requirementAr" : refInput22.current?.value});
    let element2Send = [];

    elements2.map(item => {
      element2Send.push({
        ...item , 
        "order" : item.id , 
        "requirementAr" : item.requirementAr
      })
    })

    element2Send.map(item =>{
      delete item.id ; 
      delete item?.jsx ; 
    })

    elements3.push({"id":++y , "benefitAr" : refInput33.current?.value});
    let element3Send = [];

    elements3.map(item => {
      element3Send.push({
        ...item , 
        "order" : item.id , 
        "benefitAr" : item.benefitAr
      })
    })

    element3Send.map(item =>{
      delete item.id ; 
      delete item?.jsx ; 
    })

    elements4.push({"id":++z , "requirementAr" : refInput44.current?.value});
    let element4Send = [];

    elements4.map(item => {
      element4Send.push({
        ...item , 
        "order" : item.id , 
        "requirementAr" : item.requirementAr
      })
    })

    element4Send.map(item =>{
      delete item.id ; 
      delete item?.jsx ; 
    })

    const JobRequirementsObj = {
      "jobId" : responseForm1 , 
      "requirements" : [
        ...element2Send
      ]
    }

    const JobBenefitsObj = {
      "jobId" : responseForm1 , 
      "benefits": [
        ...element3Send
      ]
    }

    const JobAdditionalRequirementsObj = {
      "jobId" : responseForm1 , 
      "additionalRequirements" : [
        ...element4Send
      ]
    }

    Swal.fire({
      title: "Now Loading" , 
      allowEscapeKey: false , 
      allowOutsideClick : false ,
      didOpen:()=>{
        Swal.showLoading();
        axios.post(`${baseURL}/Careers/JobRequirement`,JobRequirementsObj,{
          headers:{
            Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
          }
        }).then((res)=> res).catch((err)=> err);
        
        axios.post(`${baseURL}/Careers/JobBenefit`,JobBenefitsObj,{
          headers:{
            Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
          }
        }).then((res)=> res).catch((err)=> err);

        axios.post(`${baseURL}/Careers/JobAdditionalRequirement`,JobAdditionalRequirementsObj,{
          headers:{
            Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
          }
        }).then((res)=> res).catch((err)=> err);
        Swal.close();
        history.push("/Kader/Jobs/Jobslist");
      },
      didClose:()=>{
        Swal.fire({
          title: `${t('Job Created Successfully')}` ,  
          icon: "success" , 
          timer : "5000" , 
          showConfirmButton : false
        })
      }
    })
  }

  return (
   <>
        {/* Page Wrapper */}
        <div className="page-wrapper cardhead">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">{t('Add New Job')}</h3>
                <ul className="formwizard">
                  <li className="formwizard-item active">{t('Post new job')}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Lightbox */}
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">{t('Create Job Process')}</h4>
                </div>
                <div className="card-body">
                  <div id="basic-pills-wizard" className="twitter-bs-wizard">
                    <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                      <li className="nav-item">
                        <Link
                          to="#seller-details"
                          className={`nav-link ${str === 'seller-details' ? 'active' : ''}`}
                          // className="nav-link"
                          data-bs-toggle="tab"
                        >
                          <div
                            className="step-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Create JobStep"
                          >
                            <FeatherIcon icon="plus-square" />
                          </div>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="#bank-detail"
                          className={`nav-link ${str === 'bank-detail' ? 'active' : ''}`}
                          // className="nav-link"
                          data-bs-toggle="tab"
                        >
                          <div
                            className="step-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Final Step"
                          >
                            <FeatherIcon icon="edit" />
                          </div>
                        </Link>
                      </li>
                    </ul>
                    {/* wizard-nav */}
                    <div className="tab-content twitter-bs-wizard-tab-content">
                      <div style={{ display: str == 'seller-details' ? 'block' : 'none' }} className="tab-pane" id="seller-details">
                        <div className="mb-4">
                          <h5>{t('Enter Your Job Details')}</h5>
                        </div>
                        <form onSubmit={handleSubmit1}>
                        <div className="row">
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Job Title En')}</label>
                    <input type="text" required name="titleEn" onChange={handleChange}/>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Job Title Ar')}</label>
                    <input type="text" required name="titleAr" onChange={handleChange} />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Job Category')}</label>
                    <Select2
                      className="select"
                      data={options1}
                      options={{
                        placeholder: t('Choose Job Category'),
                      }}
                      value={selectedOption1}
                      onChange={handleSelectChange1}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Company Name')}</label>
                    <Select2
                      className="select"
                      data={options2}
                      options={{
                        placeholder: t('Choose Company'),
                      }}
                      value={selectedOption2}
                      onChange={handleSelectChange2}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Workplace Model')}</label>
                    <Select2
                      className="select"
                      data={options3}
                      options={{
                        placeholder: t('Choose Place Model'),
                      }}
                      value={selectedOption3}
                      onChange={handleSelectChange3}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Work schedule')}</label>
                    <Select2
                      className="select"
                      data={options4}
                      options={{
                        placeholder: t('Choose Schdule'),
                      }}
                      value={selectedOption4}
                      onChange={handleSelectChange4}
                    />
                  </div>
                </div>
              </div>
              {/* Start of Job Description */}
              <h4>{t('Job Description')}</h4>
              <br></br>
              <div className="col-12">
        <div className="form-group">
          <input type="text" name="jobDescription" ref={refInput11} value={jobDescription} onChange={(e) => setJobDescription(e.target.value)} />
        </div>
      </div>
      <div className="position-relative">
        <button className="adjust-icons" onClick={handleAdd1}>
          <img src={PlusIcon} alt="img" className="me-1" />
        </button>
      </div>
      <ul>
        {elements1.map(({ jsx, id }) => (
          <li key={id} className="adjust">
            {jsx}
            <div className="position-relative">
            <button className="adjust-icons" onClick={(e)=>handleDelete1(id,e)}>
              <img src={DeleteIcon} alt="Delete Icon" />
            </button>
            </div>
          </li>
        ))}
      </ul>
      {/* End of Job Description */}
                        <ul className="pager wizard twitter-bs-wizard-pager-link">
                          <li className="next">
                          {/* onClick={() => setStr('bank-detail')} */}
                            <button className="btn btn-primary">
                              {t('Next')} <i className="bx bx-chevron-right ms-1" />
                            </button>
                          </li>
                        </ul>
                    </form>
                      </div>
                      {/* tab pane */}
                      <div className="tab-pane" id="bank-detail" style={{ display: str == 'bank-detail' ? 'block' : 'none' }}>
                        <div>
                          <div className="mb-4">
                            <h5>{t('Additional Details')}</h5>
                          </div>
                          <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>{t('SEO')}</label>
                                        <textarea className="form-control" name='seo' />
                                    </div>
                                </div>
                          <form>
                          <div className="row">
                  {/* Start of Job Requirements */}
                  <h5>{t('Job Requirements')}</h5>
              <br></br>
              <br></br>
              <div className="col-12">
        <div className="form-group">
          <input type="text" name="jobRequirements" ref={refInput22} value={jobRequirements} onChange={(e) => setJobRequirements(e.target.value)} />
        </div>
      </div>
      <div className="position-relative">
        <button className="adjust-icons" onClick={handleAdd2}>
          <img src={PlusIcon} alt="img" className="me-1" />
        </button>
      </div>
      <ul>
        {elements2.map(({ jsx, id }) => (
          <li key={id} className="adjust">
            {jsx}
            <div className="position-relative">
            <button className="adjust-icons" onClick={(e)=>handleDelete2(id,e)}>
              <img src={DeleteIcon} alt="Delete Icon" />
            </button>
            </div>
          </li>
        ))}
      </ul>
                  {/* End of Job Requirements */}
            
               {/* Start of Job Benefits */}
               <h5>{t('Job Benefits')}</h5>
              <br></br>
              <br></br>
              <div className="col-12">
        <div className="form-group">
          <input type="text" name="jobBenefits" ref={refInput33} value={jobBenefits} onChange={(e) => setJobBenefits(e.target.value)} />
        </div>
      </div>
      <div className="position-relative">
        <button className="adjust-icons" onClick={handleAdd3}>
          <img src={PlusIcon} alt="img" className="me-1" />
        </button>
      </div>
      <ul>
        {elements3.map(({ jsx, id }) => (
          <li key={id} className="adjust">
            {jsx}
            <div className="position-relative">
            <button className="adjust-icons" onClick={(e)=>handleDelete3(id,e)}>
              <img src={DeleteIcon} alt="Delete Icon" />
            </button>
            </div>
          </li>
        ))}
      </ul>
               {/* End of Job Benefits */}
                
                 {/* Start of Job Additional Requirements */}
                 <h5>{t('Job Additional Requirements')}</h5>
              <br></br>
              <br></br>
              <div className="col-12">
        <div className="form-group">
          <input type="text" name="jobAdditionalRequirements" ref={refInput44} value={jobAdditionalRequirements} onChange={(e) => setAdditionalRequirements(e.target.value)} />
        </div>
      </div>
      <div className="position-relative">
        <button className="adjust-icons" onClick={handleAdd4}>
          <img src={PlusIcon} alt="img" className="me-1" />
        </button>
      </div>
      <ul>
        {elements4.map(({ jsx, id }) => (
          <li key={id} className="adjust">
            {jsx}
            <div className="position-relative">
            <button className="adjust-icons" onClick={(e)=>handleDelete4(id,e)}>
              <img src={DeleteIcon} alt="Delete Icon" />
            </button>
            </div>
          </li>
        ))}
      </ul>
                 {/* End of Job Additional Requirements */}
              
               </div>
                          </form>
                          <ul className="pager wizard twitter-bs-wizard-pager-link">
                            {/* <li className="previous">
                              <button
                                onClick={() => setStr('seller-details')}
                                className="btn btn-primary"
                              >
                                <i className="bx bx-chevron-left me-1" /> Previous
                              </button>
                            </li> */}
                            <li className="float-end">
                              <Link
                                to="#"
                                className="btn btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target=".confirmModal"
                              >
                                {t('Submit')}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/* tab pane */}
                    </div>
                    {/* end tab content */}
                  </div>
                </div>
                {/* end card body */}
              </div>
            </div>
            {/* /Wizard */}
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      <div className="modal fade confirmModal" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-3">
            <div className="modal-header border-bottom-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="text-center">
                <h5 className="mb-3">{t('Confirm Save Changes')}</h5>
                <button
                  type="button"
                  className="btn btn-dark w-md me-1"
                  data-bs-dismiss="modal"
                >
                  {t('Close')}
                </button>
                <button
                  type="button"
                  className="btn btn-primary w-md me-1"
                  data-bs-dismiss="modal"
                  onClick={handleSubmit2}
                >
                  {t('Save changes')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
   </>
  );
};

export default Addsales;
