/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React,{useEffect, useState} from 'react';
import { LoginImage, Logo, LogoWhiteSign, MailIcon, GoogleIcon, FacebookIcon } from '../EntryFile/imagePath'
import { Link  , useHistory} from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup';
import axios from 'axios';
import { baseURL } from './Authentecation';
import { jwtDecode } from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import i18n from './configureApp/i18n';
import {connect} from 'react-redux' ; 

const SignInPage = (props) => {
    let theme = document.querySelector('body').getAttribute('data-theme');
    let active = props.active1 ; 
    
    const {t} = useTranslation();
    let lang = i18n.language ; 
    const[eye,seteye]=useState(true);
    const [payload , setPayload] = useState();

    const history = useHistory();
    const onEyeClick = () =>{
        seteye(!eye)
        }

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required(t('Email is required'))
            .email(t('Email is invalid')),
        password: Yup.string()
            .required(t('Password is required'))
            // .min(8, 'Password must be at least 8 characters')
    })

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (data) => {
        axios.post(`${baseURL}/Authentication/Account/Login` , {
            email : data.email ,
            password :  data.password
        }).then((res)=> {
                if(res.data.valid){
                    window.localStorage.setItem("result" , res.data.result);
                    const decodeRole = jwtDecode(res.data.result); 
                    window.localStorage.setItem("role" , decodeRole.UserRole);
                    window.localStorage.setItem("resultID" , res.data.resultID);
                    if(decodeRole.UserRole === "Admin"){
                        history.push(`/Kader/dashboard/`);
                    }if(decodeRole.UserRole === "HR"){
                        history.push(`/Kader/Jobs/Jobslist/`);
                    }if(decodeRole.UserRole === "ContentCreator"){
                        history.push(`/Kader/people/Institutelist-people`);
                    }if(decodeRole.UserRole === "SocialMedia"){
                        history.push(`/Kader/course/courselist-courses`);
                    }
                }else{
                    if(res.data.message === "User Must Change Password"){
                        const encodedData = encodeURIComponent(data.email);
                        props.history.push(`/ChangePassword?email=${encodedData}`)
                    }else{
                            setPayload(()=>{
                                return res ;
                            })
                    }
                }
        });
    };
    
    useEffect(() => {
        
        if (!localStorage.getItem('reloaded')) {
            localStorage.setItem('reloaded', true);
            window.location.reload();
        }
        if(localStorage.getItem("result")){
           if(localStorage.getItem("role") === "Admin"){
            history.push("/Kader/dashboard");
           }else if(localStorage.getItem("role")==="HR"){
            history.push("/Kader/Jobs/Jobslist/");
           }else if(localStorage.getItem("role")==="ContentCreator"){
            history.push("/Kader/people/Institutelist-people");
           }else if(localStorage.getItem("role") === "SocialMedia"){
            history.push("/Kader/product/courselist-courses");
           }
        }
        
      }, []);
    return (
        <>
         <div className="main-wrapper">
                <Helmet>
                    <title>Kader Application</title>
                    <meta name="description" content="SignIn page" />
                </Helmet>
                <div className="account-content">
                    <div className="login-wrapper">
                        <div className="login-content">
                            <div className="login-userset">
                                <form onSubmit={handleSubmit(onSubmit)} style={(lang == "ar") ? {direction: "rtl"} : {direction:"ltr"}}>
                                    <div className="login-logo">
                                        <img src={(active == false) && Logo || (active == true)&& LogoWhiteSign} alt={(theme == "dark") && "img" || (theme == "") && "img1"}/>
                                    </div>
                                    <div className="login-userheading">
                                        <h3>{t('Sign in')}</h3>
                                        {/* <h4>{t('please login')}</h4> */}
                                    </div>
                                    <div className="form-login">
                                        <label>{t('Email')}</label>
                                        <div className="form-addons">
                                            <input
                                                type="text"
                                                {...register('email')}
                                                className={` ${errors.email ? 'is-invalid' : ''}`}
                                                placeholder="Enter your email address"
                                            />
                                            <img src={MailIcon} style={(lang == "ar") ? {left:"20px"} : {right:"20px"}} alt="img" />
                                            <div className="invalid-feedback">{errors.email?.message}</div>

                                        </div>
                                    </div>
                                    <div className="form-login">
                                        <label>{t('Password')}</label>
                                        <div className="pass-group">
                                            <input
                                                type={eye ? "password" : "text"}
                                                className={`${errors.password ? 'is-invalid' : ''}`}
                                                placeholder="Enter your password"
                                                {...register('password')}
                                            />
                                            <span  onClick={onEyeClick} className={`fas toggle-password ${eye ? "fa-eye-slash":"fa-eye"}`} style={lang == "ar" ? {left:"10px"} : { right :"11px"}} />
                                            <div className="invalid-feedback">{errors.password?.message}</div>

                                        </div>
                                    </div>
                                    <div className="form-login">
                                        <div className="alreadyuser">
                                            <h4>
                                                <Link to="/forgetPassword" className="hover-a">
                                                    {t('Forgot Password?')}
                                                </Link>
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="form-login">
                                        <button className="btn btn-login" >
                                            {t('Login')}
                                        </button>
                                    </div>
                                </form>
                            </div>
                            {(payload)? (<div className='alreadyuser text-center'><h6>{t(payload.data.message)}</h6></div>): ""}
                        </div>
                        <div className="login-img">
                            <img src={LoginImage} alt="img" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function mapStateToProps(state){
    return{
      active1 : state.active
    }
  }
  
  function mapDispatchToProps(dispatch){
    return {
      change : () => dispatch({type : "CHANGE"})
    }
  }
export default connect(mapStateToProps , mapDispatchToProps)(SignInPage);