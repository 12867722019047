/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import React, { useState , useEffect } from "react";
import { Upload } from "../../EntryFile/imagePath";
import "react-select2-wrapper/css/select2.css";
import { Country, State }  from 'country-state-city';
import Select from "react-dropdown-select";
import { baseURL } from "../../InitialPage/Authentecation";
import axios from "axios";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import Swal from "sweetalert2";
import {useHistory} from "react-router-dom";
import { useTranslation } from "react-i18next";

const addCompany = () => {
  const {t} = useTranslation(); 
  const history = useHistory();

  const options = [];
  const options1 = [];

  const loadData = ()=>{
  Country.getAllCountries().map((item)=>{
    return(
      options.push({label: item.name , value:item.isoCode })
    )
  });
  }
loadData();
const [value , setValue] = useState([]);
const [value1 , setValue1] = useState([]);
const [formData , setFormData] = useState({});

const handleChangeCountry = (value) =>{
  setValue1(value) ; 
  setFormData({
      ...formData , 
      country : value[0]?.label
  })
}

const handleChangeCity = (value) => {
  setValue(value);
  setFormData({
    ...formData , 
    city : value[0]?.label
  })
}

value1.map((item)=>{
  options1.length = 0 ;
  return(
    State.getStatesOfCountry(item?.value).map((item )=>{
        options1.push({label:item.name , value: Math.random()})
    })
  )
})

const [option2 , setOptions2] = useState([]);
// Company Size 
useEffect(()=>{
  axios.get(`${baseURL}/Careers/CompanySize`,{
    headers:{
      Authorization : `Bearer ${window.localStorage.getItem('result')}`
    }
  }).then((res)=>{
    setOptions2(()=>{
      return res.data.result ; 
    })
  }).catch(err => err);
},[]);

let options3 = [] ; 
const newName = "text" ;
const newName2 = "id";

option2.map(item =>{
  options3.push({
    ...item,
    [newName] : item.size , 
    [newName2] : item.id
  })
})
options3.map(item => {
  delete item.order ;
  delete item.size ;
});

const [selectedOption1 , setSelectedOption1] = useState('');
const handleSelectChange1 = (e)=>{
  setSelectedOption1(e.target.value);
  setFormData({
    ...formData , 
    companySizeId : e.target.value 
  })
}

const handleFileChange = (e)=>{
  const file = e.target.files[0] ; 
  setFormData({
    ...formData , 
    logo : file
    })
}

const handleChange = (e)=>{
  const {name , value} = e.target ; 
  setFormData({
    ...formData , 
    [name] : value
  })
}

let title , icon = "" ;
const handleSubmit = (e)=>{
  e.preventDefault();
 
  Swal.fire({
    title: `${t('Now Loading')}` , 
    allowEscapeKey: false , 
    allowOutsideClick : false ,
    didOpen:()=>{
      Swal.showLoading();
      axios.post(`${baseURL}/Careers/Company`, formData,{
        headers:{
          Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
          "Content-Type" :"multipart/form-data"
        }
      }).then((res)=> {
        if(res.data.message === "Saved Successfully"){
            title = `${t('Finished!')}`;
            icon = "success";
            Swal.close();
            history.push("/Kader/Carrers/Companies")
          }else{
                title = `${t('Company is Already Exist')}` ;
                icon ='warning' ;
                Swal.close();
      }}
      ).catch(()=>{
        Swal.fire({
          title: `${t('There is Failure in Network')}` ,
          icon:'warning' , 
          timer: "3000",
          showConfirmButton: false 
        }) 
      })
    },
    didClose:()=>{
      Swal.fire({
        title: title ,  
        icon: icon , 
        timer : "5000" , 
        showConfirmButton : false
      })
    }
  })
}
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>{t('Company Management')}</h4>
              <h6>{t('Add Company')}</h6>
            </div>
          </div>
          {/* /add */}
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Name')}</label>
                    <input type="text" required name="nameAr" onChange={handleChange}/>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Email')}</label>
                    <input type="email" required name="email" onChange={handleChange}/>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Phone')}</label>
                    <input type="number" required name="phone" onChange={handleChange}/>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Company Size')}</label>
                    <Select2
                     className="select"
                     style={{borderColor:"rgba(145,158,171,0.32",padding:"9px" , borderRadius:"5px"}}
                     data={options3}
                     options={{
                      placeholder: t('Choose'),
                    }}
                    value={selectedOption1}
                    onChange={handleSelectChange1}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Country')}</label>
                    <Select
                    name="Select"
                    style={{borderColor:"rgba(145,158,171,0.32",padding:"9px" , borderRadius:"5px"}}
                    options={options}
                    onChange={handleChangeCountry}
                    required
                    placeholder={t('Search')}
                    >
                    </Select>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('City')}</label>
                    <Select
                     name="Select"
                     style={{borderColor:"rgba(145,158,171,0.32",padding:"9px" , borderRadius:"5px"}}
                     options={options1}
                     onChange={handleChangeCity}
                     placeholder={t('Search')}
                    >
                    </Select>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>{t('Description')}</label>
                    <textarea className="form-control" name="aboutAr" onChange={handleChange} />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>{t('Commercial logo')}</label>
                    <div className="image-upload">
                      <input type="file" required onChange={handleFileChange}/>
                      <div className="image-uploads">
                        <img src={Upload} alt="img" />
                        <h4>{t('Drag and drop a file to upload')}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <button className="btn btn-submit me-2">{t('Submit')}</button>
                  <button className="btn btn-cancel" onClick={()=> history.push("/Kader/Carrers/Companies")}>{t('Cancel')}</button>
                </div>
              </div>
              </form>
            </div>
          </div>
          {/* /add */}
        </div>
      </div>
    </>
  );
};

export default addCompany;
