/* eslint-disable no-undef */
/* eslint-disable no-dupe-keys */
import React, { useState  , useEffect } from "react";
import { Upload } from "../../EntryFile/imagePath";
import { useParams ,useHistory} from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import axios from "axios";
import { baseURL } from "../../InitialPage/Authentecation";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";


const EditUser = () => {
  const {t} = useTranslation();
  const [data , setData] = useState({});
  
  function base64toFile(base64String, fileName, mimeType) {
    // Decode the Base64 String
    const binaryData = atob(base64String);
  
    // Create a Blob from Binary Data
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: mimeType || 'application/octet-stream' });
  
    // Create a File from Blob
    const file = new File([blob], fileName || 'file', { type: mimeType || 'application/octet-stream' });
  
    //  Return the File object
    return file ; 
  }

const base64String = data?.Image;
const fileName = "example.jpg"; // Name with Extention
const mimeType = "image/jpeg"; // I can Change MIME type if i want 

  const history = useHistory();
  const {id} = useParams();
  const [Birthdate , setBirthdate] = useState('');
  const [roleData , setRoleData] = useState([]);
  const [isVisible , setVisible] = useState(true); 
  const [imgUrl , setImageURL] = useState();


   // To handle Image 
   const [image , setImage] = useState(null) ; 
   const handleFileChange = (e)=>{
    const file = e.target.files[0] ; 
    const reader = new FileReader();
    reader.onload = function (e) {
        const dataUrl = e.target.result;
        setImageURL(dataUrl);
      };
    reader.readAsDataURL(file);
    setImage(file);
    setVisible(true);
}
  // To control select 2 Gender view
const options2 = [
  {id: 0 , text : "Male"} , 
  {id: 1 , text : "Female"} , 
  {id: 2 , text : "Not Selected"} , 
  {id: 3 , text : "Ohters"}
]

   // Adjust name of options object 
 let options = [] ; 
 const newName = "text" ;

 roleData.map((item)=>{
   options.push({
     ...item , 
     [newName] : item.role 
   });
 })

 options.map(item => delete item.role);
  useEffect(()=>{
    axios.get(`${baseURL}/Authentication/User/${id}?WithImages=true`,{
      headers:{
        Authorization: `Bearer ${window.localStorage.getItem("result")}`
      }
    }).then(res => {
      setData(res.data.result)
      const date = res.data.result.Birthdate ; 
      const dataPortion = date?.split('T')[0] ;
      setBirthdate(dataPortion);
    }).catch(err => err);
  },[]);

  useEffect(()=>{
    axios.get(`${baseURL}/Authentication/UserRoles`,{
      headers:{
        Authorization : `Bearer ${window.localStorage.getItem('result')}`
      }
    }).then((res)=> setRoleData(res.data.result))
    .catch(err => err);
  } , []);

  
  let title , icon = "";
  const handleSubmit = (e) =>{
    e.preventDefault();
    const formData = {
      userId : id,
      userRole : e.target.elements.Role.value, 
      gender : e.target.elements.Gender.value ,
      firstNameEn : e.target.elements.firstNameEn.value , 
      firstNameAr : e.target.elements.firstNameAr.value , 
      lastNameEn : e.target.elements.lastNameEn.value ,
      lastNameAr : e.target.elements.lastNameAr.value , 
      userName : e.target.elements.userName.value , 
      email: e.target.elements.email.value , 
      phoneNumber : e.target.elements.phoneNumber.value,
      address : e.target.elements.address.value ,
      birthdate : e.target.elements.birthdate.value ,
      Image : isVisible?image??base64toFile(base64String , fileName , mimeType):image 
    };

    Swal.fire({
      title: `${t('Now Loading')}` , 
      // timer : "10000" , 
      allowEscapeKey: false , 
      allowOutsideClick : false ,
      didOpen:()=>{
        Swal.showLoading();
        axios.put(`${baseURL}/Authentication/User`, formData , {
          headers:{
            Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
            "Content-Type" :"multipart/form-data"
          }
        }).then((res)=> {
          if(res.data.valid){
              title = `${t('Finished!')}`;
              icon = "success";
              Swal.close();
              history.push("/Kader/people/userlist-people");
              window.location.reload();
          }else{
              title= `${t('User Not Updated')}` ;
              icon =  'warning';
              Swal.close();
          }
        })
        .catch(()=>{
          Swal.fire({
            title: `${t('User Not Updated')}` ,
            text : `${t('Please Check on other fields')}` , 
            timer : "3000" ,
            showConfirmButton: false 
          })
        });
      },
      didClose:()=>{
        Swal.fire({
          title: title ,  
          icon:icon , 
          timer : "5000" , 
          showConfirmButton : false
        })
      }
    })
  }

  const handleDeleteClick = ()=>{
    setVisible(false);
    setImage(null);
 }
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>{t('User management')}</h4>
              <h6>{t('Edit/Update User')}</h6>
            </div>
          </div>
          {/* /add */}
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('First Name(EN)')}</label>
                    <input type="text" required name="firstNameEn" defaultValue={data?.FirstNameEn} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('First Name(AR)')}</label>
                    <input type="text" required name="firstNameAr" defaultValue={data?.FirstNameAr} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Last Name(En)')}</label>
                    <input type="text" required name="lastNameEn" defaultValue={data?.LastNameEn} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Last Name(Ar)')}</label>
                    <input type="text" required name="lastNameAr" defaultValue={data?.LastNameAr} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('BirthDate')}</label>
                   <input type="date" name="birthdate" defaultValue={Birthdate}/>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('User Name')}</label>
                    <input type="text" name="userName" defaultValue={data?.UserName} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Phone')}</label>
                    <input type="text" name="phoneNumber" defaultValue={data?.PhoneNumber} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Email')}</label>
                    <input type="text" name="email" defaultValue={data?.Email} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Address')}</label>
                    <input type="text" name="address" defaultValue={data?.Address}  />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Role')}</label>
                    <Select2
                      className="select"
                      data={options}
                      defaultValue = {data.UserRole}
                      id = "Role"
                      options={{
                        placeholder: `${t('Choose')}`,
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Gender')}</label>
                    <Select2
                      className="select"
                      data={options2}
                      defaultValue={data?.Gender}
                      id = "Gender"
                      options={{
                        placeholder: `${t('Choose')}`,
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>{t('User Image')}</label>
                    <div className="image-upload">
                      <input type="file" onChange={handleFileChange} defaultValue={data?.Image}/>
                      <div className="image-uploads">
                        <img src={Upload} alt="img" />
                        <h4>{t('Drag and drop a file to upload')}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 ms-3" style={{display: (isVisible)? "block" : "none"}}>
                                    <div className="product-list">
                                        <ul className="row">
                                            <li className="ps-0">
                                                <div className="productviews">
                                                    <div className="productviewsimg">
                                                    {isNaN((image?.size / 1024).toFixed(1))? (
                                                            <img src={`data:image/jpeg;base64,${data?.Image}`}/>
                                                        ):<img src={imgUrl} />}
                                                    </div>
                                                    <div className="productviewscontent">
                                                        <div className="productviewsname">
                                                            <h2>{data?.FirstNameEn}</h2>
                                                            <h3>{isNaN((image?.size / 1024).toFixed(1)) ? "581" : (image?.size / 1024).toFixed(1) }kb</h3>
                                                        </div>
                                                        <a href="#" className="hideset" onClick={handleDeleteClick}>
                                                            x
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                <div className="col-lg-12">
                  <button className="btn btn-submit me-2">{t('update')}</button>
                  <button className="btn btn-cancel" onClick={()=> history.push("/Kader/people/userlist-people")}>{t('Cancel')}</button>
                </div>
              </div>
              </form>
            </div>
          </div>
          {/* /add */}
        </div>
      </div>  
    </>
  );
};

export default EditUser;
