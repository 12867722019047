/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { Samsung, Upload } from '../../EntryFile/imagePath'
import { useParams ,useHistory} from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../InitialPage/Authentecation";
import Swal from "sweetalert2";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const EditCategory = () => {
    const {t} = useTranslation();
    function base64toFile(base64String, fileName, mimeType) {
        // Step 1: Decode the Base64 String
        const binaryData = atob(base64String);
      
        // Step 2: Create a Blob from Binary Data
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }
        const blob = new Blob([arrayBuffer], { type: mimeType || 'application/octet-stream' });
      
        // Step 3: Create a File from Blob
        const file = new File([blob], fileName || 'file', { type: mimeType || 'application/octet-stream' });
      
        // Step 4: Return the File object
        return file ; 
      }
    const history = useHistory();
    const {id} = useParams();
    const [data , setData] = useState({});
         // To handle Image 
   const [image , setImage] = useState(null) ; 
   const [isVisible , setVisible] = useState(true); 
   const [imgUrl , setImageURL] = useState();

   const handleFileChange = (e)=>{
    const file = e.target.files[0] ; 
    const reader = new FileReader();
    reader.onload = function (e) {
        const dataUrl = e.target.result;
        setImageURL(dataUrl);
      };
    reader.readAsDataURL(file);
    setImage(file);
    setVisible(true);
}

const base64String = data?.Image;
const fileName = "example.jpg"; // Provide a file name with extension
const mimeType = "image/jpeg"; // Specify the MIME type of the file

    useEffect(()=>{
        axios.get(`${baseURL}/Courses/CourseCategory/${id}?IncludeCourses=false&WithImages=true`,{
            headers:{
              Authorization: `Bearer ${window.localStorage.getItem("result")}`
            }
          }).then(res => {
            setData(res.data.result)
        })
          .catch(err => err)
    },[]);

    let title , icon = "";
    const handleSubmit = (e) =>{
        e.preventDefault();
        const formData = {
            id : id , 
            nameAr : e.target.elements.nameAr.value , 
            nameEn : e.target.elements.nameEn.value , 
            descriptionAr: e.target.elements.descriptionAr.value , 
            descriptionEn : e.target.elements.descriptionEn.value ,
            image : isVisible?image??base64toFile(base64String , fileName , mimeType):image
        }

        Swal.fire({
            title: `${t('Now Loading')}` , 
            allowEscapeKey: false , 
            allowOutsideClick : false ,
            didOpen:()=>{
              Swal.showLoading();
              axios.put(`${baseURL}/Courses/CourseCategory`, formData , {
                headers:{
                  Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
                  "Content-Type" :"multipart/form-data"
                }
              }).then((res)=> {
                if(res.data.valid){
                    title = `${t('Finished!')}`;
                    icon = "success";
                    Swal.close();
                    history.push("/Kader/course/categorylist-product")
                }else{
                    title = `${t('Category Not Updated')}` ;
                    icon = "warning" ;
                    Swal.close();
                }
              })
              .catch(()=>{
                Swal.fire({
                  title: `${t('Category Not Updated')}` ,
                  text : `${t('Please Upload New Image')}` , 
                  timer: 3000,
                  showConfirmButton: false 
                })
              });
            },
            didClose:()=>{
              Swal.fire({
                title: title ,  
                icon: icon , 
                timer : "5000" , 
                showConfirmButton : false
              })
            }
          })
    }

    const handleDeleteClick = ()=>{
       setVisible(false);
       setImage(null);
    }
    return (
        <>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="page-title">
                            <h4>{t('Course Category')}</h4>
                            <h6>{t('Edit Category')}</h6>
                        </div>
                    </div>
                    {/* /add */}
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                            <div className="row">
                            <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>{t('Category Name(En)')}</label>
                                        <input type="text" name='nameEn' defaultValue={data?.NameEn}/>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>{t('Category Name(Ar)')}</label>
                                        <input type="text" name='nameAr' defaultValue={data?.NameAr}/>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>{t('Description(En)')}</label>
                                        <textarea className="form-control" name='descriptionEn' defaultValue={data?.DescriptionEn} maxLength={70}/>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>{t('Description(Ar)')}</label>
                                        <textarea className="form-control" name='descriptionAr' defaultValue={data?.DescriptionAr} maxLength={70} />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>{t('Category Image')}</label>
                                        <div className="image-upload">
                                            <input type="file" name='file' onChange={handleFileChange} defaultValue={data?.Image}/>
                                            <div className="image-uploads">
                                                <img src={Upload} alt="img" />
                                                <h4>{t('Drag and drop a file to upload')}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 ms-3" style={{display: (isVisible)? "block" : "none"}}>
                                    <div className="product-list">
                                        <ul className="row">
                                            <li className="ps-0">
                                                <div className="productviews">
                                                    <div className="productviewsimg">
                                                        {isNaN((image?.size / 1024).toFixed(1))? (
                                                            <img src={`data:image/jpeg;base64,${data?.Image}`} alt='Api Image' />
                                                        ):<img src={imgUrl} alt='Local Image' />}
                                                    </div>
                                                    <div className="productviewscontent">
                                                        <div className="productviewsname">
                                                            <h2>{data?.NameEn}</h2>
                                                            <h3>{isNaN((image?.size / 1024).toFixed(1)) ? "581" : (image?.size / 1024).toFixed(1) }kb</h3>
                                                        </div>
                                                        <a href="#" className="hideset" onClick={handleDeleteClick}>
                                                            x
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <button className="btn btn-submit me-2" type="submit">
                                        {t('update')}
                                    </button>
                                    <button className="btn btn-cancel" onClick={()=> history.push('/Kader/course/categorylist-product')}>
                                        {t('Cancel')}
                                    </button>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                    {/* /add */}
                </div>
            </div>

        </>
    )
}

export default EditCategory