/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import {
  Logo,
  LogoWhiteSign , 
  SmallLogo,
  Closes,
  HeaderSearch,
  Flag,
  FlagUS,
  FlagFR,
  FlagES,
  FlagSA,
  FlagDE,
  Notification,
  Avatar2,
  Avatar3,
  Avatar6,
  Avatar17,
  Avatar13,
  Avatar,
  Logout,
  LogoWhite,
  Avatar1,
} from "../../EntryFile/imagePath";
import { Link , useHistory} from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { baseURL } from "../Authentecation";
import axios from "axios";
import { useTranslation } from 'react-i18next';
// import i18n from "../configureApp/i18n";

const Header = () => {
  const [searchBar, SetSearchBar] = useState(false);
  const [toggle, SetToggle] = useState(false);
  const history = useHistory();
  const { i18n } = useTranslation();
  let lang = i18n.language ; 

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };


  const ImageDecoder = (imgDecoder) => {
    // Decode base64 string to binary
    const binaryString = atob(imgDecoder);
  
    // Convert binary string to Uint8Array
    const uint8Array = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }
  
    // Create a Blob from Uint8Array
    const blob = new Blob([uint8Array], { type: "image/jpeg" });
  
    // Create a data URL from the Blob
    const imageUrl = URL.createObjectURL(blob);
  
    return imageUrl ; 
  };

  const [data , setData] = useState([]);
  const [imgData , setImageData] = useState("");
  useEffect(()=>{
    const fetchData = async()=>{
      try{
        const apiURL =`${baseURL}/Authentication/User/${localStorage.getItem('resultID')}?WithImages=true`;
        const result = await axios.get(apiURL, {
          headers:{
            Authorization : `Bearer ${window.localStorage.getItem('result')}`
          }
        })
        setData(result.data.result);
        setImageData(result.data.result["Image"]);
      }catch(err){
        console.error("Error Handling Data" , err);
      }
    };
   fetchData();
  },[]);
  

  const role = window.localStorage.getItem("role");

  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
    SetToggle((current) => !current);
  };
  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };
  const sidebarOverlay = () => {
    document.querySelector(".main-wrapper").classList.toggle("slide-nav");
    document.querySelector(".sidebar-overlay").classList.toggle("opened");
    document.querySelector("html").classList.toggle("menu-opened");
  };

  let pathname = location.pathname;

  const exclusionArray = [
    "/reactjs/template/Kader/index-three",
    "/reactjs/template/Kader/index-one",
  ];
  if (exclusionArray.indexOf(window.location.pathname) >= 0) {
    return "";
  }

  const [isFullscreen, setIsFullscreen] = useState(false);
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(
        document.fullscreenElement ||
          document.mozFullScreenElement ||
          document.webkitFullscreenElement ||
          document.msFullscreenElement
      );
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("msfullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "msfullscreenchange",
        handleFullscreenChange
      );
    };
  }, []);

  const logout = ()=>{
    axios.post(`${baseURL}/Authentication/Account/Logout`,{},{
      headers:{
        Authorization : `Bearer ${window.localStorage.getItem('result')}`
      }
    }).then((res)=>{
        if(res.data.status === 3){
          window.localStorage.removeItem("result");
          window.localStorage.removeItem("role");
          history.replace("/");
        }
    }).catch(()=>{
      window.localStorage.removeItem("result");
      window.localStorage.removeItem("role");
      history.replace("/");
    })
  }

  const toggleFullscreen = (elem) => {
    
    elem = elem || document.documentElement;
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  };

const {t} = useTranslation();
  return (
    <>
      <div className="header">
        {/* Logo */}
        { (<div
          className={`header-left ${toggle ? "" : "active"}`}
          onMouseLeave={expandMenu}
          onMouseOver={expandMenuOpen}
        >
          <Link to="/Kader/dashboard" className="logo logo-normal">
            <img src={LogoWhiteSign} alt="" />
          </Link>
          <Link to="/Kader/dashboard" className="logo logo-white">
            <img src={Logo} alt="" />
          </Link>
          <Link to="/Kader/dashboard" className="logo-small">
            <img src={Logo} alt="" />
          </Link>
          <Link
            id="toggle_btn"
            to="#"
            style={{
              display: pathname.includes("tasks")
                ? "none"
                : pathname.includes("compose")
                ? "none"
                : "",
            }}
            onClick={handlesidebar}
          >
            <FeatherIcon icon="chevrons-left" className="feather-16" />
          </Link>
        </div>) }
        {/* /Logo */}
        <Link
          id="mobile_btn"
          className="mobile_btn"
          to="#"
          onClick={sidebarOverlay}
        >
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </Link>
        {/* Header Menu */}
        <ul className="nav user-menu">
          {/* Search */}
          <li className="nav-item nav-searchinputs">
            <div className="top-nav-search">
              <Link to="#" className="responsive-search">
                <i className="fa fa-search" />
              </Link>
              <form action="#">
                <div className="searchinputs">
                  <input type="text" placeholder={t("Search")} />
                  <div className="search-addon">
                    <span>
                      {/* <i data-feather="search" className="feather-14" /> */}
                      <FeatherIcon icon="search" className="feather-14" />
                    </span>
                  </div>
                </div>
                {/* <Link class="btn"  id="searchdiv"><img src={HeaderSearch} alt="img"/></Link> */}
              </form>
            </div>
          </li>
          {/* /Search */}
          {/* Flag */}
          <li className="nav-item dropdown has-arrow flag-nav nav-item-box">
            <Link
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              to="#"
              role="button"
            >
              {/* <i data-feather="globe" /> */}
              <FeatherIcon icon="globe" />
            </Link>
            <div className="dropdown-menu dropdown-menu-right">
              <Link to="#" className={`dropdown-item ${lang == "en" ? "active" : "" } `} onClick={() => {changeLanguage('en'); }}>
                <img src={FlagUS} alt="" height={16} /> English
              </Link>
              <Link to="#" className={`dropdown-item ${lang == "ar" ? "active" : "" } `} onClick={() => {changeLanguage('ar'); }}>
                <img src={FlagSA} alt="" height={16} /> Arabic
              </Link>
            </div>
          </li>
          {/* /Flag */}
          <li className="nav-item nav-item-box">
            <Link
              to="#"
              id="btnFullscreen"
              onClick={() => toggleFullscreen()}
              className={isFullscreen ? "Exit Fullscreen" : "Go Fullscreen"}
            >
              {/* <i data-feather="maximize" /> */}
              <FeatherIcon icon="maximize" />
            </Link>
          </li>
          <li className="nav-item dropdown has-arrow main-drop">
            <Link
              to="#"
              className="dropdown-toggle nav-link userset"
              data-bs-toggle="dropdown"
            >
              <span className="user-info">
                <span className="user-letter">
                  {(imgData)? <img src={(data)? ImageDecoder(data["Image"]) : ""} alt="" className="img-fluid" /> : ""}
                </span>
                <span className="user-detail">
                  <span className="user-name">{data['UserName']}</span>
                  <span className="user-role">{t('Super Admin')}</span>
                </span>
              </span>
            </Link>
            <div className="dropdown-menu menu-drop-user">
              <div className="profilename">
                <div className="profileset">
                  <span className="user-img">
                  {(imgData)? <img src={(data)? ImageDecoder(data["Image"]) : ""} alt="" className="img-fluid" /> : ""}
                    <span className="status online" />
                  </span>
                  <div className="profilesets">
                    <h6>{data['UserName']}</h6>
                    <h5>{t('Super Admin')}</h5>
                  </div>
                </div>
                <hr className="m-0" />
                <Link
                  className="dropdown-item"
                  to="/Kader/profile/user-profile"
                >
                  <i className="me-2" data-feather="user" /> {t('My Profile')}
                </Link>
                {/* <Link
                  className="dropdown-item"
                  to="/Kader/settings/generalsettings"
                >
                  <i className="me-2" data-feather="settings" />
                  Settings
                </Link> */}
                <hr className="m-0" />
                <button className="dropdown-item logout pb-0" onClick={logout}>
                  <img src={Logout} className="me-2" alt="img" />
                  {t('Logout')}  
                </button>
              </div>
            </div>
          </li>
        </ul>
        {/* /Header Menu */}
        {/* Mobile Menu */}
        <div className="dropdown mobile-user-menu">
          <Link
            to="#"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v" />
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
          <hr className="m-0" />
                <Link
                  className="dropdown-item"
                  to="/Kader/profile/user-profile"
                >
                  <i className="me-2" data-feather="user" /> {t('My Profile')}
                </Link>
           
          <hr className="m-0" />
          <button className="dropdown-item logout" onClick={logout}>
                  {t('Logout')}  
                </button>
                <hr className="m-0" />
          </div>
        </div>
        {/* /Mobile Menu */}
      </div>
    </>
  );
};

export default Header;
