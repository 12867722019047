/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Table from "../../EntryFile/datatable";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import Tabletop from "../../EntryFile/tabletop";
import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";
import {
  Download,
  Product7,
  Product8,
  Product1,
} from "../../EntryFile/imagePath";

import {
  ClosesIcon,
Backward , 
  Excel,
  Filter,
  Pdf,
  Eye1,
  Calendar,
  Printer,
  search_whites,
  Search,
  PlusIcon,
  EditIcon,
  Dollar1,
  plusCircle,
  delete1,
  DeleteIcon,
  datepicker,
} from "../../EntryFile/imagePath";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import { useTranslation } from "react-i18next";

const RecievedJobsApp = (props) => {
  const {t} = useTranslation();
  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());
  const [inputfilter, setInputfilter] = useState(false);

  const togglefilter = (value) => {
    setInputfilter(value);
  };

  const confirmText = () => {
    Swal.fire({
      title: `${t('Are you sure?')}`,
      text: `${t("You won't be able to revert this!")}`,
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${t('Yes, delete it!')}`,
      cancelButtonText : `${t('Cancel')}`,
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(function (n) {
      n.value &&
        Swal.fire({
          type: "success",
          title: `${t('Deleted!')}`,
          text: `${t('Your file has been deleted.')}`,
          confirmButtonClass: "btn btn-success",
        });
    });
  };
  const options = [
    { id: 1, text: "Completed", text: "Completed" },
    { id: 2, text: "Paid", text: "Paid" },
  ];
  // const [data] = useState([
  //   {
  //     id: 1,
  //     Date: "walk-in-customer",
  //     Company: "Kader",
  //     Status: "on site",
  //     Payment: "Paid",
  //     Total: "Web",
  //     Paid: "Full Time",
  //     Due: 100,
  //     Biller: "Admin",
  //   },
  //   {
  //     id: 2,
  //     Date: "walk-in-customer",
  //     Company: "Kader",
  //     Status: "on site",
  //     Payment: "Paid",
  //     Total: "Web",
  //     Paid: "Full Time",
  //     Due: 100,
  //     Biller: "Admin",
  //   },
  //   {
  //     id: 3,
  //     Date: "walk-in-customer",
  //     Company: "Kader",
  //     Status: "on site",
  //     Payment: "Paid",
  //     Total: "Mobile",
  //     Paid: "Part Time",
  //     Due: 0,
  //     Biller: "Admin",
  //   },
  //   {
  //     id: 4,
  //     Date: "Fred C. Rasmussen",
  //     Company: "Kader",
  //     Status: "remotely",
  //     Payment: "Due",
  //     Total: "Web",
  //     Paid: "Part Time",
  //     Due: 0,
  //     Biller: "Admin",
  //   },
  //   {
  //     id: 5,
  //     Date: "Thomas M. Martin",
  //     Company: "Kader",
  //     Status: "remotely",
  //     Payment: "Due",
  //     Total: "Mobile",
  //     Paid: "Full Time",
  //     Due: 100,
  //     Biller: "Admin",
  //   },
  //   {
  //     id: 6,
  //     Date: "Thomas M. Martin",
  //     Company: "Kader",
  //     Status: "on site",
  //     Payment: "Paid",
  //     Total: "Web",
  //     Paid: "Full Time",
  //     Due: 100,
  //     Biller: "Admin",
  //   },
  //   {
  //     id: 7,
  //     Date: "walk-in-customer",
  //     Company: "Kader",
  //     Status: "on site",
  //     Payment: "Paid",
  //     Total: "Embded System",
  //     Paid: "Full Time",
  //     Due: 100,
  //     Biller: "Admin",
  //   },
  //   {
  //     id: 8,
  //     Date: "walk-in-customer",
  //     Company: "Kader",
  //     Status: "remotely",
  //     Payment: "Due",
  //     Total: "Mobile",
  //     Paid: "Part Time",
  //     Due: 0,
  //     Biller: "Admin",
  //   },
  //   {
  //     id: 9,
  //     Date: "walk-in-customer",
  //     Company: "Kader",
  //     Status: "remotely",
  //     Payment: "Due",
  //     Total: "Mobile",
  //     Paid: "Part Time",
  //     Due: 0,
  //     Biller: "Admin",
  //   },
  //   {
  //     id: 10,
  //     Date: "walk-in-customer",
  //     Company: "Kader",
  //     Status: "remotely",
  //     Payment: "Due",
  //     Total: "Emnded System",
  //     Paid: "Part Time",
  //     Due: 0,
  //     Biller: "Admin",
  //   },
  //   {
  //     id: 11,
  //     Date: "walk-in-customer",
  //     Company: "Kader",
  //     Status: "remotely",
  //     Payment: "Due",
  //     Total: "Mobile",
  //     Paid: "Part Time",
  //     Due: 0,
  //     Biller: "Admin",
  //   },
  // ]);

  const columns1 = [
    {
      title: `${t('Job Title')}`,
      dataIndex: "Date",
      sorter: (a, b) => a.Date.length - b.Date.length,
    },
    {
      title: `${t('Job Category')}`,
      dataIndex: "Total",
      sorter: (a, b) => a.Reference.length - b.Reference.length,
    },
    // {
    //   title: "Company",
    //   dataIndex: "Status",
    //   render: (text, record) => (
    //     <>
    //       {text === "Pending" && (
    //         <span className="badges bg-lightred">{text}</span>
    //       )}
    //       {text === "Completed" && (
    //         <span className="badges bg-lightgreen">{text}</span>
    //       )}
    //     </>
    //   ),
    //   sorter: (a, b) => a.Status.length - b.Status.length,
    // },
    // {
    //   title: "Work place Model",
    //   dataIndex: "Payment",
    //   render: (text, record) => (
    //     <>
    //       {text === "Paid" && (
    //         <span className="badges bg-lightgreen">{text}</span>
    //       )}
    //       {text === "Due" && <span className="badges bg-lightred">{text}</span>}
    //     </>
    //   ),
    //   sorter: (a, b) => a.Payment.length - b.Payment.length,
    // },
    {
      title: `${t('Company')}`,
      dataIndex: "Company",
      sorter: (a, b) => a.Total.length - b.Total.length,
    },
    {
      title: `${t('Workplace Model')}`,
      dataIndex: "Status",
      sorter: (a, b) => a.Total.length - b.Total.length,
    },
    {
      title: `${t('Work schedule')}`,
      dataIndex: "Paid",
      sorter: (a, b) => a.Total.length - b.Total.length,
    },
    //   {
    //   title: "Date",
    //   dataIndex: "Name",
    //   sorter: (a, b) => a.Name.length - b.Name.length,
    // },
    // {
    //   title: "Paid",
    //   dataIndex: "Paid",
    //   render: (text, record) => (
    //     <>
    //       {text === 100 && <div className="text-green">{text}</div>}
    //       {text === 0 && <div>{text}</div>}
    //     </>
    //   ),
    //   sorter: (a, b) => a.Paid.length - b.Paid.length,
    // },
    // {
    //   title: "Due",
    //   dataIndex: "Due",
    //   render: (text, record) => (
    //     <>
    //       {text === 100 && <div className="text-red">{text}</div>}
    //       {text === 0 && <div>{text}</div>}
    //     </>
    //   ),
    //   sorter: (a, b) => a.Due.length - b.Due.length,
    // },
    // {
    //   title: "Biller",
    //   dataIndex: "Biller",
    //   sorter: (a, b) => a.Biller.length - b.Biller.length,
    // },
    // {
    //   title: "Action",
    //   render: (text, record) => (
    //     <>
    //       <div className="text-center">
    //         <Link
    //           className="action-set"
    //           to="#"
    //           data-bs-toggle="dropdown"
    //           aria-expanded="true"
    //         >
    //           <i className="fa fa-ellipsis-v" aria-hidden="true" />
    //         </Link>
    //         <ul className="dropdown-menu">
    //           <li>
    //             <Link
    //               to="/Kader/Jobs/Post-Job"
    //               className="dropdown-item"
    //             >
    //               <img src={Eye1} className="me-2" alt="img" />
    //               Job Detail
    //             </Link>
    //           </li>
    //           <li>
    //             <Link
    //               to="/Kader/sales/edit-sales"
    //               className="dropdown-item"
    //             >
    //               <img src={EditIcon} className="me-2" alt="img" />
    //               Edit Job
    //             </Link>
    //           </li>
    //           <li>
    //             <Link
    //               to="#"
    //               className="dropdown-item"
    //               data-bs-toggle="modal"
    //               data-bs-target="#showpayment"
    //             >
    //               <img src={Dollar1} className="me-2" alt="img" />
    //               Show Payments
    //             </Link>
    //           </li>
    //           <li>
    //             <Link
    //               to="#"
    //               className="dropdown-item"
    //               data-bs-toggle="modal"
    //               data-bs-target="#createpayment"
    //             >
    //               <img src={plusCircle} className="me-2" alt="img" />
    //               Create Payment
    //             </Link>
    //           </li>
    //           <li>
    //             <Link to="#" className="dropdown-item">
    //               <img src={Download} className="me-2" alt="img" />
    //               Download CV
    //             </Link>
    //           </li>
    //           <li>
    //             <Link
    //               to="#"
    //               className="dropdown-item confirm-text"
    //               onClick={confirmText}
    //             >
    //               <img src={delete1} className="me-2" alt="img" />
    //               Delete Job
    //             </Link>
    //           </li>
    //         </ul>
    //       </div>
    //     </>
    //   ),
    // },
    {
      title: `${t('CV')}`,
      render: (text , record) => (
        <>
          <>
            <Link className="confirm-text" to="#">
              <img src={Download} alt="img" />
            </Link>
          </>
        </>
      ),
    },
  ];
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>{t('Received Jobs Application')}</h4>
              <h6>{t('Manage your Jobs')}</h6>
            </div>
            <div className="page-btn">
              <Link to="/Kader/Jobs/Jobslist" className="btn btn-added">
                <img src={Backward} alt="img" className="me-1" />
                {t('Back To Jobs Application')}
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <Tabletop inputfilter={inputfilter} togglefilter={togglefilter} />
              {/* /Filter */}
              <div
                className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                id="filter_inputs"
                style={{ display: inputfilter ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <input type="text" placeholder={t('Enter Name')} />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <input type="text" placeholder={t('Enter Category Job')} />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <Select2
                          className="select"
                          data={options}
                          options={{
                            placeholder: t('Choose Workplace mode'),
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <Link to="#" className="btn btn-filters ms-auto">
                          <img src={search_whites} alt="img" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <Table
                  props={props} columns={columns1}
                  // dataSource={data}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </>
  );
};

export default RecievedJobsApp;


