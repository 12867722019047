/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import { Upload , PlusIcon , DeleteIcon} from "../../EntryFile/imagePath";
import "react-select2-wrapper/css/select2.css";
import React, { useEffect, useState, useRef} from "react";
import { Link , useHistory , useParams} from "react-router-dom";
import "../../assets/plugins/twitter-bootstrap-wizard/form-wizard.css";
import "../../assets/plugins/twitter-bootstrap-wizard/jquery.bootstrap.wizard.min.js";
import "../../assets/plugins/twitter-bootstrap-wizard/prettify.js";
import "../../assets/plugins/twitter-bootstrap-wizard/form-wizard.js";
import FeatherIcon from "feather-icons-react";
import DatePicker from "./StartFlatpicker.jsx";
import DatePicker1 from "./EndFlatpicker.jsx";
import axios from "axios";
import { baseURL } from "../../InitialPage/Authentecation.jsx";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

let i= 0 ;
let j = 0 ; 
let y = 0 ;
let z = 0 ; 
const EditProduct = () => {
  const {t} = useTranslation();
  function base64toFile(base64String, fileName, mimeType) {
    // Decode the Base64 String
    const binaryData = atob(base64String);
  
    // Create a Blob from Binary Data
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: mimeType || 'application/octet-stream' });
  
    // Create a File from Blob
    const file = new File([blob], fileName || 'file', { type: mimeType || 'application/octet-stream' });
  
    //  Return the File object
    return file ; 
  }


  const history = useHistory();
  const [str, setStr] = useState("Course-details");
  const {id} = useParams() ; 
  
const [instructorName , setInstructorName] = useState('');
const [instructorJobTitle , setInstructorJobTitle] = useState('');
const [instructorImage , setInstructorImage] = useState('');
const [courseImage , setCourseImage] = useState('');
const [instructorIdState , setInstructorIdState] = useState('');

  const[dataApi , setDataApi] = useState({});
  const [imgUrl , setImageURL] = useState();
  const [imgUrl1 , setImageURL1] = useState();
  const base64String = courseImage;
  const base64String1 = instructorImage;
  const fileName = "example.jpg"; // Name with Extention
  const mimeType = "image/jpeg"; // I can Change MIME type if i want 
  const [isVisible , setVisible] = useState(true); 
  const [isVisible1 , setVisible1] = useState(true); 
   // To handle Image 
   const [image , setImage] = useState(null) ; 
   const [image1 , setImage1] = useState(null) ; 
   
   const handleFileChange = (e)=>{
    const file = e.target.files[0] ; 
    const reader = new FileReader();
    reader.onload = function (e) {
        const dataUrl = e.target.result;
        setImageURL(dataUrl);
      };
    reader.readAsDataURL(file);
    setImage(file);
    setVisible(true);
  }

  const handleFileChange1 = (e)=>{
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = function (e){
      const dataUrl = e.target.result ;
      setImageURL1(dataUrl);
    }
    reader.readAsDataURL(file);
    setImage1(file);
    setVisible1(true);
  }


  const [startTimeProps , setStartTimeProps] = useState("");
  const [endTimeProps , setEndTimeProps] = useState("");
  const [meetingLink , setMeetingLink] = useState("");

const [course , setCourseDetails] = useState({});
  const [elements, setElements] = useState([]);
  const [elements11, setElements11] = useState([]);

  const [elements22 , setElements22] = useState([]);
  const [elements33 , setElements33] = useState([]);
  const [elements44 , setElements44] = useState([]);

  const [contentId , setContentId] = useState("");
  useEffect(()=>{
  axios.get(`${baseURL}/Courses/Course/${id}?IncludeCourseDetails=true&WithImages=true`,{
    headers:{
      Authorization: `Bearer ${window.localStorage.getItem("result")}`
    }
  }).then(res => {
    setDataApi(res.data.result);
    const data = res.data.result.Instructors[0].NameAr;
    const data1 = res.data.result.Instructors[0].JobTitleAr;
    setInstructorName(data);
    setInstructorJobTitle(data1);
    setCourseImage(res.data.result.Image);

    setStartTimeProps(res.data.result.Sections[0].Content[0].MeetingStartTime);
    setEndTimeProps(res.data.result.Sections[0].Content[0].MeetingEndTime);
    setMeetingLink(res.data.result.Sections[0].Content[0].MeetingLink);
    const instructorId = res.data.result.Instructors[0].Id;
    setInstructorIdState(instructorId);
    setContentId(res.data.result.Sections[0].Content[0].Id);
    setElements11(res.data.result.Instructors[0].Experiences);
    setElements22(res.data.result.Objectives);
    setElements33(res.data.result.Requirements);
    setElements44(res.data.result.Targets);
    axios.get(`${baseURL}/Courses/CourseInstructor/${instructorId}/Image` , {
       headers:{
      Authorization: `Bearer ${window.localStorage.getItem("result")}`
    }
    }).then(res => setInstructorImage(res.data.result)).catch(err => err);
  }).catch(err => err);
},[]);

  //====================================== Start Form one ========================================

// To fetch data from api 
let title1, icon1 = "";
const handleSubmit1 = (e)=>{
  e.preventDefault();
  i = elements11[elements11.length - 1]?.Order || 0;
  const formData = {
    id : id ,
    titleAr : e.target.elements.titleAr.value ,
    secondaryTitleAr : e.target.elements.secondaryTitleAr.value,
    price : e.target.elements.price.value ,
    descriptionAr : e.target.elements.descriptionAr.value,
    image : isVisible?image??base64toFile(base64String , fileName , mimeType):image 
  }
  setCourseDetails(formData);
  Swal.fire({
    title: `${t('Now Loading')}` , 
    allowEscapeKey: false , 
    allowOutsideClick : false ,
    didOpen:()=>{
      Swal.showLoading();
      axios.put(`${baseURL}/Courses/Course`, formData , {
        headers:{
          Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
          "Content-Type" :"multipart/form-data"
        }
      }).then((res)=> {
        if(res.data.valid){
            title1 = `${t('Finished!')}`;
            icon1 = "success";
            Swal.close();
            setStr("Instructor-info");
        }else{
            title1 = `${t('Course Not Updated')}` ;
            icon1 = 'warning';
            Swal.close();
        }
      })
      .catch(()=>{
        Swal.fire({
          title: `${t('Course Not Updated')}` ,
          text : `${t('Please Check on other fields')}` , 
          timer: "3000",
          showConfirmButton: false 
        })
      });
    },
    didClose:()=>{
      Swal.fire({
        title: title1 ,  
        icon: icon1 , 
        timer : "5000" , 
        showConfirmButton : false
      })
    }
  })
}

const handleDeleteClick = ()=>{
  setVisible(false);
  setImage(null);
}
// =====================End Of Form 1 ====================================


// ======================= Start Of Form 2 =================================
// start To Handle Expereince 
const [title, setTitle] = useState("");
const [details, setDetails] = useState("");
const [title11, setTitle11] = useState("");
const [details11, setDetails11] = useState("");
const refInput1 = useRef(null);
const refInput2 = useRef(null);
const inputPlus = useRef(null);
// const [updateList , setUpdateList] = useState([]);

const handleAdd = (e) => {
  e.preventDefault();
  if(refInput1.current.value === "" && refInput2.current.value ===""){
    return false;
  }else{
    const newElement = {
      id: ++i,
      title: title,
      details: details,
      jsx: (
        <div key={Date.now()} className="row">
          <div className="col-6">
            <div className="form-group">
              <label style={{ textAlign: "center" }}>Title</label>
              <input
                type="text"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label style={{ textAlign: "center" }}>Details</label>
              <input
                type="text"
                name="details"
                value={details}
                onChange={(e) => setDetails(e.target.value)}
              />
            </div>
          </div>
        </div>
      ),
    };
    setElements([...elements, newElement]);
    // Reset the input fields after adding an element
    setTitle("");
    setDetails("");
  }
};

const handleDelete = (event, id) => {
  event.preventDefault();
  const updatedElements = elements.filter((element) => element.id !== id);
  setElements(updatedElements);
};

const handleDelete11 = (event ,id) =>{
  event.preventDefault();
  const updatedElements = elements11.filter((ele)=>ele.Id !== id);
  setElements11(updatedElements);
}
// End of Handle Experience

let title2 , icon2 = "";
const handleSubmit2 = (e)=>{
  e.preventDefault();
    inputPlus.current.click();
  j = elements22[elements22.length - 1]?.Order || 0 ; 
  y = elements33[elements33.length - 1]?.Order || 0 ; 
  z = elements44[elements44.length - 1]?.Order || 0 ; 

  const formData2={
    id : instructorIdState , 
    nameAr : e.target.elements.nameAr.value ,
    jobTitleAr: e.target.elements.jobTitleAr.value , 
    image : isVisible1?image1??base64toFile(base64String1 , fileName , mimeType):image1 
 }
 
 Swal.fire({
  title: `${t('Now Loading')}` , 
  allowEscapeKey: false , 
  allowOutsideClick : false ,
  didOpen:()=>{
    Swal.showLoading();
    axios.put(`${baseURL}/Courses/CourseInstructor`, formData2 , {
      headers:{
        Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
        "Content-Type" :"multipart/form-data"
      }
    }).then((res)=> {
      if(res.data.valid){
        if(refInput1.current.value !== "" && refInput2.current.value !== ""){
        elements.push({"id": ++i, "title" : refInput1.current?.value , "details" : refInput2.current?.value});
        }
        let elementSend = [];
      
        elements.map(item => {
          elementSend.push({
            ...item , 
            "jobDetailsAr": item.details , 
            "jobTitleAr" : item.title , 
            "order" : item.id
          })
        })
      
        elementSend.map(item =>{
          delete item.title ; 
          delete item.details ; 
          delete item.id ; 
          delete item?.jsx ;
        })
      
       
       const ExperienceObj = {
        "courseInstructorId" : instructorIdState , 
        "instructorExperiences":[
            ...elementSend
        ]
      }
      
      if(refInput1.current.value !== "" && refInput2.current.value !==""){
        axios.post(`${baseURL}/Courses/CourseInstructorExperience`,ExperienceObj,{
          headers:{
            Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
          }
        }).then(res=>res).catch(err => err);
      }
    
          title2 = `${t('Finished!')}` ;
          icon2 = "success";
          Swal.close();
          setStr("additional-info");
      }
    })
    .catch(()=>{
      Swal.fire({
        title: `${t('Course Not Updated')}` ,
        text : `${t('Please Check on other fields')}` , 
        timer: "3000",
        showConfirmButton: false 
      })
    });
  },
  didClose:()=>{
    Swal.fire({
      title: title2 ,  
      icon: icon2 , 
      timer : "3000" , 
      showConfirmButton : false
    })
  }
})

}
// ======================== End Of Form 2 ===================================

// ======================== Start Of Form 3 ==================================
const [startTime , setStartTime] = useState('');
const [endTime , setEndTime] = useState('');
const [formData3 , setFormData3] = useState({});


const handleChange3 = (e)=>{
  const {name , value} = e.target ; 
  setFormData3({
    ...formData3,
    [name] : value 
  })
}


// Start of Handle Objective 
const [objective , setObjective] = useState("");
const [objective1 , setObjective1] = useState("");
const [elements1, setElements1] = useState([]);
const refInput11 = useRef(null);

const handleAdd1 = (e)=>{
  e.preventDefault();
  if(refInput11.current.value === ""){
    return false ; 
  }else{
    const newElement = {
      id: ++j,
      objective: objective,
      jsx: (
        <div key={Date.now()} className="row">
          <div className="col-12">
        <div className="form-group">
          <br></br>
          <input type="text" name="objective" value={objective} onChange={(e) => setObjective(e.target.value)}/>
        </div>
      </div>
        </div>
      )
    };
    setElements1([...elements1 , newElement]) ;
    // To reset value 
    setObjective("");
  }
}

const handleDelete1 = (id , e)=>{
  e.preventDefault();
  const updatedElements = elements1.filter(elements1 => elements1.id !== id);
  setElements1(updatedElements);
}

const handleDelete22 = (event ,id) =>{
  event.preventDefault();
  const updatedElements = elements22.filter((ele)=>ele.Id !== id);
  setElements22(updatedElements);
}

// End of Handle Objective 

// Start of Handle Requirements 
const [requirements , setRequirements] = useState('');
const [requirements2 , setRequirements2] = useState('');
const [elements2 , setElements2] = useState([]);
const refInput22 = useRef(null) ; 

const handleAdd2 = (e)=>{
  e.preventDefault();
  if(refInput22.current.value === ""){
    return false ; 
  }else{
    const newElement = {
      id: ++y,
      requirements : requirements , 
      jsx: (
        <div key={Date.now()} className="row">
          <div className="col-12">
        <div className="form-group">
          <br></br>
          <input type="text" name="requirements" value={requirements} onChange={(e) => setRequirements(e.target.value)}/>
        </div>
      </div>
        </div>
      )
    };
    setElements2([...elements2 , newElement]) ;
    // To reset value 
    setRequirements('');
  }
}

const handleDelete2 = (id , e)=>{
  e.preventDefault();
  const updatedElements = elements2.filter(elements2 => elements2.id !== id);
  setElements2(updatedElements);
}

const handleDelete33 = (event ,id) =>{
  event.preventDefault();
  const updatedElements = elements33.filter((ele)=>ele.Id !== id);
  setElements33(updatedElements);
}
// End of Handle Requirements

// Start of Handle Targets 
const [targets , setTargets] = useState('');
const [targets3 , setTargets3] = useState('');
const [elements3 , setElements3] = useState([]);
const refInput33 = useRef(null) ;

const handleAdd3 = (e)=>{
  e.preventDefault();
  if(refInput33.current.value === ""){
    return false ; 
  }else{
    const newElement = {
      id: ++z,
      targets: targets,
      jsx: (
        <div key={Date.now()} className="row">
          <div className="col-12">
        <div className="form-group">
          <br></br>
          <input type="text" name="targets" value={targets} onChange={(e) => setTargets(e.target.value)}/>
        </div>
      </div>
        </div>
      )
    };
    setElements3([...elements3 , newElement]) ;
    // To reset value 
    setTargets("");
  }


}

const handleDelete3 = (id , e)=>{
  e.preventDefault();
  const updatedElements = elements3.filter(elements3 => elements3.id !== id);
  setElements3(updatedElements);
}

const handleDelete44 = (e , id)=>{
  e.preventDefault();
  const updatedElements = elements44.filter(element => element.Id !== id);
  setElements44(updatedElements);
}
// End of Handle Targets

const handleDateChange = (selectedDate)=>{
  setStartTime(selectedDate);
}

const handleDateChange1 = (selectedDate) =>{
  setEndTime(selectedDate);
}


let title3 , icon3 = "";
const handleSubmit3 = (e)=>{
  e.preventDefault();

  const formData4={
    "contentAr" : course.secondaryTitleAr ,
    "id" : contentId , 
    "meetingStartTime" :startTime||startTimeProps , 
    "meetingEndTime" : endTime||endTimeProps , 
    "meetingLink" : formData3.meetingLink || meetingLink,
    "descriptionAr" : course.descriptionAr ,
    "previewLink" : formData3.meetingLink || meetingLink
  }


  Swal.fire({
    title: `${t('Now Loading')}` , 
    allowEscapeKey: false , 
    allowOutsideClick : false ,
    didOpen:()=>{
      Swal.showLoading();
      axios.put(`${baseURL}/Courses/CourseSectionContent`,formData4 ,{
    headers: {
      Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
    }
  }).then(res => {
      if(res.data.valid){
        if(refInput11.current.value !== ""){
          elements1.push({"id":++j , "objective" : refInput11.current?.value});
        }
        if(refInput22.current.value !== ""){
          elements2.push({"id":++y , "requirements" : refInput22.current?.value});
        }
        if(refInput33.current.value !== ""){
          elements3.push({"id":++z , "targets" : refInput33.current?.value});
        }
      
        let element1Send = [];
        let element2Send = [];
        let element3Send = [];
      
      
        elements1.map(item => {
          element1Send.push({
            ...item , 
            "order" : item.id , 
            "objectiveAr" : item.objective
          })
        })
      
        elements2.map(item =>{
          element2Send.push({
            ...item , 
            "order" : item.id , 
            "requirementAr" :  item.requirements
          })
        })
      
        elements3.map(item =>{
          element3Send.push({
            ...item , 
            "order" : item.id ,
            "targetAr" : item.targets
          })
        })
      
      
        element1Send.map(item =>{
          delete item.objective ; 
          delete item.id ; 
          delete item?.jsx ; 
        })
      
        element2Send.map(item => {
          delete item.requirements ; 
          delete item.id ; 
          delete item?.jsx ; 
        })
      
        element3Send.map(item => {
          delete item.targets ; 
          delete item.id ; 
          delete item?.jsx ; 
        })
      
      
        const ObjectiveObj = {
          "courseId" : course.id , 
          "objectives" : [
              ...element1Send
          ]
        }
      
        const RequirementsObj = {
          "courseId" : course.id , 
          "requirements" : [
              ...element2Send
          ]
        }
      
        const TargetsObj = {
          "courseId" : course.id ,
          "targets" : [
              ...element3Send
          ]
        }
        if(refInput11.current.value !== ""){
          axios.post(`${baseURL}/Courses/CourseObjective`,ObjectiveObj,{
            headers:{
              Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
            }
          }).then(res=> res).catch(err => err);
        }
        
        
  if(refInput22.current.value !== ""){
    axios.post(`${baseURL}/Courses/CourseRequirement`,RequirementsObj,{
              headers:{
                Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
              }
            }).then(res=>res).catch(err => err)
  }

  if(refInput33.current.value !== ""){
    axios.post(`${baseURL}/Courses/CourseTarget`,TargetsObj,{
              headers:{
                Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
              }
            }).then(res=>res.data).catch(err => err);
  }
        title3 = `${t('Course Updated')}` ;
        icon3 = "success";
        Swal.close();
        history.push("/Kader/course/courselist-courses");
      }
  }).catch(()=>{
      Swal.fire({
        title: `${t('Course Not Updated')}` ,
        text : `${t('Time and History are old')}` , 
        timer: "3000",
        showConfirmButton: false 
      })
    });
    },
    didClose:()=>{
      Swal.fire({
        title: title3 ,  
        icon: icon3 , 
        timer : "3000" , 
        showConfirmButton : false
      })
    }
  })
}

// ======================== End Of Form 3 ===================================

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper cardhead">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">{t('Edit Course')}</h3>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Lightbox */}
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">{t('Editing Course Process')}</h4>
                </div>
                <div className="card-body">
                  <div id="basic-pills-wizard" className="twitter-bs-wizard">
                    <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                      <li className="nav-item">
                        <Link
                          to="#Course-details"
                          className={`nav-link ${str === 'Course-details' ? 'active' : ''}`}
                          // className="nav-link"
                          data-bs-toggle="tab"
                        >
                          <div
                            className="step-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Create Course Step"
                          >
                            <FeatherIcon icon="plus-square" />
                          </div>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="#Instructor-info"
                          className={`nav-link ${str === 'Instructor-info' ? 'active' : ''}`}
                          // className="nav-link"
                          data-bs-toggle="tab"
                        >
                          <div
                            className="step-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Instructor Info"
                          >
                            <FeatherIcon icon="users" />
                          </div>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="#additional-info"
                          className={`nav-link ${str === 'additional-info' ? 'active' : ''}`}
                          // className="nav-link"
                          data-bs-toggle="tab"
                        >
                          <div
                            className="step-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Final Step"
                          >
                            <FeatherIcon icon="edit" />
                          </div>
                        </Link>
                      </li>
                    </ul>
                    {/* wizard-nav */}
                    <div className="tab-content twitter-bs-wizard-tab-content">
                      <div style={{ display: str == 'Course-details' ? 'block' : 'none' }} className="tab-pane" id="Course-details">
                        <div className="mb-4">
                          <h5>{t('Enter Your Course Details')}</h5>
                        </div>
                    <form onSubmit={handleSubmit1}>
                        <div className="row">
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Title')}</label>
                    <input type="text" required name="titleAr" defaultValue={dataApi?.TitleAr}/>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Sub title')}</label>
                    <input type="text" required name="secondaryTitleAr" defaultValue={dataApi?.SecondaryTitleAr}/>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Price')}</label>
                    <input type="number" required name="price" defaultValue={dataApi?.Price}  />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>{t('Course Description')}</label>
                    <textarea className="form-control" required name="descriptionAr" defaultValue={dataApi?.DescriptionAr}/>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>{t('Course Image')}</label>
                    <div className="image-upload">
                      <input type="file" defaultValue={dataApi?.Image} onChange={handleFileChange}/>
                      <div className="image-uploads">
                        <img src={Upload} alt="img" />
                        <h4>{t('Drag and drop a file to upload')}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 ms-3" style={{display: (isVisible)? "block" : "none"}}>
                                    <div className="product-list">
                                        <ul className="row">
                                            <li className="ps-0">
                                                <div className="productviews">
                                                    <div className="productviewsimg">
                                                    {isNaN((image?.size / 1024).toFixed(1))? (
                                                            <img src={`data:image/jpeg;base64,${courseImage}`}/>
                                                        ):<img src={imgUrl} />}
                                                    </div>
                                                    <div className="productviewscontent">
                                                        <div className="productviewsname">
                                                            <h2>{dataApi?.SecondaryTitleAr}</h2>
                                                            <h3>{isNaN((image?.size / 1024).toFixed(1)) ? "581" : (image?.size / 1024).toFixed(1) }kb</h3>
                                                        </div>
                                                        <a className="hideset" onClick={handleDeleteClick}>
                                                            x
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
              </div>
                        <ul className="pager wizard twitter-bs-wizard-pager-link">
                          <li className="next">
                          {/* onClick={() => setStr('Instructor-info')} */}
                            <button className="btn btn-primary">
                              {t('Next')} <i className="bx bx-chevron-right ms-1" />
                            </button>
                          </li>
                        </ul>
                    </form>
                      </div>
                      {/* tab pane */}
                      <div className="tab-pane" style={{ display: str == 'Instructor-info' ? 'block' : 'none' }} id="Instructor-info">
                        <div>
                          <div className="mb-4">
                            <h5>{t('Enter Your Info')}</h5>
                          </div>
                          <form onSubmit={handleSubmit2}>
                          <div className="row">
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Instructor Name')}</label>
                    <input type="text" required name="nameAr" defaultValue={instructorName}/>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Instructor Title')}</label>
                    <input type="text" required name="jobTitleAr" defaultValue={instructorJobTitle}/>
                  </div>
                </div>
                <h3>{t('Experience')}</h3>
                <div className="col-6">
        <div className="form-group">
          <label style={{ textAlign: "center" }}>{t('Title')}</label>
          <input
            type="text"
            name="title"
            ref={refInput1}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
      </div>
      <div className="col-6">
        <div className="form-group">
          <label style={{ textAlign: "center" }}>{t('Details')}</label>
          <input
            type="text"
            name="details"
            ref={refInput2}
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          />
        </div>
        <div className="position-relative">
          <button className="adjust-icons" ref={inputPlus} onClick={handleAdd}>
            <img src={PlusIcon} alt="img" className="me-1" />
          </button>
        </div>
      </div>
      <ul>
        {elements.map(({ jsx, id }) => (
          <li key={id} className="adjust">
            {jsx}
            <div className="position-relative">
              <button
                className="adjust-icons"
                onClick={(event) => handleDelete(event, id)}
              >
                <img src={DeleteIcon} alt="Delete Icon" />
              </button>
            </div>
          </li>
        ))}
      </ul>
                           <ul>
                           <br></br>
                           {elements11.length? elements11.map(({Id , JobDetailsAr , JobTitleAr})=>(
                             <li key={Id} className="row">
                              <div className="col-6">
        <div className="form-group">
          <input
            type="text"
            name="title"
            defaultValue={JobTitleAr}
            onChange={(e) => setTitle11(e.target.value)}
            onBlur={()=>{
              const newElement={
                "id" : Id ,
                "jobTitleAr" : title11 , 
                "jobDetailsAr" : details11 || JobDetailsAr
              }
              // setUpdateList(newElement);
              axios.put(`${baseURL}/Courses/CourseInstructorExperience`, newElement , 
{
  headers:{
    Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
  }
}).then(res=>res.data).catch(err => err)
            }}
          />
        </div>
      </div>
      <div className="col-6">
        <div className="form-group">
          <input
            type="text"
            name="details"
            defaultValue={JobDetailsAr}
            onChange={(e)=> setDetails11(e.target.value)}
            onBlur={()=>{
              const newElement={
                "id" : Id ,
                "jobDetailsAr" : details11,
                "jobTitleAr" : title11 || JobTitleAr
              }
              // setUpdateList(newElement);
              axios.put(`${baseURL}/Courses/CourseInstructorExperience`, newElement , 
{
  headers:{
    Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
  }
}).then(res=>res.data).catch(err => err)
            }}
          />
        </div>
        <div className="position-relative">
          <button className="adjust-icons" onClick={(e)=>{
            handleDelete11(e,Id)
              axios.delete(`${baseURL}/Courses/CourseInstructorExperience/${Id}`,{
                headers :{
                  Authorization : `Bearer ${window.localStorage.getItem("result")}`,
                }
              }).then(res => res).catch(err => err);
            }}>
            <img src={DeleteIcon} alt="img" className="me-1" />
          </button>
        </div>
      </div><br></br><br></br><br></br><br></br>
                             </li>
                             
                             )):<>
                             <br></br><br></br>
                             </>}
                           </ul>
               <div className="col-12">
                  <div className="form-group">
                    <label>{t('Instructor Image')}</label>
                    <div className="image-upload">
                      <input type="file" defaultValue={instructorImage} onChange={handleFileChange1}/>
                      <div className="image-uploads">
                        <img src={Upload} alt="img" />
                        <h4>{t('Drag and drop a file to upload')}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 ms-3" style={{display: (isVisible1)? "block" : "none"}}>
                                    <div className="product-list">
                                        <ul className="row">
                                            <li className="ps-0">
                                                <div className="productviews">
                                                    <div className="productviewsimg">
                                                    {isNaN((image1?.size / 1024).toFixed(1))? (
                                                            <img src={`data:image/jpeg;base64,${instructorImage}`}/>
                                                        ):<img src={imgUrl1} />}
                                                    </div>
                                                    <div className="productviewscontent">
                                                        <div className="productviewsname">
                                                            <h2>{dataApi?.SecondaryTitleAr}</h2>
                                                            <h3>{isNaN((image1?.size / 1024).toFixed(1)) ? "581" : (image1?.size / 1024).toFixed(1) }kb</h3>
                                                        </div>
                                                        <a className="hideset" onClick={handleDeleteClick}>
                                                            x
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                </div>
              </div>
                          <ul className="pager wizard twitter-bs-wizard-pager-link">
                            <li className="previous">
                              <button
                                onClick={(e) => {e.preventDefault();setStr('Course-details')}}
                                className="btn btn-primary"
                              >
                                <i className="bx bx-chevron-left me-1" /> {t('Previous')}
                              </button>
                            </li>
                            <li className="next">
                              <button
                                // onClick={() => setStr('additional-info')}
                                className="btn btn-primary"
                              >
                                {t('Next')} <i className="bx bx-chevron-right ms-1" />
                              </button>
                            </li>
                          </ul>
                          </form>
                        </div>
                      </div>
                      {/* tab pane */}
                      <div className="tab-pane" id="additional-info" style={{ display: str == 'additional-info' ? 'block' : 'none' }}>
                        <div>
                          <div className="mb-4">
                            <h5>{t('Additional Details')}</h5>
                          </div>
                          <form>
                          <div className="row">
                          <DatePicker title={t('Meeting Start Time')} onDateChange={handleDateChange} dataProps={startTimeProps.replace("T"," ")}/>
                  <DatePicker1 title={t('Meeting End Time')} onDateChange={handleDateChange1} dataProps={endTimeProps.replace("T"," ")}/>
                          <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Course Link')}</label>
                    <input type="text" name="meetingLink" required defaultValue={meetingLink} onChange={handleChange3}/>
                  </div>
                           </div>
                          
               {/* Start of Objective */}
               <h4>{t('Objective')}</h4>
               <br></br>
               <br></br>
      <div className="col-12">
        <div className="form-group">
          <input 
            type="text" 
            name="objective" 
            ref={refInput11} 
            value={objective} 
            onChange={(e) => setObjective(e.target.value)} />
        </div>
      </div>
      <div className="position-relative">
        <button className="adjust-icons" onClick={handleAdd1}>
          <img src={PlusIcon} alt="img" className="me-1" />
        </button>
      </div>

      <ul>
        {elements1.map(({ jsx, id }) => (
          <li key={id} className="adjust">
            {jsx}
            <div className="position-relative">
            <button className="adjust-icons" onClick={(e)=>handleDelete1(id,e)}>
              <img src={DeleteIcon} alt="Delete Icon" />
            </button>
            </div>
          </li>
        ))}
      </ul>

                        <ul>
                           <br></br>
                           {elements22.length? elements22.map(({Id , ObjectiveAr})=>(
                             <li key={Id} className="row">
                                <div className="col-12">
        <div className="form-group">
          <input 
            type="text" 
            name="objective" 
            defaultValue={ObjectiveAr}  
            onChange={(e) => setObjective1(e.target.value)}
            onBlur={()=>{
              const newElement={
                "id" : Id ,
                "objectiveAr" : objective1 || ObjectiveAr
              }
              axios.put(`${baseURL}/Courses/CourseObjective`,newElement,{
                headers:{
                  Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
                }
              }).then(res => res.data).catch(err => err);
            }}
             />
        </div>
      </div>
      <div className="position-relative">
        <button className="adjust-icons" onClick={(e)=>{
          handleDelete22(e , Id);

          axios.delete(`${baseURL}/Courses/CourseObjective/${Id}`,{
              headers :{
                Authorization : `Bearer ${window.localStorage.getItem("result")}`,
              }
          }).then(res => res.data).catch(err => err);
        }}>
          <img src={DeleteIcon} alt="img" className="me-1" />
        </button>
      </div>
        <br></br>
                             </li>
                             
                             )):<>
                             <br></br>
                             </>}
                           </ul>
               {/* End of Objective */}
                
                {/* Start of Requirements */}
                <h4>{t('Requirements')}</h4>
                <br></br>
               <br></br>
      <div className="col-12">
        <div className="form-group">
          <input type="text" name="requirements" ref={refInput22} value={requirements} onChange={(e) => setRequirements(e.target.value)} />
        </div>
      </div>
      <div className="position-relative">
        <button className="adjust-icons" onClick={handleAdd2}>
          <img src={PlusIcon} alt="img" className="me-1" />
        </button>
      </div>

      <ul>
        {elements2.map(({ jsx, id }) => (
          <li key={id} className="adjust">
            {jsx}
            <div className="position-relative">
            <button className="adjust-icons" onClick={(e)=>handleDelete2(id,e)}>
              <img src={DeleteIcon} alt="Delete Icon" />
            </button>
            </div>
          </li>
        ))}
      </ul>
      <ul>
                           <br></br>
                           {elements33.length? elements33.map(({Id , RequirementAr})=>(
                             <li key={Id} className="row">
                                <div className="col-12">
        <div className="form-group">
          <input 
            type="text" 
            name="requirements" 
            defaultValue={RequirementAr}  
            onChange={(e) => setRequirements2(e.target.value)}
            onBlur={()=>{
              const newElement={
                "id" : Id ,
                "requirementAr" : requirements2 || RequirementAr
              }
              axios.put(`${baseURL}/Courses/CourseRequirement/`,newElement,{
                headers:{
                  Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
                }
              }).then(res => res.data).catch(err => err);
            }}
             />
        </div>
      </div>
      <div className="position-relative">
        <button className="adjust-icons" onClick={(e)=>{
          handleDelete33(e , Id);

          axios.delete(`${baseURL}/Courses/CourseRequirement/${Id}`,{
              headers :{
                Authorization : `Bearer ${window.localStorage.getItem("result")}`,
              }
          }).then(res => res.data).catch(err => err);
        }}>
          <img src={DeleteIcon} alt="img" className="me-1" />
        </button>
      </div>
        <br></br>
                             </li>
                             
                             )):<>
                             <br></br>
                             </>}
                           </ul>
                {/* End of Requirements */}
               
                 {/* Start of Targets */}
                 <h4>{t('Targets')}</h4>
                 <br></br>
               <br></br>
      <div className="col-12">
        <div className="form-group">
          <input 
            type="text" 
            name="targets" 
            ref={refInput33} 
            value={targets} 
            onChange={(e) => setTargets(e.target.value)} />
        </div>
      </div>
      <div className="position-relative">
        <button className="adjust-icons" onClick={handleAdd3}>
          <img src={PlusIcon} alt="img" className="me-1" />
        </button>
      </div>

      <ul>
        {elements3.map(({ jsx, id }) => (
          <li key={id} className="adjust">
            {jsx}
            <div className="position-relative">
            <button className="adjust-icons" onClick={(e)=>handleDelete3(id,e)}>
              <img src={DeleteIcon} alt="Delete Icon" />
            </button>
            </div>
          </li>
        ))}
      </ul>

                       <ul>
                           <br></br>
                           {elements44.length? elements44.map(({Id , TargetAr})=>(
                             <li key={Id} className="row">
                                <div className="col-12">
        <div className="form-group">
          <input 
            type="text" 
            name="targets" 
            defaultValue={TargetAr}  
            onChange={(e) => setTargets3(e.target.value)}
            onBlur={()=>{
              const newElement={
                "id" : Id ,
                "targetAr" : targets3 || TargetAr
              }
              axios.put(`${baseURL}/Courses/CourseTarget/`,newElement,{
                headers:{
                  Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
                }
              }).then(res => res.data).catch(err => err);
            }}
             />
        </div>
      </div>
      <div className="position-relative">
        <button className="adjust-icons" onClick={(e)=>{
          handleDelete44(e , Id);
          axios.delete(`${baseURL}/Courses/CourseTarget/${Id}`,{
              headers :{
                Authorization : `Bearer ${window.localStorage.getItem("result")}`,
              }
          }).then(res => res.data).catch(err => err);
        }}>
          <img src={DeleteIcon} alt="img" className="me-1" />
        </button>
      </div>
        <br></br>
                             </li>
                             
                             )):<>
                             <br></br>
                             </>}
                           </ul>
                 {/* End of Targets */}
               </div>
                          </form>
                          <ul className="pager wizard twitter-bs-wizard-pager-link">
                            <li className="previous">
                              <button
                                onClick={() => setStr('Instructor-info')}
                                className="btn btn-primary"
                              >
                                <i className="bx bx-chevron-left me-1" /> {t('Previous')}
                              </button>
                            </li>
                            <li className="float-end">
                              <Link
                                to="#"
                                className="btn btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target=".confirmModal"
                              >
                                {t('Submit')}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/* tab pane */}
                    </div>
                    {/* end tab content */}
                  </div>
                </div>
                {/* end card body */}
              </div>
            </div>
            {/* /Wizard */}
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      <div className="modal fade confirmModal" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-3">
            <div className="modal-header border-bottom-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="text-center">
                <h5 className="mb-3">{t('Confirm Save Changes')}</h5>
                <button
                  type="button"
                  className="btn btn-dark w-md me-1"
                  data-bs-dismiss="modal"
                >
                  {t('Close')}
                </button>
                <button
                  type="button"
                  className="btn btn-primary w-md me-1"
                  data-bs-dismiss="modal"
                 onClick={handleSubmit3}
                >
                  {t('Save changes')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditProduct;
