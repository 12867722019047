import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your transilations
import enTranslation from "../locales/en.json" ; 
import arTranslation from "../locales/ar.json" ;

const resources = {
    en: {
      translation: enTranslation,
    },
    ar: {
      translation: arTranslation,
    },
  };

  i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'ar', // Set the default language
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;