/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import React, { useEffect, useState } from "react";
import { Upload } from "../../EntryFile/imagePath";
import Select from "react-dropdown-select";
import "react-select2-wrapper/css/select2.css";
import { Country, State }  from 'country-state-city';
import {useHistory , useParams} from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { baseURL } from "../../InitialPage/Authentecation";
import { useTranslation } from "react-i18next";

const EditSupplier = () => {
  const {t} = useTranslation();
  const [data , setData] = useState({}); 

  function base64toFile(base64String, fileName, mimeType) {
    // Step 1: Decode the Base64 String
    const binaryData = atob(base64String);
  
    // Step 2: Create a Blob from Binary Data
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: mimeType || 'application/octet-stream' });
  
    // Step 3: Create a File from Blob
    const file = new File([blob], fileName || 'file', { type: mimeType || 'application/octet-stream' });
  
    // Step 4: Return the File object
    return file ; 
  }

  const base64String = data?.Image;
const fileName = "example.jpg"; // Provide a file name with extension
const mimeType = "image/jpeg"; // Specify the MIME type of the file

  const history = useHistory();
  const {id} = useParams() ;
  const [isVisible , setVisible] = useState(true); 
  const [imgUrl , setImageURL] = useState();

const options = [];
const options1 = [];

const loadData = ()=>{
Country.getAllCountries().map((item)=>{
  return(
    options.push({label: item.name , value:item.isoCode})
  )
});
}
loadData();
const [value , setValue] = useState([]);
const [value1 , setValue1] = useState([]);

const handleChangeCountry = (value)=>{
  setValue1(value) ; 
}

const handleChangeCity = (value) => {
  setValue(value);
}

value1.map((item)=>{
  options1.length = 0 ;
  return(
    State.getStatesOfCountry(item?.value).map((item )=>{
        options1.push({label:item.name , value: Math.random()})
    })
  )
})

// To handle Image 
const [image , setImage] = useState(null) ; 

const handleFileChange = (e)=>{
  const file = e.target.files[0] ; 
  const reader = new FileReader();
    reader.onload = function (e) {
        const dataUrl = e.target.result;
        setImageURL(dataUrl);
      };
    reader.readAsDataURL(file);
  setImage(file);
  setVisible(true);
}

useEffect(()=>{
  axios.get(`${baseURL}/Courses/Institute/${id}?IncludeCourses=false&WithImage=true`, {
    headers:{
      Authorization: `Bearer ${window.localStorage.getItem("result")}`
    }
  }).then(res => setData(res.data.result))
  .catch(err => err);
}, []);

let title , icon = "";
// To handle Submit 
const handleSubmit = (e)=>{
  e.preventDefault();
  const formData = {
    id: id , 
    nameAr : e.target.elements.nameAr.value ,
    nameEn : e.target.elements.nameEn.value ,
    aboutUsAr : e.target.elements.aboutUsAr.value , 
    aboutUsEn : e.target.elements.aboutUsEn.value ,
    phone : e.target.elements.phone.value , 
    email : e.target.elements.email.value , 
    city : value[0].label ,  
    country : value1[0].label , 
    image : isVisible?image??base64toFile(base64String , fileName , mimeType):image
  };

  Swal.fire({
    title: `${t('Now Loading')}` , 
    allowEscapeKey: false , 
    allowOutsideClick : false ,
    didOpen:()=>{
      Swal.showLoading();
      axios.put(`${baseURL}/Courses/Institute`, formData , {
        headers:{
          Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
          "Content-Type" :"multipart/form-data"
        }
      }).then((res)=> {
        if(res.data.valid){
          title = `${t('Finished!')}`;
          icon = "success";
          Swal.close();
          history.push("/Kader/people/Institutelist-people")
        }else{
            title = `${t('Institute Not Updated')}` ;
            icon = "warning" ;
            Swal.close();
        }
      })
      .catch(()=>{
        Swal.fire({
          title: `${t('Institute Not Updated')}` ,
          text : `${t('Please Upload New Image')}` ,
          timer :"3000" ,  
          showConfirmButton: false 
        })
      });
    },
    didClose:()=>{
      Swal.fire({
        title: title ,  
        icon:icon , 
        timer : "5000" , 
        showConfirmButton : false
      })
    }
  })
}

const handleDeleteClick = ()=>{
  setVisible(false);
  setImage(null);
}
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>{t('Institute Management')}</h4>
              <h6>{t('Update Institute')}</h6>
            </div>
          </div>
          {/* /add */}
          <div className="card">
            <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Institute Name(En)')}</label>
                    <input type="text" name="nameEn" required defaultValue={data?.NameEn}/>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Institute Name(Ar)')}</label>
                    <input type="text" name="nameAr" required defaultValue={data?.NameAr}/>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Email')}</label>
                    <input type="email" name="email" required defaultValue={data?.Email}/>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Phone')}</label>
                    <input type="text"  name="phone" required defaultValue={data?.Phone}/>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Choose Country')}</label>
                    <Select
                    name="Select"
                    style={{borderColor:"rgba(145,158,171,0.32",padding:"9px" , borderRadius:"5px"}}
                    options={options}
                    onChange={handleChangeCountry}
                    required
                    placeholder={t('Search')}
                    values={[{label: data?.Country}]}
                    >
                    </Select>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('City')}</label>
                    <Select
                     name="Select"
                     style={{borderColor:"rgba(145,158,171,0.32",padding:"9px" , borderRadius:"5px"}}
                     options={options1}
                     placeholder={t('Search')}
                     values={[{label:data?.City}]}
                     onChange={handleChangeCity}
                    >
                    </Select>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Course Description(En)')}</label>
                    <textarea className="form-control" name="aboutUsEn" defaultValue={data?.AboutUsEn}/>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Course Description(Ar)')}</label>
                    <textarea className="form-control" name="aboutUsAr" defaultValue={data?.AboutUsAr}/>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>{t('Institute logo')}</label>
                    <div className="image-upload">
                      <input type="file" onChange={handleFileChange} defaultValue={data?.Image} />
                      <div className="image-uploads">
                        <img src={Upload} alt="img" />
                        <h4>{t('Drag and drop a file to upload')}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 ms-3" style={{display: (isVisible)? "block" : "none"}}>
                                    <div className="product-list">
                                        <ul className="row">
                                            <li className="ps-0">
                                                <div className="productviews">
                                                    <div className="productviewsimg">
                                                    {isNaN((image?.size / 1024).toFixed(1))? (
                                                            <img src={`data:image/jpeg;base64,${data?.Image}`} alt='Api Image' />
                                                        ):<img src={imgUrl} alt='Local Image' />}
                                                    </div>
                                                    <div className="productviewscontent">
                                                        <div className="productviewsname">
                                                            <h2>{data?.NameEn}</h2>
                                                            <h3>{isNaN((image?.size / 1024).toFixed(1)) ? "581" : (image?.size / 1024).toFixed(1) }kb</h3>
                                                        </div>
                                                        <a href="#" className="hideset" onClick={handleDeleteClick}>
                                                            x
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                <div className="col-lg-12">
                  <button className="btn btn-submit me-2">{t('update')}</button>
                  <button className="btn btn-cancel" onClick={()=> history.push("/Kader/people/Institutelist-people")}>{t('Cancel')}</button>
                </div>
              </div>
              </form>
            </div>
          </div>
          {/* /add */}
        </div>
      </div>
    </>
  );
};

export default EditSupplier;
