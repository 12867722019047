let themeStorage = localStorage.getItem('selectedTheme');
const initState = {
    active : (themeStorage == 'light')? true : false
}

const reducer = (state = initState , action )=>{
    if(action.type == 'CHANGE'){
        return{
            active : ! state.active
        }
    }
    return state ;
}

export default reducer ; 