/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from 'react-tooltip';

import {
    ClosesIcon,
    Filter,
    Search
  } from "../EntryFile/imagePath";

const Tabletop = ({inputfilter,togglefilter}) => {
  return (
    <div className="table-top">
      <div className="search-set">
        <div className="search-path">
          <a
            className={` btn ${
              inputfilter ? "btn-filter setclose" : "btn-filter"
            } `}
            id="filter_search"
            onClick={() => togglefilter(!inputfilter)}
          >
            <img src={Filter} alt="img" />
            <span>
              <img src={ClosesIcon} alt="img" />
            </span>
          </a>
        </div>
        <div className="search-input">
          <Link to="#" className="btn btn-searchset">
            <img src={Search} alt="img" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Tabletop;
