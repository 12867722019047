import React, { useRef, useEffect } from 'react';
import Flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { Calendar } from '../../EntryFile/imagePath';
import { PropTypes } from 'prop-types' ;

const DatePicker = ({title , onDateChange , dataProps}) => {
  const datePickerRef = useRef(null);

  useEffect(() => {
    // Initialize Flatpickr when the component mounts
    const datePicker = new Flatpickr(datePickerRef.current, {
      // Flatpickr options go here
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      minDate : new Date(),
      allowInput: true ,
      onChange: function (dateString) {

        // If the onDateChange prop is provided, call the callback with the selected date
        if (typeof onDateChange === 'function') {
          const dateString1 = dateString[0];
          dateString1.setHours(dateString1.getHours()+3);
          const updateDateTime = dateString1.toISOString();
          onDateChange(updateDateTime);
        }
      },
    });
    
    // Cleanup Flatpickr instance when the component unmounts
    return () => {
      datePicker.destroy();
    };
  }, [onDateChange]); // Empty dependency array ensures that this effect runs only once when the component mounts


  return (
    <div className="col-lg-4 col-sm-6 col-12">
    <div className='form-group adjust'>
      <label>{title}</label>
      <input type="text" ref={datePickerRef} placeholder="yyyy-mm-dd  --:--" defaultValue={dataProps} required/>
      <img src={Calendar} alt="data-calendar" className='child' />
    </div>
    </div>
  );
};

DatePicker.propTypes = {
  title : PropTypes.any , 
  onDateChange: PropTypes.func,
  dataProps : PropTypes.any
}

export default DatePicker;
