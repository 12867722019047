import React , { useState,useEffect} from "react";
import { EditSet } from "../../EntryFile/imagePath";
import { useTranslation } from 'react-i18next';
import { baseURL } from "../../InitialPage/Authentecation";
import axios from "axios";
import {useHistory} from "react-router-dom";
import Swal from "sweetalert2";

const EmployeeProfile = () => {

  const {t} = useTranslation();
  const history = useHistory();
  const [data , setData] = useState({});

  function base64toFile(base64String, fileName, mimeType) {
    // Decode the Base64 String
    const binaryData = atob(base64String);
  
    // Create a Blob from Binary Data
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: mimeType || 'application/octet-stream' });
  
    // Create a File from Blob
    const file = new File([blob], fileName || 'file', { type: mimeType || 'application/octet-stream' });
  
    //  Return the File object
    return file ; 
  }

  const base64String = data?.Image;
const fileName = "example.jpg"; // Name with Extention
const mimeType = "image/jpeg"; // I can Change MIME type if i want 

  const [imgUrl , setImageURL] = useState();
    // To handle Image 
    const [image , setImage] = useState(null) ; 
    const handleFileChange = (e)=>{
     const file = e.target.files[0] ; 
     const reader = new FileReader();
     reader.onload = function (e) {
         const dataUrl = e.target.result;
         setImageURL(dataUrl);
       };
     reader.readAsDataURL(file);
     setImage(file);
 }
  useEffect(()=>{
    const fetchData = async()=>{
      try{
        const apiURL =`${baseURL}/Authentication/User/${localStorage.getItem('resultID')}?WithImages=true`;
        const result = await axios.get(apiURL, {
          headers:{
            Authorization : `Bearer ${window.localStorage.getItem('result')}`
          }
        })
        
        setData(result.data.result);
        // setImageData(result.data.result["Image"]);
      }catch(err){
        console.error("Error Handling Data" , err);
      }
    };
   fetchData();
  },[]);

  let title , icon = "";
  const handleSubmit = (e)=>{
    e.preventDefault();
    const formData = {
      userId : data.Id , 
      userRole : data.UserRole , 
      gender : data.Gender , 
      firstNameEn : e.target.elements.firstNameEn.value , 
      firstNameAr : data.FirstNameAr , 
      lastNameEn : e.target.elements.lastNameEn.value ,
      lastNameAr : data.LastNameAr , 
      userName : e.target.elements.userName.value , 
      phoneNumber : e.target.elements.phoneNumber.value,
      Image : image??base64toFile(base64String , fileName , mimeType)
    }

    Swal.fire({
      title: `${t('Now Loading')}` , 
      // timer : "10000" , 
      allowEscapeKey: false , 
      allowOutsideClick : false ,
      didOpen:()=>{
        Swal.showLoading();
        axios.put(`${baseURL}/Authentication/User`, formData , {
          headers:{
            Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
            "Content-Type" :"multipart/form-data"
          }
        }).then((res)=> {
          if(res.data.valid){
              title = `${t('Finished!')}`;
              icon = "success";
              history.push("/Kader/dashboard");
              Swal.close();
              window.location.reload();
          }else{
              title= `${t('User Not Updated')}` ;
              icon =  'warning';
              Swal.close();
          }
        })
        .catch(()=>{
          Swal.fire({
            title: `${t('User Not Updated')}` ,
            text : `${t('Please Check on other fields')}` , 
            timer : "3000" ,
            showConfirmButton: false 
          })
        });
      },
      didClose:()=>{
        Swal.fire({
          title: title ,  
          icon:icon , 
          timer : "5000" , 
          showConfirmButton : false
        })
      }
    })
    
  }
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>{t('Profile')}</h4>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <form onSubmit={handleSubmit}>
            <div className="card-body">
              <div className="profile-set">
                <div className="profile-head"></div>
                <div className="profile-top">
                  <div className="profile-content">
                
                    <div className="profile-contentimg">
                    {isNaN((image?.size / 1024).toFixed(1))? (
                                                            <img src={`data:image/jpeg;base64,${data?.Image}`} id="blah"/>
                                                        ):<img src={imgUrl} id="blah" />}
                      <div className="profileupload">
                        <input type="file" id="imgInp" onChange={handleFileChange} defaultValue={data?.Image}/>
                        <a href="#">
                        <img src={EditSet} alt="img" />
                        </a>
                      </div>
                    </div>
                    <div className="profile-contentname">
                      <h2>{data.UserName}</h2>
                      <h4>{t('Updates Your Photo and Personal Details.')}</h4>
                    </div>
                  </div>
                  {/* <div className="ms-auto">
                    <button className="btn btn-submit me-2">Save</button>
                    <button className="btn btn-cancel">Cancel</button>
                  </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label>{t('First Name')}</label>
                    <input type="text" name="firstNameEn" defaultValue={data.FirstNameEn} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label>{t('Last Name')}</label>
                    <input type="text" name="lastNameEn" defaultValue={data.LastNameEn} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label>{t('User Name')}</label>
                    <input type="text" name="userName" defaultValue={data.UserName} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label>{t('Phone')}</label>
                    <input type="text" name="phoneNumber" defaultValue={data.PhoneNumber} />
                  </div>
                </div>
                {/* <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label>User Name</label>
                    <input type="text" placeholder="+1452 876 5432" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label>Password</label>
                    <div className="pass-group">
                      <input
                        type={passwordShown ? "text" : "password"}
                        className=" pass-input"
                      />
                      <span
                        className={`fas toggle-password ${
                          passwordShown ? "fa-eye" : "fa-eye-slash"
                        }`}
                        onClick={togglePassword}
                      />
                    </div>
                  </div>
                </div> */}
                <div className="col-12">
                  <button className="btn btn-submit me-2" type="submit">Submit</button>
                  <button className="btn btn-cancel" onClick={()=> history.push("/Kader/dashboard")}>Cancel</button>
                </div>
              </div>
            </div>
            </form>
          </div>
          {/* /product list */}
        </div>
      </div>
    </>
  );
};

export default EmployeeProfile;
