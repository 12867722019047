import React, { useRef , useState,  useEffect } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Logo1 } from "../../EntryFile/imagePath";
import { useParams } from "react-router-dom";
import { baseURL } from "../../InitialPage/Authentecation";
import axios from "axios";
import { useTranslation } from "react-i18next";

function Invoice() {
  const {t} = useTranslation();
  const {id} = useParams();
  const [data , setData] = useState({});

  const fetchData = async()=>{
    try{
      const result = await axios.get(`${baseURL}/Courses/Course/${id}?IncludeCourseDetails=true&WithImages=true`,{
        headers:{
          Authorization: `Bearer ${window.localStorage.getItem("result")}`
        }
      })

      const dataFromApi = result.data.result ;
      const date = new Date(dataFromApi.Sections[0].Content[0].MeetingStartTime);
      const day = date.getDay().toString(); 
      const month = (date.getMonth()+1).toString();
      const year = date.getFullYear().toString();  
      const finalDate = day + "/" + month + "/" + year ;
      setData({
        TitleAr : dataFromApi.TitleAr ,
        CourseCategory : await getCategoryName(dataFromApi.CourseCategoryId),
        InstituteName : await getInstituteName(dataFromApi.InstituteId),
        Attndee : await getNumberEnrolledInCourse(id), 
        startTime : finalDate
      });

      
    }catch(err){
      console.error("Error Handling Data" , err);
    }
  }

  function getNumberEnrolledInCourse(id){
    try{
      const apiURL = `${baseURL}/Courses/Course/${id}/EnrolledUsers?PageIndex=1&PageSize=99`;
      const result = axios.get(apiURL , {
        headers:{
          Authorization : `Bearer ${window.localStorage.getItem('result')}`
        }
      }).then(res => res.data.totalItems ? res.data.totalItems : "No Attendee Enrolled until now");
      return result ;
    }catch(err){
      return err ; 
    }
  }
  function getCategoryName(CourseCategoryId){
    try{
      const apiURL = `${baseURL}/Courses/CourseCategory/${CourseCategoryId}?IncludeCourses=false&WithImage=false`;
      const result = axios.get(apiURL , {
        headers:{
          Authorization : `Bearer ${window.localStorage.getItem('result')}`
        }
      }).then(res => res.data.result.NameEn);
      return result ;
    }catch(err){
      return err ; 
    }
  }

function getInstituteName(CourseInstituteId){
  try{
    const apiURL = `${baseURL}/Courses/Institute/${CourseInstituteId}?IncludeCourses=false&WithImage=false`;
    const result = axios.get(apiURL , {
      headers:{
        Authorization : `Bearer ${window.localStorage.getItem('result')}`
      }
    }).then(res => res.data.result.NameEn);
    return result ; 
  }catch(err){
    return err ; 
  }
}

useEffect(()=>{
  fetchData();
},[])

  const refPDF = useRef(null);
  const downloadPDF = () => {
    const input = refPDF.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "px", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY =  50;
      pdf.addImage(
        imgData,
        "JPG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("invoice.pdf");
    });
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>{t('Jobs Application')}</h4>
              <h6>{t('Manage your Jobs')}</h6>
            </div>
          </div>
          {/* /product list */}
          <div className="card" id="myCard">
            <div className="card-body">
              <div ref={refPDF} style={{ fontWeight: "bold"}}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card" id="myCard-body">
                      <div className="card-header" id="myCard-header">
                <div style={{ marginLeft: "40px", width: "130px" }}>
                  <img src={Logo1} alt="logo" />
                </div>
                        <h4 className="card-title mb-0 text-center text-dark">
                          {t('Attndence Confirmation')}
                        </h4>
                      </div>
                      <div className="card-body" style={{ marginTop: "-10px" }}>
                        <div className="table-responsive">
                          <table className="table mb-0" id="specifec">
                            <thead>
                              <tr>
                                <th>{t('Institute')}</th>
                                <th>{t('Course Category')}</th>
                                <th>{t('Course Name')}</th>
                                <th>{t('Number Attendee')}</th>
                                <th>{t('date')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{data.InstituteName}</td>
                                <td>{data.CourseCategory}</td>
                                <td>{data.TitleAr}</td>
                                <td>{data.Attndee}</td>
                                <td>{data.startTime}</td>
                              </tr>
                            </tbody>
                          </table>
                          <div style={{marginTop:"30px" , marginLeft: "20px"}} className="text-dark">
                            <h4>{t('Best Regards :')}</h4>
                            <p style={{fontSize :"18px" , marginLeft :"30px"}}>{t('Kader operations Team')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button className="btn btn-primary" onClick={downloadPDF}>
                {t('Download as PDF')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Invoice;
