/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import { Upload , PlusIcon , DeleteIcon} from "../../EntryFile/imagePath";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import React, { useEffect, useState, useRef} from "react";
import { Link , useHistory } from "react-router-dom";
import "../../assets/plugins/twitter-bootstrap-wizard/form-wizard.css";
import "../../assets/plugins/twitter-bootstrap-wizard/jquery.bootstrap.wizard.min.js";
import "../../assets/plugins/twitter-bootstrap-wizard/prettify.js";
import "../../assets/plugins/twitter-bootstrap-wizard/form-wizard.js";
import FeatherIcon from "feather-icons-react";
import DatePicker from "./StartFlatpicker.jsx";
import DatePicker1 from "./EndFlatpicker.jsx";
import axios from "axios";
import { baseURL } from "../../InitialPage/Authentecation.jsx";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

let i= 0 ;
let j = 0 ; 
let y = 0 ;
let z = 0 ; 
const AddProduct = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const [str, setStr] = useState("Course-details");
  const [options1 , setOptions1] = useState([]);
  const [options11 , setOptions11] = useState([]);


  //====================================== Start Form one ========================================
  // Adjust name of options object 
  let options = [] ;
  let options2 = [] ;
  const newName = "text" ;
  const newName2 = "id";

  options1.map((item)=>{
    options.push({
      ...item , 
      [newName] : item.NameEn , 
      [newName2] : item.Id 
    });
  })

options11.map(item =>{
  options2.push({
    ...item , 
    [newName] : item.NameEn , 
    [newName2] : item.Id 
  })
})

options2.map(item =>{
  delete item.Id ;
  delete item.NameAr ; 
  delete item.NameEn ; 
  delete item.Country ;
  delete item.City ; 
  delete item.Phone ; 
  delete item.AboutUsAr ; 
  delete item.AboutUsEn ;
  delete item.Email
})
options.map(item =>{
  delete item.NameEn ; 
  delete item.Id ;
  delete item.NameAr ; 
  delete item.DescriptionAr;
  delete item.DescriptionEn ; 
  delete item.ParentId ; 
  delete item.Image ;
})

useEffect(()=>{
  axios.get(`${baseURL}/Courses/CourseCategory?WithImages=true&PageIndex=1&PageSize=90`,{
    headers:{
      Authorization : `Bearer ${window.localStorage.getItem('result')}`
    }
  }).then((res)=>{
    setOptions1(()=>{
      return res.data.result ; 
    })
  }).catch(err => err);
  const apiURL =`${baseURL}/Courses/Institute?PartialName=&City=&Country=&WithImages=false&PageIndex=1&PageSize=90`;
    axios.get(apiURL,{
      headers:{
        Authorization : `Bearer ${window.localStorage.getItem('result')}`
      }
    })
    .then((res)=> {
      setOptions11(res.data.result);
    })
    .catch(err => err)
} , []);


// To Control on form1 : 
const [selectedOption1 , setSelectedOption1] = useState('');
const [selectedOption2 , setSelectedOption2] = useState('');
const [formData1 , setFormData1] = useState({});
const [numericValue, setNumericValue] = useState('');
const [responseForm1 , setResponseForm1] = useState('');

const handleSelectChange1 = (e)=>{
  setSelectedOption1(e.target.value);
  setFormData1({
    ...formData1 , 
    courseCategoryId : e.target.value 
  })
}

const handleSelectChange2 = (e)=>{
  setSelectedOption2(e.target.value);
  setFormData1({
    ...formData1 , 
    instituteId : e.target.value
  })
}
const handleChange = (e)=>{
  const {name , value} = e.target ; 
  setFormData1({
    ...formData1 , 
    [name] : value 
  })
}

const handleChangeNumber = (e)=>{
  const inputValue = e.target.value ; 
  const cleanedValue = inputValue.replace(/[^0-9.]/g, '');
  setNumericValue(cleanedValue);
  setFormData1({
    ...formData1 , 
    [e.target.name] : cleanedValue 
  })
}
   // To handle Image 1  
   const handleFileChange = (e)=>{
    const file = e.target.files[0] ;
      setFormData1({
        ...formData1 , 
        image : file
      })
}

let title1 , icon1 = "" ;
const handleSubmit1 = (e)=>{
  e.preventDefault();
  Swal.fire({
    title: `${t('Now Loading')}` , 
    allowEscapeKey: false , 
    allowOutsideClick : false ,
    didOpen:()=>{
      Swal.showLoading();
      axios.post(`${baseURL}/Courses/Course`, formData1,{
        headers:{
          Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
          "Content-Type" :"multipart/form-data"
        }
      }).then((res)=> {
        if(res.data.message === "Saved Successfully"){
          setResponseForm1(res.data.resultID);
          title1 = `${t('Course Created')}` ;
          icon1 = "success" ;
          Swal.close();
          setStr('Instructor-info');
        }if(res.data.message === "Already Existing"){
                title1 = `${t('Course is Already Exist')}` ;
                icon1='warning' ;
                Swal.close();
        }
      }).catch(()=>{
        Swal.fire({
          title: `${t('Faild Process')}` ,
          text : `${t('Try Again')}` , 
          timer: 3000,
          showConfirmButton: false 
        })
      }) 
    },
    didClose:()=>{
      Swal.fire({
        title: title1 ,  
        icon: icon1 , 
        timer : "5000" , 
        showConfirmButton : false
      })
    }
  })
}

// =====================End Of Form 1 ====================================


// ======================= Start Of Form 2 =================================

const [formData2 , setFormData2] = useState({});

const handleChange2 = (e)=>{
  const {name , value} = e.target ; 
  setFormData2({
    ...formData2 , 
    "courseId": responseForm1 , 
    "order" : 1 , 
    [name] : value  
  })
}

   // To handle Image 2  
   const handleFileChange2 = (e)=>{
    const file = e.target.files[0] ;
      setFormData2({
        ...formData2 , 
        image : file
      })
}

// start To Handle Expereince 
const [title, setTitle] = useState("");
const [elements, setElements] = useState([]);
const [details, setDetails] = useState("");
const refInput1 = useRef(null);
const refInput2 = useRef(null);

const handleAdd = (e) => {
  e.preventDefault();
  if(refInput1.current.value === "" && refInput2.current.value ===""){
    return false;
  }else{
    const newElement = {
      id: ++i,
      title: title,
      details: details,
      jsx: (
        <div key={Date.now()} className="row">
          <div className="col-6">
            <div className="form-group">
              <br></br>
              <input
                type="text"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <br></br>
              <input
                type="text"
                name="details"
                value={details}
                onChange={(e) => setDetails(e.target.value)}
              />
            </div>
          </div>
        </div>
      ),
    };
    setElements([...elements, newElement]);
    // Reset the input fields after adding an element
    setTitle("");
    setDetails("");
  }
};

const handleDelete = (event, id) => {
  event.preventDefault();
  const updatedElements = elements.filter((element) => element.id !== id);
  setElements(updatedElements);
};

// End of Handle Experience
let title2 , icon2 = "";
const handleSubmit2 = (e)=>{
  e.preventDefault();

  elements.push({"id": ++i, "title" : refInput1.current?.value , "details" : refInput2.current?.value});

  let elementSend = [];

  elements.map(item => {
    elementSend.push({
      ...item , 
      "jobDetailsAr": item.details , 
      "jobTitleAr" : item.title , 
      "order" : item.id
    })
  })

  elementSend.map(item =>{
    delete item.title ; 
    delete item.details ; 
    delete item.id ; 
    delete item?.jsx ;
  })


  Swal.fire({
    title: `${t('Now Loading')}` , 
    allowEscapeKey: false , 
    allowOutsideClick : false ,
    didOpen:()=>{
      Swal.showLoading();
      axios.post(`${baseURL}/Courses/CourseInstructor`, formData2,{
        headers:{
          Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
          "Content-Type" :"multipart/form-data"
        }
      }).then((res)=> {
        if(res.data.message === "Saved Successfully"){
          const ExperienceObj = {
            "courseInstructorId" : res.data.resultID , 
            "instructorExperiences":[
                ...elementSend
            ]
          }
          axios.post(`${baseURL}/Courses/CourseInstructorExperience`,ExperienceObj,{
            headers:{
              Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
            }
          }).then(res=>res).catch(err => console.log("Experience Error"))
          title2 = `${t('Instructor Created')}`;
          icon2 = "success" ;
          Swal.close();
          setStr('additional-info');
        }if(res.data.message === "Already Existing"){
                title2= `${t('Instructor is Already Exist')}` ;
                icon2='warning';
                Swal.close();
        }
      }).catch(()=>{
        Swal.fire({
          title: `${t('Faild Process')}` ,
          text : `${t('Try Again')}` , 
          timer: 3000,
          showConfirmButton: false 
        })
      })
    },
    didClose:()=>{
      Swal.fire({
        title: title2 ,  
        icon: icon2 , 
        timer : "5000" , 
        showConfirmButton : false
      })
    }
  })
 
}
// ======================== End Of Form 2 ===================================

// ======================== Start Of Form 3 ==================================
const [startTime , setStartTime] = useState('');
const [endTime , setEndTime] = useState('');
const [formData3 , setFormData3] = useState({});

// Start of Handle Objective 
const [objective , setObjective] = useState("");
const [elements1, setElements1] = useState([]);
const refInput11 = useRef(null);

const handleAdd1 = (e)=>{
  e.preventDefault();
  if(refInput11.current.value === ""){
    return false ; 
  }else{
    const newElement = {
      id: ++j,
      objective: objective,
      jsx: (
        <div key={Date.now()} className="row">
          <div className="col-12">
        <div className="form-group">
          <br></br>
          <input type="text" name="objective" value={objective} onChange={(e) => setObjective(e.target.value)}/>
        </div>
      </div>
        </div>
      )
    };
    setElements1([...elements1 , newElement]) ;
    // To reset value 
    setObjective("");
  }
}

const handleDelete1 = (id , e)=>{
  e.preventDefault();
  const updatedElements = elements1.filter(elements1 => elements1.id !== id);
  setElements1(updatedElements);
}

// End of Handle Objective 

// Start of Handle Requirements 
const [requirements , setRequirements] = useState('');
const [elements2 , setElements2] = useState([]);
const refInput22 = useRef(null) ; 

const handleAdd2 = (e)=>{
  e.preventDefault();
  if(refInput22.current.value === ""){
    return false ; 
  }else{
    const newElement = {
      id: ++y,
      requirements : requirements , 
      jsx: (
        <div key={Date.now()} className="row">
          <div className="col-12">
        <div className="form-group">
          <br></br>
          <input type="text" name="requirements" value={requirements} onChange={(e) => setRequirements(e.target.value)}/>
        </div>
      </div>
        </div>
      )
    };
    setElements2([...elements2 , newElement]) ;
    // To reset value 
    setRequirements('');
  }
}

const handleDelete2 = (id , e)=>{
  e.preventDefault();
  const updatedElements = elements2.filter(elements2 => elements2.id !== id);
  setElements2(updatedElements);
}
// End of Handle Requirements

// Start of Handle Targets 
const [targets , setTargets] = useState('');
const [elements3 , setElements3] = useState([]);
const refInput33 = useRef(null) ;

const handleAdd3 = (e)=>{
  e.preventDefault();
  if(refInput33.current.value === ""){
    return false ; 
  }else{
    const newElement = {
      id: ++z,
      targets: targets,
      jsx: (
        <div key={Date.now()} className="row">
          <div className="col-12">
        <div className="form-group">
          <br></br>
          <input type="text" name="targets" value={targets} onChange={(e) => setTargets(e.target.value)}/>
        </div>
      </div>
        </div>
      )
    };
    setElements3([...elements3 , newElement]) ;
    // To reset value 
    setTargets("");
  }


}

const handleDelete3 = (id , e)=>{
  e.preventDefault();
  const updatedElements = elements3.filter(elements3 => elements3.id !== id);
  setElements3(updatedElements);
}
// End of Handle Targets

const handleDateChange = (selectedDate)=>{
  setStartTime(selectedDate);
}

const handleDateChange1 = (selectedDate) =>{
  setEndTime(selectedDate);
}

const handleChange3 = (e)=>{
  const {name , value} = e.target ; 
  setFormData3({
    [name] : value
  })
}

let title3 , icon3 = "";
const handleSubmit3 = (e)=>{
  e.preventDefault();

  const formData4={
    "courseId" : responseForm1 , 
    "sections" : [
      {
        "order" : 1 ,
        "sectionAr" : formData1.titleAr , 
        "content":[
          {
            "contentAr" : formData1.secondaryTitleAr ,
            "meetingLink" : formData3.meetingLink,
            "previewLink" :  formData3.meetingLink,
            "order" : 1 ,
            "descriptionAr" : formData1.descriptionAr , 
            "meetingStartTime" : startTime , 
            "meetingEndTime" : endTime
          }
        ]
      }
    ]
  }

  if(formData4.sections[0].content[0].meetingStartTime !== "" && formData4.sections[0].content[0].meetingEndTime !== ""
  && formData4.sections[0].content[0].meetingLink !== ""){
    elements1.push({"id":++j , "objective" : refInput11.current?.value});
    elements2.push({"id":++y , "requirements" : refInput22.current?.value});
    elements3.push({"id":++z , "targets" : refInput33.current?.value});
  }

  let element1Send = [];
  let element2Send = [];
  let element3Send = [];

  elements1.map(item => {
    element1Send.push({
      ...item , 
      "order" : item.id , 
      "objectiveAr" : item.objective
    })
  })

  elements2.map(item =>{
    element2Send.push({
      ...item , 
      "order" : item.id , 
      "requirementAr" :  item.requirements
    })
  })

  elements3.map(item =>{
    element3Send.push({
      ...item , 
      "order" : item.id ,
      "targetAr" : item.targets
    })
  })

  element1Send.map(item =>{
    delete item.objective ; 
    delete item.id ; 
    delete item?.jsx ; 
  })

  element2Send.map(item => {
    delete item.requirements ; 
    delete item.id ; 
    delete item?.jsx ; 
  })

  element3Send.map(item => {
    delete item.targets ; 
    delete item.id ; 
    delete item?.jsx ; 
  })

  const ObjectiveObj = {
    "courseId" : responseForm1 , 
    "objectives" : [
        ...element1Send
    ]
  }

  const RequirementsObj = {
    "courseId" : responseForm1 , 
    "requirements" : [
        ...element2Send
    ]
  }

  const TargetsObj = {
    "courseId" : responseForm1 ,
    "targets" : [
        ...element3Send
    ]
  }

  Swal.fire({
    title: `${t('Now Loading')}` ,  
    allowEscapeKey: false , 
    allowOutsideClick : false ,
    didOpen:()=>{
      Swal.showLoading();
      axios.post(`${baseURL}/Courses/CourseSection`, formData4,{
        headers:{
          Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
        }
      }).then((res)=> {
        if(res.data.message === "Saved Successfully"){
            axios.post(`${baseURL}/Courses/CourseObjective`,ObjectiveObj,{
              headers:{
                Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
              }
            }).then(res=>res).catch(err => console.log("Objective Error"));
    
            axios.post(`${baseURL}/Courses/CourseRequirement`,RequirementsObj,{
              headers:{
                Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
              }
            }).then(res=>res).catch(err => console.log("Requirement Error"));
    
            axios.post(`${baseURL}/Courses/CourseTarget`,TargetsObj,{
              headers:{
                Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
              }
            }).then(res=>res).catch(err => console.log("Target Error"));
            title3 = `${t('Course Created')}`;
            icon3 = "success" ;
            Swal.close();
            history.push("/Kader/course/courselist-courses");
        }
      }).catch(()=>{
        Swal.fire({
          title: `${t('Faild Process')}` ,
          text : `${t('Try Again')}` , 
          timer: 3000,
          showConfirmButton: false    
        })
      })
    },
    didClose:()=>{
      Swal.fire({
        title: title3 ,  
        icon: icon3 , 
        timer : "5000" , 
        showConfirmButton : false
      })
    }
  })
}

// ======================== End Of Form 3 ===================================

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper cardhead">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">{t('Add New Course')}</h3>
                <ul className="formwizard">
                  <li className="formwizard-item active">{t('Create new course')}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Lightbox */}
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">{t('Create Course Process')}</h4>
                </div>
                <div className="card-body">
                  <div id="basic-pills-wizard" className="twitter-bs-wizard">
                    <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                      <li className="nav-item">
                        <Link
                          to="#Course-details"
                          className={`nav-link ${str === 'Course-details' ? 'active' : ''}`}
                          // className="nav-link"
                          data-bs-toggle="tab"
                        >
                          <div
                            className="step-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Create Course Step"
                          >
                            <FeatherIcon icon="plus-square" />
                          </div>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="#Instructor-info"
                          className={`nav-link ${str === 'Instructor-info' ? 'active' : ''}`}
                          // className="nav-link"
                          data-bs-toggle="tab"
                        >
                          <div
                            className="step-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Instructor Info"
                          >
                            <FeatherIcon icon="users" />
                          </div>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="#additional-info"
                          className={`nav-link ${str === 'additional-info' ? 'active' : ''}`}
                          // className="nav-link"
                          data-bs-toggle="tab"
                        >
                          <div
                            className="step-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Final Step"
                          >
                            <FeatherIcon icon="edit" />
                          </div>
                        </Link>
                      </li>
                    </ul>
                    {/* wizard-nav */}
                    <div className="tab-content twitter-bs-wizard-tab-content">
                      <div style={{ display: str == 'Course-details' ? 'block' : 'none' }} className="tab-pane" id="Course-details">
                        <div className="mb-4">
                          <h5>{t('Enter Your Course Details')}</h5>
                        </div>
                    <form onSubmit={handleSubmit1}>
                        <div className="row">
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Title')}</label>
                    <input type="text" required name="titleAr" onChange={handleChange}/>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Sub title')}</label>
                    <input type="text" required name="secondaryTitleAr" onChange={handleChange}/>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Course Category')}</label>
                    <Select2
                      className="select"
                      data={options}
                      options={{
                        placeholder: `${t('Choose')}`,
                      }}
                      value={selectedOption1}
                      onChange={handleSelectChange1}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Institute Name')}</label>
                    <Select2
                      className="select"
                      data={options2}
                      options={{
                        placeholder: `${t('Choose')}`,
                      }}
                      value={selectedOption2}
                      onChange={handleSelectChange2}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Price')}</label>
                    <input type="text" required name="price" value={numericValue} onChange={handleChangeNumber} />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>{t('Course Description')}</label>
                    <textarea className="form-control" required name="descriptionAr" defaultValue={""} onChange={handleChange}/>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>{t('Course Image')}</label>
                    <div className="image-upload">
                      <input type="file" required onChange={handleFileChange}/>
                      <div className="image-uploads">
                        <img src={Upload} alt="img" />
                        <h4>{t('Drag and drop a file to upload')}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                        <ul className="pager wizard twitter-bs-wizard-pager-link">
                          <li className="next">
                          {/* onClick={() => setStr('Instructor-info')} */}
                            <button className="btn btn-primary">
                              {t('Next')} <i className="bx bx-chevron-right ms-1" />
                            </button>
                          </li>
                        </ul>
                    </form>
                      </div>
                      {/* tab pane */}
                      <div className="tab-pane" style={{ display: str == 'Instructor-info' ? 'block' : 'none' }} id="Instructor-info">
                        <div>
                          <div className="mb-4">
                            <h5>{t('Enter Your Info')}</h5>
                          </div>
                          <form onSubmit={handleSubmit2}>
                          <div className="row">
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Instructor Name')}</label>
                    <input type="text" required name="nameAr" onChange={handleChange2}/>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Instructor Title')}</label>
                    <input type="text" required name="jobTitleAr" onChange={handleChange2}/>
                  </div>
                </div>
                <h3>{t('Experience')}</h3>
      <div className="col-6">
        <div className="form-group">
          <label style={{ textAlign: "center" }}>{t('TitleInst')}</label>
          <input
            type="text"
            name="title"
            ref={refInput1}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
      </div>
      <div className="col-6">
        <div className="form-group">
          <label style={{ textAlign: "center" }}>{t('Details')}</label>
          <input
            type="text"
            name="details"
            ref={refInput2}
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          />
        </div>
        <div className="position-relative">
          <button className="adjust-icons" onClick={handleAdd}>
            <img src={PlusIcon} alt="img" className="me-1" />
          </button>
        </div>
      </div>
      <ul>
        {elements.map(({ jsx, id }) => (
          <li key={id} className="adjust">
            {jsx}
            <div className="position-relative">
              <button
                className="adjust-icons"
                onClick={(event) => handleDelete(event, id)}
              >
                <img src={DeleteIcon} alt="Delete Icon" />
              </button>
            </div>
          </li>
        ))}
      </ul>
               <div className="col-12">
                  <div className="form-group">
                    <label>{t('Instructor Image')}</label>
                    <div className="image-upload">
                      <input type="file" required onChange={handleFileChange2}/>
                      <div className="image-uploads">
                        <img src={Upload} alt="img" />
                        <h4>{t('Drag and drop a file to upload')}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                          <ul className="pager wizard twitter-bs-wizard-pager-link">
                            
                            <li className="next">
                              <button
                                // onClick={() => setStr('additional-info')}
                                className="btn btn-primary"
                              >
                                {t('Next')} <i className="bx bx-chevron-right ms-1" />
                              </button>
                            </li>
                          </ul>
                          </form>
                        </div>
                      </div>
                      {/* tab pane */}
                      <div className="tab-pane" id="additional-info" style={{ display: str == 'additional-info' ? 'block' : 'none' }}>
                        <div>
                          <div className="mb-4">
                            <h5>{t('Additional Details')}</h5>
                          </div>
                          <form onSubmit={handleSubmit3}>
                          <div className="row">
                          <DatePicker title={t('Meeting Start Time')} onDateChange={handleDateChange}/>
                  <DatePicker1 title={t('Meeting End Time')} onDateChange={handleDateChange1}/>
                          <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Course Link')}</label>
                    <input type="text" name="meetingLink" onChange={handleChange3} required />
                  </div>
                           </div>
                           <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>{t('SEO')}</label>
                                        <textarea className="form-control" name='seo' />
                                    </div>
                                </div>
               {/* Start of Objective */}
               <h4>{t('Objective')}</h4>
      <div className="col-12">
        <div className="form-group">
          <input type="text" name="objective" ref={refInput11} value={objective} onChange={(e) => setObjective(e.target.value)} />
        </div>
      </div>
      <div className="position-relative">
        <button className="adjust-icons" onClick={handleAdd1}>
          <img src={PlusIcon} alt="img" className="me-1" />
        </button>
      </div>

      <ul>
        {elements1.map(({ jsx, id }) => (
          <li key={id} className="adjust">
            {jsx}
            <div className="position-relative">
            <button className="adjust-icons" onClick={(e)=>handleDelete1(id,e)}>
              <img src={DeleteIcon} alt="Delete Icon" />
            </button>
            </div>
          </li>
        ))}
      </ul>
               {/* End of Objective */}
                
                {/* Start of Requirements */}
                <h4>{t('Requirements')}</h4>
      <div className="col-12">
        <div className="form-group">
          <input type="text" name="requirements" ref={refInput22} value={requirements} onChange={(e) => setRequirements(e.target.value)} />
        </div>
      </div>
      <div className="position-relative">
        <button className="adjust-icons" onClick={handleAdd2}>
          <img src={PlusIcon} alt="img" className="me-1" />
        </button>
      </div>

      <ul>
        {elements2.map(({ jsx, id }) => (
          <li key={id} className="adjust">
            {jsx}
            <div className="position-relative">
            <button className="adjust-icons" onClick={(e)=>handleDelete2(id,e)}>
              <img src={DeleteIcon} alt="Delete Icon" />
            </button>
            </div>
          </li>
        ))}
      </ul>
                {/* End of Requirements */}
               
                 {/* Start of Targets */}
                 <h4>{t('Targets')}</h4>
      <div className="col-12">
        <div className="form-group">
          <input type="text" name="targets" ref={refInput33} value={targets} onChange={(e) => setTargets(e.target.value)} />
        </div>
      </div>
      <div className="position-relative">
        <button className="adjust-icons" onClick={handleAdd3}>
          <img src={PlusIcon} alt="img" className="me-1" />
        </button>
      </div>

      <ul>
        {elements3.map(({ jsx, id }) => (
          <li key={id} className="adjust">
            {jsx}
            <div className="position-relative">
            <button className="adjust-icons" onClick={(e)=>handleDelete3(id,e)}>
              <img src={DeleteIcon} alt="Delete Icon" />
            </button>
            </div>
          </li>
        ))}
      </ul>
                 {/* End of Targets */}
               </div>
                          <ul className="pager wizard twitter-bs-wizard-pager-link">
                            {/* <li className="previous">
                              <button
                                onClick={() => setStr('Instructor-info')}
                                className="btn btn-primary"
                              >
                                <i className="bx bx-chevron-left me-1" /> Previous
                              </button>
                            </li> */}
                            <li className="float-end">
                              <button
                                className="btn btn-primary"
                                // data-bs-toggle="modal"
                                // data-bs-target=".confirmModal"
                              >
                                {t('Submit')}
                              </button>
                            </li>
                          </ul>
                          </form>
                        </div>
                      </div>
                      {/* tab pane */}
                    </div>
                    {/* end tab content */}
                  </div>
                </div>
                {/* end card body */}
              </div>
            </div>
            {/* /Wizard */}
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};
export default AddProduct;
