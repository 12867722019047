/* eslint-disable no-undef */

import React from "react";
import { createRoot } from "react-dom/client";
import Main from './EntryFile/Main';
import { createStore } from "redux";
import reducer from "./reducers/rootReducer";
import { Provider } from "react-redux";

const store = createStore(reducer);
createRoot(
  document.getElementById('app')).render(
  <Provider store={store}>
    <Main />
  </Provider>
  );

if (module.hot) {
  module.hot.accept();
}


