/* eslint-disable no-dupe-keys */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Table from "../../EntryFile/datatable"
import Tabletop from "../../EntryFile/tabletop"
import {
  PlusIcon,
  EditIcon,
  DeleteIcon,
  search_whites,
  Download,
  uploadOther,
} from "../../EntryFile/imagePath";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import axios from "axios";
import { baseURL } from "../../InitialPage/Authentecation";
import { useTranslation } from "react-i18next";

const ProductList = () => {
  const [inputfilter, setInputfilter] = useState(false);
  const {t} = useTranslation();
  //select2
  const options = [
    { id: 1, text: "Choose Course", text: "Choose Course" },
    // { id: 2, text: "Course 1", text: "Course 1" },
    // { id: 3, text: "Course 2", text: "Course 2" },
  ];
  const options2 = [
    { id: 1, text: "Choose Category", text: "Choose Category" },
    // { id: 2, text: "Category 1", text: "Category 1" },
    // { id: 3, text: "Category 2", text: "Category 2" },
  ];
  const options5 = [
    { id: 1, text: "Price", text: "Price" },
    // { id: 2, text: "150.00", text: "150.00" },
  ];


  const togglefilter = (value) => {
    setInputfilter(value);
  };

  
  const [countItems , setCountItems] = useState(0);
  const [pagination , setPagination] = useState({
    PageIndex : 1 , 
    PageSize : 10 ,
    WithImages: false,
    IncludeExperience : false
  });

  const [dataGetInstructor , setDataGetInstructor] = useState([]);
  let data = [];
  const newName = "id";
  dataGetInstructor?.map(item =>{
    data.push({
      ...item , 
      [newName] : item.CourseId ,
      "Price" : item.CourseDetails.Price,
      "CourseName" : item.CourseDetails.TitleAr.slice(0,16),
      "CourseCategoryName" : item.CourseDetails.CourseCategoryName ,
      "InstituteName" : item.CourseDetails.InstituteName,
      "status" : item.CourseDetails.Published , 
      "InstructorName" : item.NameAr.slice(0,14),
    })
  })

  data.map(item =>  delete item.CourseId);

  let title , icon = "";
  const confirmText = (id) => {
    Swal.fire({
      title: `${t('Are you sure?')}`,
      text: `${t("You won't be able to revert this!")}`,
      type: "warning",
      showCancelButton: !0,
      confirmButtonText: `${t('Yes, delete it!')}`,
      cancelButtonText : `${t('Cancel')}`,
      customClass:{
        confirmButton : "btn btn-primary",
        cancelButton : "btn btn-primary"
      },
      buttonsStyling: !1,
    })
    .then(function (n) {
      if(n.value){
       try{
        Swal.fire({
          title: `${t('Now Loading')}` , 
          allowEscapeKey: false , 
          allowOutsideClick : false ,
          didOpen:()=>{
            Swal.showLoading();
            axios.delete(`${baseURL}/Courses/Course/${id}`, {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem("result")}`,
              },
            }).then(res =>{
              if(res.data.valid){
                title = `${t('Finished!')}`;
                icon = "success";
                setDataGetInstructor(data.filter(item => item.id !== id));
                setCountItems(data.length - 1);
                Swal.close();
              }else{
                  title =  `${t('Course Not Deleted')}` ;
                  icon = "error" ;
                  Swal.close();
              }
            })
          },
          didClose:()=>{
            Swal.fire({
              title: title ,  
              icon: icon , 
              timer : "5000" , 
              showConfirmButton : false
            })
          }
        })
       }catch(err){
        console.error("Error handling" , err);
       }
      }
    });
  };

  const changePageSizeAndPageIndex = (index , size)=>{
    setPagination({WithImages:false , PageIndex: index , PageSize:size , IncludeExperience:false});
  }
  const fetchData = async()=>{
    try{
      const apiURL =`${baseURL}/Courses/CourseInstructor?IncludeExperience=false&WithImages=false&PageIndex=${pagination.PageIndex}&PageSize=${pagination.PageSize}`;
      const result = await axios.get(apiURL, {
        headers:{
          Authorization : `Bearer ${window.localStorage.getItem('result')}`
        }
      })
      const dataFromInstructorApi = result.data.result ;
      const test = await Promise.all(
        dataFromInstructorApi.map(async (item) => {
          return {
            "CourseId" : item.CourseId , 
            "CourseDetails" : await getCourseInfo(item.CourseId),
            "NameAr" : item.NameAr
          };
        })
      )
      setDataGetInstructor(test);
      setCountItems(result.data.totalItems);
    }catch(err){
      console.error("Error Handling Data" , err);
    }
  }; 
function getCourseInfo(courseId){
    try{
      const apiURL = `${baseURL}/Courses/Course/${courseId}?IncludeCourseDetails=false&WithImages=false`;
      const result = axios.get(apiURL ,{
        headers:{
          Authorization : `Bearer ${window.localStorage.getItem('result')}`
        }
      }).then( async res => {
        return{
          "CourseCategoryName" : await getCategoryName(res.data.result.CourseCategoryId) , 
          "InstituteName" : await getInstituteName(res.data.result.InstituteId),
          "Price" : res.data.result.Price ,
          "TitleAr" : res.data.result.TitleAr,
          "Published" : res.data.result.Published
        }
      });
      return result ;
    }catch(err){
      return err ; 
    }
  }

  function getCategoryName(CourseCategoryId){
    try{
      const apiURL = `${baseURL}/Courses/CourseCategory/${CourseCategoryId}?IncludeCourses=false&WithImage=false`;
      const result = axios.get(apiURL , {
        headers:{
          Authorization : `Bearer ${window.localStorage.getItem('result')}`
        }
      }).then(res => res.data.result.NameEn);
      return result ;
    }catch(err){
      return err ; 
    }
  }

function getInstituteName(CourseInstituteId){
  try{
    const apiURL = `${baseURL}/Courses/Institute/${CourseInstituteId}?IncludeCourses=false&WithImage=false`;
    const result = axios.get(apiURL , {
      headers:{
        Authorization : `Bearer ${window.localStorage.getItem('result')}`
      }
    }).then(res => res.data.result.NameEn);
    return result ; 
  }catch(err){
    return err ; 
  }
}

useEffect(()=>{
    fetchData();
  },[pagination])

  let title1 , icon1 = "";
  const handleCheckboxChange = (id) => {
    data.map(item => {
      if(item.id === id){
        if(item.status === true){
          Swal.fire({
            title: `${t('Now Loading')}` , 
            allowEscapeKey: false , 
            allowOutsideClick : false ,
            didOpen:()=>{
              Swal.showLoading();
              axios.post(`${baseURL}/Courses/Course/${id}/UnPublish`,null , {
                  headers:{
                    Authorization: `Bearer ${window.localStorage.getItem("result")}`,
                  }
                }).then((res)=> {
                if(res.data.valid){
                  title1 = `${t('Finished!')}`;
                  icon1 = "success";
                  Swal.close();
                }if(res.data.valid !== true){
                  title1 = `${t("Can't UnPublish Course After Users have Enrolled in it")}` ;
                  icon1 = 'error' ;
                  Swal.close();
                }
              })
            },
            didClose:()=>{
              Swal.fire({
                title: title1 ,  
                icon: icon1 , 
                timer : "5000" , 
                showConfirmButton : false
              })
            }
          })
        }else{
             axios.post(`${baseURL}/Courses/Course/${id}/Publish` , null , {
        headers:{
          Authorization: `Bearer ${window.localStorage.getItem("result")}`,
        }
      }).then(res => res)
        }
      }
    })
  };

  const columns = [
    {
      title: `${t('Course Name')}`,
      dataIndex: "CourseName",
      sorter: (a, b) => a.CourseName.length - b.CourseName.length,
    },
    {
      title: `${t('Course Category')}`,
      dataIndex: "CourseCategoryName",
      sorter: (a, b) => a.CourseCategoryName.length - b.CourseCategoryName.length,
    },
    {
      title: `${t('Institute')}`,
      dataIndex: "InstituteName",
      sorter: (a, b) => a.InstituteName.length - b.InstituteName.length,
    },
    {
      title: `${t('Price')}`,
      dataIndex: "Price",
      sorter: (a, b) => a.Price.length - b.Price.length,
    },
    {
      title: `${t('Created By')}`,
      dataIndex: "InstructorName",
      sorter: (a, b) => a.NameAr.length - b.NameAr.length,
    },
    {
      title: `${t('Publish')}`,
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <div className="status-toggle d-flex justify-content-between align-items-center">
          <input
            type="checkbox"
            id={record.id}
            className="check"
            defaultChecked={status}
            onChange={() => handleCheckboxChange(record.id)}
          />
          <label htmlFor={record.id} className="checktoggle" onClick={() => handleCheckboxChange(record.id)}>
            CheckBox
          </label>
        </div>
      ),
    },
    {
      title: `${t('Attachment')}`,
      dataIndex: "status" , 
      key : "status",
      render: (text , record) => (
        record.status ? 
        <>
          <>
            <Link className="me-3" to={`/Kader/course/uploadSnapShotAttendee/${record.id}`}>
              <img src={uploadOther} alt="img" />
            </Link> 
            <Link className="confirm-text" to={`/Kader/course/invoice/${record.id}`}>
              <img src={Download} alt="img" />
            </Link>
          </>
        </> : <>
          <>
            <Link className="confirm-text" to={`/Kader/course/invoice/${record.id}`}>
              <img src={Download} alt="img" />
            </Link>
          </>
        </>
      ),
    },
    {
      title: `${t('Action')}`,
      dataIndex: "status" , 
      key : "status",
      render: (text , record) => (
        record.status ? 
        "": <>
        <>
          <Link className="me-3" to={`/Kader/course/editproduct-product/${record.id}`}>
            <img src={EditIcon} alt="img" />
          </Link>
          <Link className="confirm-text" to="#" onClick={()=>confirmText(record.id)}>
            <img src={DeleteIcon} alt="img" />
          </Link>
        </>
      </> 
      ),
    },
  ];
  return (
    <>
      <div className="page-wrapper">
        <div className="content" id="container1">
          <div className="page-header">
            <div className="page-title">
              <h4>{t('Courses List')}</h4>
              <h6>{t('Manage your courses')}</h6>
            </div>
            <div className="page-btn">
              <Link
                to="/Kader/course/addcourse-courses"
                className="btn btn-added"
              >
                <img src={PlusIcon} alt="img" className="me-1" />
                {t('Add New Course')}
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <Tabletop
                inputfilter={inputfilter}
                togglefilter={togglefilter}
              />
              {/* /Filter */}
              <div
                className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                id="filter_inputs"
                style={{ display: inputfilter ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="row">
                        <div className="col-lg col-sm-6 col-12">
                          <div className="form-group">
                            <Select2
                              className="select"
                              data={options}
                              options={{
                                placeholder: "Choose Course",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg col-sm-6 col-12">
                          <div className="form-group">
                            <Select2
                              className="select"
                              data={options2}
                              options={{
                                placeholder: "Choose Category",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg col-sm-6 col-12 ">
                          <div className="form-group">
                            <Select2
                              className="select"
                              data={options5}
                              options={{
                                placeholder: "Price",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-1 col-sm-6 col-12">
                          <div className="form-group">
                            <a className="btn btn-filters ms-auto">
                              <img src={search_whites} alt="img" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={data}
                  handleChange={changePageSizeAndPageIndex} totalItems={countItems}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </>
  );
};

export default ProductList;


