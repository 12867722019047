/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Table from "../../EntryFile/datatable";
import Tabletop from "../../EntryFile/tabletop"
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import Swal from "sweetalert2";
import {
  PlusIcon,
  EditIcon,
  DeleteIcon,
  Thomas,
  Benjamin,
  James,
  Bruklin,
  Beverly,
  search_whites,
} from "../../EntryFile/imagePath";
import axios from "axios";
import { baseURL } from "../../InitialPage/Authentecation";
import { useTranslation } from "react-i18next";

const UserList = () => {
  const {t} = useTranslation();
  const ImageDecoder = (imgDecoder) => {
    // Decode base64 string to binary
    const binaryString = atob(imgDecoder);
  
    // Convert binary string to Uint8Array
    const uint8Array = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }
  
    // Create a Blob from Uint8Array
    const blob = new Blob([uint8Array], { type: "image/jpeg" });
  
    // Create a data URL from the Blob
    const imageUrl = URL.createObjectURL(blob);
  
    return imageUrl ; 
  };
  const [data , setData] = useState([]);
  const [inputfilter, setInputfilter] = useState(false);
  const [countItems , setCountItems] = useState(0);

  const togglefilter = (value) => {
    setInputfilter(value);
  };
  const options = [
    { id: 1, text: "Disable", text: "Disable" },
    { id: 2, text: "Enable", text: "Enable" },
  ];
  
  const [isNetwork , setIsNetwork] = useState();
  const [selectedId , setSelectedId] = useState();

  const chooseSelectUser = (id)=>{
    setSelectedId(id) ; 
  }

  const [pagination , setPagination] = useState({
    PageIndex : 1 , 
    PageSize : 10 ,
    WithImages: true,
    ShowDeletedUsers:false 
  });

  let data1 = [] ; 
  const newName = "id" ;
  
  data?.map(item=>{
    data1.push({
      ...item , 
      [newName]: item.Id , 
    })
  })
  
  data1.map(item => delete item.Id);
let title , icon = "" ;
  const confirmText = (id) => {
    Swal.fire({
      title: `${t('Are you sure?')}`,
      text: `${t("You won't be able to revert this!")}`,
      type: "warning",
      showCancelButton: !0,
      confirmButtonText: `${t('Yes, delete it!')}`,
      cancelButtonText : `${t('Cancel')}`,
      customClass:{
        confirmButton : "btn btn-primary",
        cancelButton : "btn btn-primary"
      },
      buttonsStyling: !1,
    })
    .then(function (n) {
      if(n.value){
       try{
        Swal.fire({
          title: `${t('Now Loading')}` , 
          allowEscapeKey: false , 
          allowOutsideClick : false ,
          didOpen:()=>{
            Swal.showLoading();
            axios.delete(`${baseURL}/Authentication/User/${id}`, {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem("result")}`,
              },
            }).then(res =>{
              if(res.data.valid){
                title = `${t('Finished!')}`;
                icon = "success";
                setData(data.filter(item => item.Id !== id));
                setCountItems(data.length - 1);
                Swal.close();
              }else{
                title = `${t('User Not Deleted')}` ; 
                icon = "error" ;
                Swal.close();
              }
            })
          },
          didClose:()=>{
            Swal.fire({
              title: title ,  
              icon: icon ,  
              timer : "5000" , 
              showConfirmButton : false
            })
          }
        })
       }catch(err){
        console.error("Error handling" , err);
       }
      }
    });
  };
 
  const changePageSizeAndPageIndex = (index , size)=>{
    setPagination({WithImages:true , PageIndex: index , PageSize:size , ShowDeletedUsers:false});
  }
  
 

  useEffect(()=>{
    const fetchData = async()=>{
      try{
        const apiURL =`${baseURL}/Authentication/User?WithImages=${pagination.WithImages}&PageIndex=${pagination.PageIndex}&PageSize=${pagination.PageSize}`;
        const result = await axios.get(apiURL, {
          headers:{
            Authorization : `Bearer ${window.localStorage.getItem('result')}`
          }
        })
        setData(result.data.result);
        setCountItems(result.data.totalItems);
        setIsNetwork(true);
      }catch(err){
        console.error("Error Handling Data" , err);
        setIsNetwork(false);
      }
    };
   fetchData();
  },[pagination]);

  const columns = [
    {
      title: `${t('Image')}`,
      dataIndex: "Image",
      render: (text, record) => (
        <div className="productimgname">
          <Link to="#" style={{ width: "70%" }} className="product-img">
            <img alt="" src={ImageDecoder(record.Image)} />
          </Link>
        </div>
      ),
      width: "80px",
    },
    {
      title: `${t('First Name')}`,
      dataIndex: "FirstNameEn",
      sorter: (a, b) => a.FirstNameEn.length - b.FirstNameEn.length,
    },
    {
      title: `${t('Last Name')}`,
      dataIndex: "LastNameEn",
      sorter: (a, b) => a.LastNameEn.length - b.LastNameEn.length,
    },
    {
      title: `${t('User Name')}`,
      dataIndex: "UserName",
      sorter: (a, b) => a.UserName.length - b.UserName.length,
    },
    {
      title: `${t('Phone')}`,
      dataIndex: "PhoneNumber",
    },
    {
      title: `${t('Email')}`,
      dataIndex: "Email",
      sorter: (a, b) => a.Email.length - b.Email.length,
    },
    {
      title: `${t('Action')}`,
      render: (text, record) => (
        <>
          <Link className="me-3" to={`/Kader/people/edituser-people/${record.id}?WithImages=true`}>
            <img src={EditIcon} alt="img" />
          </Link>
          <Link className="confirm-text" to="#" onClick={()=>confirmText(record.id)}>
            <img src={DeleteIcon} alt="img" />
          </Link>
        </>
      ),
    },
  ];
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>{t('Users List')}</h4>
              <h6>{t('Manage your User')}</h6>
            </div>
            <div className="page-btn">
              <Link to="/Kader/people/adduser-people" className="btn btn-added">
                <img src={PlusIcon} alt="img" className="me-1" />
                {t('Add User')}
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
             {isNetwork?(data.length?  <div className="table-responsive">
                <Table columns={columns} dataSource={data1} handleChange={changePageSizeAndPageIndex} handleSelect={chooseSelectUser} totalItems={countItems}/> 
              </div>:<div className="loading-container" style={{marginTop:"50px"}}>
                <div className="loading-spinner"></div>
              </div>): <div className="table-responsive">
                <Table columns={columns} dataSource={data1} handleChange={changePageSizeAndPageIndex} handleSelect={chooseSelectUser} totalItems={countItems}/> 
              </div>}
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </>
  );
};
export default UserList;
