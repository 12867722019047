import React,{ useState} from 'react';
import { LoginImage , Logo} from '../EntryFile/imagePath'
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup';
import { PropTypes } from 'prop-types' ; 
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import { baseURL } from './Authentecation';

const ChangePassword = (props) => {
    const [eye, setEye] = useState(true);
    const [eye2 , setEye2] = useState(true);
    const [eye3 , setEye3] = useState(true);
    const [payload , setPayload] = useState();

    const location = useLocation();
    const queryForm = new URLSearchParams(location.search);
    const dataParam = queryForm.get('email');
   
    const onEyeClick = () => {
        setEye(!eye);
    };
    const onEye2Click = () => {
        setEye2(!eye2);
    };
    const onEye3Click = () => {
        setEye3(!eye3);
    };

    const validationSchema = Yup.object().shape({
        new_Password: Yup.string().required('New Password is required').min(8, 'New Password must be at least 8 characters'),
        confirm_New_Password: Yup.string()
            .required('Confirm New Password is required')
            .oneOf([Yup.ref('new_Password'), null], 'Passwords must match'),
        old_Password: Yup.string().required('Old Password is required').min(8, 'Old Password must be at least 8 characters'),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = (data) => {
       axios.post(`${baseURL}/Authentication/Account/ChangePassword` , {
        ...data , 
            email : decodeURIComponent(dataParam)
       }).then((res)=> {
            if(res.data.valid){
                props.history.push("/signIn");
            }else{
                setPayload(()=> res);
            }
       })
        // You can add further logic, such as sending a request to update the password
    };

    return (
        <>
            <div className="main-wrapper">
                <Helmet>
                    <title>SignIn - Dream POS</title>
                    <meta name="description" content="SignIn page" />
                </Helmet>
                <div className="account-content">
                    <div className="login-wrapper">
                        <div className="login-content">
                            <div className="login-userset">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="login-logo">
                                        <img src={Logo} alt="img" />
                                    </div>
                                    <div className="login-userheading">
                                        <h3>Change Your Password</h3>
                                    </div>
                                    <div className="form-login">
                                        <label>Old Password</label>
                                        <div className="pass-group">
                                            <input
                                                type={eye ? 'password' : 'text'}
                                                className={`${errors.old_Password ? 'is-invalid' : ''}`}
                                                placeholder="Enter your old password"
                                                {...register('old_Password')}
                                            />
                                            <span
                                                onClick={onEyeClick}
                                                className={`fas toggle-password ${eye ? 'fa-eye-slash' : 'fa-eye'}`}
                                            />
                                            <div className="invalid-feedback">{errors.old_Password?.message}</div>
                                        </div>
                                    </div>
                                    <div className="form-login">
                                        <label>New Password</label>
                                        <div className="pass-group">
                                            <input
                                                type={eye2 ? 'password' : 'text'}
                                                className={`${errors.new_Password ? 'is-invalid' : ''}`}
                                                placeholder="Enter your new password"
                                                {...register('new_Password')}
                                            />
                                            <span
                                                onClick={onEye2Click}
                                                className={`fas toggle-password ${eye2 ? 'fa-eye-slash' : 'fa-eye'}`}
                                            />
                                            <div className="invalid-feedback">{errors.new_Password?.message}</div>
                                        </div>
                                    </div>
                                    <div className="form-login">
                                        <label>Confirm New Password</label>
                                        <div className="pass-group">
                                            <input
                                                type={eye3 ? 'password' : 'text'}
                                                className={`${errors.confirm_New_Password ? 'is-invalid' : ''}`}
                                                placeholder="Confirm your new password"
                                                {...register('confirm_New_Password')}
                                            />
                                            <span
                                                onClick={onEye3Click}
                                                className={`fas toggle-password ${eye3 ? 'fa-eye-slash' : 'fa-eye'}`}
                                            />
                                            <div className="invalid-feedback">{errors.confirm_New_Password?.message}</div>
                                        </div>
                                    </div>
                                    <div className="form-login">
                                        <button type="submit" className="btn btn-login">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                            {(payload)? (<div className='alreadyuser text-center'><h6>{payload.data.message}</h6></div>): ""}
                        </div>
                        <div className="login-img">
                            <img src={LoginImage} alt="img" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


ChangePassword.propTypes ={
    history: PropTypes.any
}
export default ChangePassword;
