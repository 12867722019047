/* eslint-disable react/prop-types */
import React from 'react'
import { Redirect, Route,Switch } from 'react-router-dom';
import CustomerList from './CustomerList'
import AddCustomer from './AddCustomer';
import EditCustomer from './EditCustomer';
import InstituteList from './InstituteList';
import AddInstitute from './AddInstitute';
import EditInstitute from './EditInstitute';
import UserList from './UserList';
import AddUser from './AddUser';
import EditUser from './EditUser';
import StoreList from './StoreList';
import AddStore from './AddStore';
import EditStore from './EditStore';

const PeopleIndex = ({ match}) =>(
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/customerlist-people`} />
        <Route path={`${match.url}/customerlist-people`} component={CustomerList} />
        <Route path={`${match.url}/addcustomer-people`} component={AddCustomer} />
        <Route path={`${match.url}/studentsDetails`} component={EditCustomer} />
        <Route path={`${match.url}/Institutelist-people`} component={InstituteList} />
        <Route path={`${match.url}/addInstitute-people`} component={AddInstitute} />
        <Route path={`${match.url}/editInstitute-people/:id`} component={EditInstitute} />
        <Route path={`${match.url}/userlist-people`} component={UserList} />
        <Route path={`${match.url}/adduser-people`} component={AddUser} />
        <Route path={`${match.url}/edituser-people/:id`} component={EditUser} />
        <Route path={`${match.url}/storelist-people`} component={StoreList} />
        <Route path={`${match.url}/addstore-people`} component={AddStore} />
        <Route path={`${match.url}/editstore-people`} component={EditStore} />




    </Switch>
)

export default PeopleIndex