/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState , useEffect } from "react";
import Table from "../../EntryFile/datatable";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../InitialPage/Authentecation";
import Tabletop from "../../EntryFile/tabletop";
import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";
import {
  Download,
  Product7,
  Product8,
  Product1,
} from "../../EntryFile/imagePath";

import {
  ClosesIcon,
  RecievedJob,
  Unpublish , 
  Excel,
  Filter,
  Pdf,
  Eye1,
  Calendar,
  Printer,
  search_whites,
  Search,
  PlusIcon,
  EditIcon,
  EyeIcon , 
  Dollar1,
  plusCircle,
  delete1,
  DeleteIcon,
  datepicker,
} from "../../EntryFile/imagePath";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import { useTranslation } from "react-i18next";


const Jobslist = (props) => {
  const {t} = useTranslation() ; 
  const [inputfilter, setInputfilter] = useState(false);

  const togglefilter = (value) => {
    setInputfilter(value);
  };

  const options = [
    { id: 1, text: "Completed", text: "Completed" },
    { id: 2, text: "Paid", text: "Paid" },
  ];
  
  const [countItems , setCountItems] = useState(0);
  const [pagination , setPagination] = useState({
    PageIndex : 1 , 
    PageSize : 10 ,
  });

  const [data , setData] = useState([]);
  let data1 = [] ; 

  data?.map(item => {
    data1.push({
      id : item.JobId , 
      titleEn : item.TitleEn , 
      JobCategory : item.CategoryName , 
      CompanyNameData : item.CompanyName,
      workPlace : item.WorkPlaceModel ,
      workSchedule : item.workScheduleId
    })
  })

  let title , icon = "" ; 
  const confirmText = (id) => {
    Swal.fire({
      title: `${t('Are you sure?')}`,
      text: `${t("You won't be able to revert this!")}`,
      type: "warning",
      showCancelButton: !0,
      confirmButtonText: `${t('Yes, delete it!')}`,
      cancelButtonText : `${t('Cancel')}`,
      customClass:{
        confirmButton : "btn btn-primary",
        cancelButton : "btn btn-primary"
      },
      buttonsStyling: !1,
    }).then(function (n) {
      if (n.value){
        try{
          Swal.fire({
            title: `${t('Now Loading')}` , 
            allowEscapeKey: false , 
            allowOutsideClick : false ,
            didOpen:()=>{
              Swal.showLoading();
              axios.delete(`${baseURL}/Careers/Job/${id}`, {
                headers: {
                  Authorization: `Bearer ${window.localStorage.getItem("result")}`,
                },
              }).then(res =>{
                if(res.data.valid){
                  title = `${t('Finished!')}`;
                  icon = "success";
                  setData(data.filter(item => item.JobId !== id));
                  setCountItems(data.length - 1);
                  Swal.close();
                }else{
                    title = "Job Not Deleted" ;
                    icon = "error" ;
                    Swal.close();
                }
              })
            },
            didClose:()=>{
              Swal.fire({
                title: title ,  
                icon: icon , 
                timer : "5000" , 
                showConfirmButton : false
              })
            }
          })
         }catch(err){
          console.error("Error handling" , err);
         }
      }
    });
  };
  const changePageSizeAndPageIndex = (index , size)=>{
    setPagination({PageIndex: index , PageSize:size});
  }

  const fetchData = async()=>{
    try{
      const apiURL =`${baseURL}/Careers/Job?PageIndex=${pagination.PageIndex}&PageSize=${pagination.PageSize}`;
      const result = await axios.get(apiURL, {
        headers:{
          Authorization : `Bearer ${window.localStorage.getItem('result')}`
        }
      })
      const dataFromApi = result.data.result ;
      const test = await Promise.all(
        dataFromApi.map(async (item) => {
          return {
            "JobId" : item.id , 
            "CategoryName" : await getCategoryName(item.jobCategoryId) ,
            "CompanyName" : await getCompanyName(item.companyId) ,
            "WorkPlaceModel" : await getWorkPlaceModel(item.workPlaceModelId),
            "workScheduleId" : await getWorkSchedule(item.workScheduleId),
            "TitleEn" : item.titleEn
          };
        })
      )
      setData(test);
      setCountItems(result.data.totalItems);
    }catch(err){
      console.error("Error Handling Data" , err);
    }
  }; 

  function getCategoryName(CategoryId){
    try{
      const apiURL = `${baseURL}/Careers/JobCategory/${CategoryId}?withImages=false`;
      const result = axios.get(apiURL , {
        headers:{
          Authorization : `Bearer ${window.localStorage.getItem('result')}`
        }
      }).then(res => res.data.result.NameEn);
      return result ;
    }catch(err){
      return err ; 
    }
  }

  function getCompanyName(CompanyId){
  try{
     const apiURL = `${baseURL}/Careers/Company/${CompanyId}?withImages=false`;
      const result = axios.get(apiURL , {
        headers:{
          Authorization : `Bearer ${window.localStorage.getItem('result')}`
        }
      }).then(res => res.data.result.NameAr);
      return result ;
  }catch(err){
    return err ;
  }
  }

  function getWorkPlaceModel(workPlaceModelId){
    try{
      const apiURL = `${baseURL}/Careers/WorkPlaceModel`;
      const result = axios.get(apiURL , {
        headers :{
          Authorization : `Bearer ${window.localStorage.getItem('result')}`
        }
      }).then(res => res.data.result.map(item => {
        if(item.id === workPlaceModelId){
          return item.modelEn
        }
      }))
      return result ;
    }catch(err){
      return err ;
    }
  }

  function getWorkSchedule(workScheduleId){
    try{
      const apiURL = `${baseURL}/Careers/WorkSchedule`;
      const result = axios.get(apiURL , {
        headers :{
          Authorization : `Bearer ${window.localStorage.getItem('result')}`
        }
      }).then(res => res.data.result.map(item => {
        if(item.id === workScheduleId){
          return item.scheduleEn
        }
      }))
      return result ;
    }catch(err){
      return err ;
    }
  }
  useEffect(()=>{
    fetchData();
  },[pagination])

  let title1 , icon1 = "" ;
  const publishJob = (id)=>{
    Swal.fire({
      title: "Now Loading" , 
      allowEscapeKey: false , 
      allowOutsideClick : false ,
      didOpen:()=>{
        Swal.showLoading();
        axios.put(`${baseURL}/Careers/Job/${id}/Publish?Publish=true`, null,{
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("result")}`,
          },
        }).then(res =>{
          if(res.data.valid){
            title1 = `${t('Job Published')}`;
            icon1 = "success";
            Swal.close();
          }else{
              title1 = `${t('Job Not Published')}` ;
              icon1 = "error" ;
              Swal.close();
          }
        }).catch(err => {
          err
          Swal.close();
        })
      },
      didClose:()=>{
        Swal.fire({
          title: title1 ,  
          icon: icon1 , 
          timer : "5000" , 
          showConfirmButton : false
        })
      }
    })
  }
  const unpublishJob = (id)=>{
    Swal.fire({
      title: `${t('Now Loading')}` , 
      allowEscapeKey: false , 
      allowOutsideClick : false ,
      didOpen:()=>{
        Swal.showLoading();
        axios.put(`${baseURL}/Careers/Job/${id}/Publish?Publish=false`, null,{
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("result")}`,
          },
        }).then(res =>{
          if(res.data.valid){
            title1 = `${t('Job Unpublished')}`;
            icon1 = "success";
            Swal.close();
          }else{
              title1 = `${t('Job Not Unpublished')}` ;
              icon1 = "error" ;
              Swal.close();
          }
        }).catch(err => {
          err
          Swal.close();
        })
      },
      didClose:()=>{
        Swal.fire({
          title: title1 ,  
          icon: icon1 , 
          timer : "5000" , 
          showConfirmButton : false
        })
      }
    })
  }
  const columns = [
    {
      title: `${t('Job Title')}`,
      dataIndex: "titleEn",
      sorter: (a, b) => a.Date.length - b.Date.length,
    },
    {
      title: `${t('Job Category')}`,
      dataIndex: "JobCategory",
      sorter: (a, b) => a.Reference.length - b.Reference.length,
    },
    // {
    //   title: "Company",
    //   dataIndex: "Status",
    //   render: (text, record) => (
    //     <>
    //       {text === "Pending" && (
    //         <span className="badges bg-lightred">{text}</span>
    //       )}
    //       {text === "Completed" && (
    //         <span className="badges bg-lightgreen">{text}</span>
    //       )}
    //     </>
    //   ),
    //   sorter: (a, b) => a.Status.length - b.Status.length,
    // },
    // {
    //   title: "Work place Model",
    //   dataIndex: "Payment",
    //   render: (text, record) => (
    //     <>
    //       {text === "Paid" && (
    //         <span className="badges bg-lightgreen">{text}</span>
    //       )}
    //       {text === "Due" && <span className="badges bg-lightred">{text}</span>}
    //     </>
    //   ),
    //   sorter: (a, b) => a.Payment.length - b.Payment.length,
    // },
    {
      title: `${t('Company')}`,
      dataIndex: "CompanyNameData",
      sorter: (a, b) => a.Total.length - b.Total.length,
    },
    {
      title: `${t('Workplace Model')}`,
      dataIndex: "workPlace",
      sorter: (a, b) => a.Total.length - b.Total.length,
    },
    {
      title: `${t('Work schedule')}`,
      dataIndex: "workSchedule",
      sorter: (a, b) => a.Total.length - b.Total.length,
    },
    //   {
    //   title: "Date",
    //   dataIndex: "Name",
    //   sorter: (a, b) => a.Name.length - b.Name.length,
    // },
    // {
    //   title: "Paid",
    //   dataIndex: "Paid",
    //   render: (text, record) => (
    //     <>
    //       {text === 100 && <div className="text-green">{text}</div>}
    //       {text === 0 && <div>{text}</div>}
    //     </>
    //   ),
    //   sorter: (a, b) => a.Paid.length - b.Paid.length,
    // },
    // {
    //   title: "Due",
    //   dataIndex: "Due",
    //   render: (text, record) => (
    //     <>
    //       {text === 100 && <div className="text-red">{text}</div>}
    //       {text === 0 && <div>{text}</div>}
    //     </>
    //   ),
    //   sorter: (a, b) => a.Due.length - b.Due.length,
    // },
    // {
    //   title: "Biller",
    //   dataIndex: "Biller",
    //   sorter: (a, b) => a.Biller.length - b.Biller.length,
    // },
    {
      title: `${t('Action')}  `,
      render: (text, record) => (
        <>
          <div className="text-center">
            <Link
              className="action-set"
              to="#"
              data-bs-toggle="dropdown"
              aria-expanded="true"
            >
              <i className="fa fa-ellipsis-v" aria-hidden="true" />
            </Link>
            <ul className="dropdown-menu">
              {/* <li>
                <Link
                  to="/Kader/Jobs/Post-Job"
                  className="dropdown-item"
                >
                  <img src={Eye1} className="me-2" alt="img" />
                  Job Detail
                </Link>
              </li> */}
              <li>
                <Link
                  to={`/Kader/Jobs/edit-Job/${record.id}`}
                  className="dropdown-item"
                >
                  <img src={EditIcon} className="me-2" alt="img" />
                  {t('Edit Job')}
                </Link>
              </li>
              {/* <li>
                <Link
                  to="#"
                  className="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#createpayment"
                >
                  <img src={plusCircle} className="me-2" alt="img" />
                  Create Payment
                </Link>
              </li> */}
              <li>
                <Link to="#" className="dropdown-item" onClick={()=> publishJob(record.id)}>
                  <img src={EyeIcon} className="me-2" alt="img" />
                  {t('Publish')}
                </Link>
              </li>
              <li>
                <Link to="#" className="dropdown-item" onClick={()=> unpublishJob(record.id)}>
                  <img src={Unpublish} className="me-2" alt="img" />
                  {t('Unpublish')}
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="dropdown-item confirm-text"
                  onClick={()=> confirmText(record.id)}
                >
                  <img src={delete1} className="me-2" alt="img" />
                  {t('Delete Job')}
                </Link>
              </li>
            </ul>
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>{t('Jobs Application')}</h4>
              <h6>{t('Manage your Jobs')}</h6>
            </div>
            <div className="page-adjust-layout-btn">
            <div className="page-btn">
              <Link to="/Kader/Jobs/Post-Job" className="btn btn-added">
                <img src={PlusIcon} alt="img" className="me-1" />
                {t('Post Job')}
              </Link>
            </div>
            <div className="page-btn">
              <Link to="/Kader/Jobs/RecievedJobsApp" className="btn btn-added">
                <img src={RecievedJob} alt="img" className="me-1" style={{filter:"none"}} />
                {t('Recieved Job Application')}
              </Link>
            </div>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <Tabletop inputfilter={inputfilter} togglefilter={togglefilter} />
              {/* /Filter */}
              <div
                className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                id="filter_inputs"
                style={{ display: inputfilter ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <input type="text" placeholder={t('Enter Name')} />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <input type="text" placeholder={t('Enter Category Job')} />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <Select2
                          className="select"
                          data={options}
                          options={{
                            placeholder: t('Choose Workplace mode'),
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <Link to="#" className="btn btn-filters ms-auto">
                          <img src={search_whites} alt="img" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
              <Table columns={columns} dataSource={data1} handleChange={changePageSizeAndPageIndex} totalItems={countItems}/> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Jobslist;

