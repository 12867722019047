import React, { useState } from 'react'
import { Upload } from '../../EntryFile/imagePath';
import Swal from "sweetalert2";
import axios from "axios";
import { baseURL } from "../../InitialPage/Authentecation";
import {useHistory} from "react-router-dom";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';


const UploadSnapShot = () => {
    const history = useHistory();
    const [formData , setFormData] = useState({});
    const {id} = useParams();

    // To handle Image  
  const handleFileChange = (e)=>{
    const file = e.target.files[0] ;
      setFormData({
        ...formData ,
        courseId : id ,
        images : file
      })
}

const handleSubmit = (e)=>{
    e.preventDefault();
        console.log(formData);
    axios.post(`${baseURL}/Courses/CourseAttendeeSnapshot`, formData,{
        headers:{
          Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
          "Content-Type" :"multipart/form-data"
        }
      }).then((res)=> {
        if(res.data.valid){
          Swal.fire({
            title: "Now Loading" , 
            timer : "10000" , 
            allowEscapeKey: false , 
            allowOutsideClick : false ,
            didOpen:()=>{
              Swal.showLoading();
            },
            didClose:()=>{
              Swal.fire({
                title: "Finished!" ,  
                icon:"success" , 
                timer : "3000" , 
                showConfirmButton : false
              })
            }
          })
          setTimeout(()=>{
            history.push("/Kader/course/courselist-courses")
          }, 10000);
        }else{
          Swal.fire({
            title: "Now Loading" , 
            timer : "5000" , 
            allowEscapeKey: false , 
            allowOutsideClick : false ,
            didOpen:()=>{
              Swal.showLoading();
            },
            didClose :()=>{
              Swal.fire({
                title: "Course Not Published" ,
                icon:'warning' , 
                timer: 3000,
                showConfirmButton: false 
              }) 
            }
        })
        }
      }).catch(()=>{
        Swal.fire({
          title: "Faild Process" ,
          text : "Try Again" , 
          timer: 3000,
          showConfirmButton: false 
        })
      })
}
return (
        <>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="page-title">
                            <h4>Upload SnapShotAttendee</h4>
                        </div>
                    </div>
                    {/* /add */}
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label> SnapShot Attendee Image</label>
                                        <div className="image-upload">
                                            <input type="file" required onChange={handleFileChange}/>
                                            <div className="image-uploads">
                                                <img src={Upload} alt="img" />
                                                <h4>Drag and drop a file to upload</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <button className="btn btn-submit me-2" type="submit">
                                        Submit
                                    </button>
                                    <button  className="btn btn-cancel" onClick={()=> history.push("/Kader/course/courselist-courses")}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                    {/* /add */}
                </div>
            </div>

        </>
    )
}

export default UploadSnapShot;