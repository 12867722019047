/* eslint-disable no-dupe-keys */
import React, { useState , useEffect } from "react";
import { Upload } from "../../EntryFile/imagePath";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import Swal from "sweetalert2";
import axios from "axios";
import { baseURL } from "../../InitialPage/Authentecation";
import {useHistory} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

const AddUser = () => {
  const {t} = useTranslation();
  // To handle Image  

  const handleFileChange = (e)=>{
      const file = e.target.files[0] ; 
      if(!file){
        setError2(t('Please update your profile picture'))
      }else{
        setError2('');
        setFormData({
          ...formData , 
          Image : file
        })
      }
  }
  const [formData , setFormData] = useState({
    userRole : "" , 
    gender : "" ,
    firstNameEn : "" , 
    firstNameAr : "" , 
    lastNameEn : "" ,
    lastNameAr : "" , 
    userName : "" , 
    email: "" , 
    phoneNumber : "",
    address : "" ,
    birthdate :""
  })

  const handleChange = (e)=>{
    const {name , value} = e.target ; 
    setFormData({
      ...formData , 
      [name] : value
    })
  }

  // To control on select2
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOption1, setSelectedOption1] = useState('');
  const [error, setError] = useState('');
  const [error1, setError1] = useState('');
  const [error2, setError2] = useState('');
  
  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
    setError('');
    setFormData({
      ...formData , 
      userRole : e.target.value 
    })
  };
  
  const handleSelectChange1 = (e) => {
    setSelectedOption1(e.target.value);
    setError1('');
    setFormData({
      ...formData , 
      gender : e.target.value
    })
  };


let title = "" ; 
let icon = "" ; 
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedOption) {
      e.preventDefault();
      setError(t('Please choose an option'));
    }

    if (!selectedOption1) {
      e.preventDefault();
      setError1(t('Please choose an option'));
    }
    Swal.fire({
      title: `${t('Now Loading')}` , 
      allowEscapeKey: false , 
      allowOutsideClick : false ,
      didOpen:()=>{
        Swal.showLoading();
        axios.post(`${baseURL}/Authentication/User`, formData,{
          headers:{
            Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
            "Content-Type" :"multipart/form-data"
          }
        }).then((res)=> {
          if(res.data.message === "Saved Successfully"){
            title = `${t('Finished!')}`;
            icon = "success";
            Swal.close();
              history.push("/Kader/people/userlist-people")
          }
          
          if(res.data.message === "Already Existing"){
            title = `${t('User is Already Exist')}` ; 
            icon = 'warning';
            Swal.close();
          }
        }).catch(()=>{
          Swal.fire({
            title: `${t('Faild Process')}` ,
            text : `${t('Try Again')}` , 
            timer : "3000" ,
            showConfirmButton: false 
          })
        })
      },
      didClose:()=>{
        Swal.fire({
          title: title ,  
          icon:icon , 
          timer : "5000" , 
          showConfirmButton : false
        })
      }
    })

      
  };

// To control select 2 Gender view
const options2 = [
  {id: 0 , text : "Male"} , 
  {id: 1 , text : "Female"} , 
  {id: 2 , text : "Not Selected"} , 
  {id: 3 , text : "Ohters"}
]

// To Control Image

  const [options1 , setOptions1] = useState([]);
  const history  = useHistory() ; 
  // Adjust name of options object 
  let options = [] ; 
  const newName = "text" ;

  options1.map((item)=>{
    options.push({
      ...item , 
      [newName] : item.role 
    });
  })

  options.map(item => delete item.role);
  useEffect(()=>{
    axios.get(`${baseURL}/Authentication/UserRoles`,{
      headers:{
        Authorization : `Bearer ${window.localStorage.getItem('result')}`
      }
    }).then((res)=>{
      setOptions1(()=>{
       return res.data.result;
      })
    }).catch(err => err)
  } , [])

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>{t('User management')}</h4>
              <h6>{t('Add User')}</h6>
            </div>
          </div>
          {/* /add */}
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                        <label>{t('First Name(EN)')}</label>
                    <input type="text" required name="firstNameEn" onChange={handleChange}/>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('First Name(AR)')}</label>
                    <input type="text" required name="firstNameAr" onChange={handleChange}/>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Last Name(EN)')}</label>
                    <input type="text" required name="lastNameEn" onChange={handleChange}/>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Last Name(Ar)')}</label>
                    <input type="text" required name="lastNameAr" onChange={handleChange}/>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('BirthDate')}</label>
                   <input type="date" name="birthdate" onChange={handleChange} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('User Name')}</label>
                    <input type="text" required name="userName" onChange={handleChange}/>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Phone')}</label>
                    <input type="number" name="phoneNumber" required onChange={handleChange}/>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Email')}</label>
                    <input type="text" required name="email" onChange={handleChange}/>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Address')}</label>
                    <input type="text" name="address" onChange={handleChange}/>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Role')}</label>
                    <Select2
                      className="select"
                      data={options}
                      options={{
                        placeholder: t('Choose'),
                      }}
                      value={selectedOption}
                      onChange={handleSelectChange}
                    />
                    {error && <div className="error-message">{error}</div>}
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Gender')}</label>
                    <Select2
                      className="select"
                      data={options2}
                      options={{
                        placeholder: t('Choose'),
                      }}
                      value={selectedOption1}
                      onChange={handleSelectChange1}
                    />
                    {error1 && <div className="error-message">{error1}</div>}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>{t('User Image')}</label>
                    <div className="image-upload">
                      <input type="file" onChange={handleFileChange}/>
                      <div className="image-uploads">
                        <img src={Upload} alt="img" />
                        <h4>{t('Drag and drop a file to upload')}</h4>
                      </div>
                    </div>
                    {error2 && <div className="error-message">{error2}</div>}
                  </div>
                </div>
                <div className="col-lg-12">
                  <button className="btn btn-submit me-2" type="submit">{t('Submit')}</button>
                  <button className="btn btn-cancel" onClick={()=> history.push("/Kader/people/userlist-people")}>{t('Cancel')}</button>
                </div>
              </div>
              </form>
            </div>
          </div>
          {/* /add */}
        </div>
      </div>
    </>
  );
};

export default AddUser;
