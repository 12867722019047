/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import React, { useState } from "react";
import { Upload } from "../../EntryFile/imagePath";
import "react-select2-wrapper/css/select2.css";
import { Country, State }  from 'country-state-city';
import Select from "react-dropdown-select";
import {useHistory} from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { baseURL } from "../../InitialPage/Authentecation";
import { useTranslation } from "react-i18next";

const AddInstitute = () => {
  const {t} = useTranslation();
const history = useHistory();

const options = [];
const options1 = [];

const loadData = ()=>{
Country.getAllCountries().map((item)=>{
  return(
    options.push({label: item.name , value:item.isoCode})
  )
});
}
loadData();
const [value , setValue] = useState([]);
const [value1 , setValue1] = useState([]);
const [formData , setFormData] = useState({});

const handleChangeCountry = (value) =>{
  setValue1(value) ; 
  setFormData({
      ...formData , 
      country : value[0]?.label
  })
}

const handleChangeCity = (value) => {
  setValue(value);
  setFormData({
    ...formData , 
    city : value[0]?.label
  })
}

const handleFileChange = (e)=>{
  const file = e.target.files[0] ; 
  setFormData({
    ...formData , 
    Image : file
    })
}

value1.map((item)=>{
  options1.length = 0 ;
  return(
    State.getStatesOfCountry(item?.value).map((item )=>{
        options1.push({label:item.name , value: Math.random()})
    })
  )
})

  const handleChange = (e)=>{
    const {name , value} = e.target ; 
    setFormData({
      ...formData , 
      [name] : value
    })
  }
let title , icon = "" ;
  const handleSubmit=(e)=>{
    e.preventDefault();

    Swal.fire({
      title: `${t('Now Loading')}` , 
      allowEscapeKey: false , 
      allowOutsideClick : false ,
      didOpen:()=>{
        Swal.showLoading();
        axios.post(`${baseURL}/Courses/Institute`, formData,{
          headers:{
            Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
            "Content-Type" :"multipart/form-data"
          }
        }).then((res)=> {
          if(res.data.message === "Saved Successfully"){
              title = `${t('Finished!')}`;
              icon = "success";
              Swal.close();
              history.push("/Kader/people/Institutelist-people")
            }if(res.data.message === "Already Exist"){
                  title = `${t('Institute is Already Exist')}` ;
                  icon = 'warning' ;
                  Swal.close();
            
        }}
        ).catch(()=>{
          Swal.fire({
            title: `${t('There is Failure in Network')}` ,
            icon:'warning' , 
            timer: 3000,
            showConfirmButton: false 
          }) 
        })
      },
      didClose:()=>{
        Swal.fire({
          title: title ,  
          icon: icon , 
          timer : "5000" , 
          showConfirmButton : false
        })
      }
    })
  }
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>{t('Institute Management')}</h4>
              <h6>{t('Add Institute')}</h6>
            </div>
          </div>
          {/* /add */}
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Institute Name(En)')}</label>
                    <input type="text" name="nameEn" required onChange={handleChange}/>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Institute Name(Ar)')}</label>
                    <input type="text" name="nameAr" required  onChange={handleChange}/>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Email')}</label>
                    <input type="email" name="email" required onChange={handleChange}/>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Phone')}</label>
                    <input type="number"  name="phone" required onChange={handleChange}/>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Choose Country')}</label>
                    <Select
                    name="Select"
                    style={{borderColor:"rgba(145,158,171,0.32",padding:"9px" , borderRadius:"5px"}}
                    options={options}
                    onChange={handleChangeCountry}
                    required
                    placeholder={t('Search')}
                    >
                    </Select>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('City')}</label>
                    <Select
                     name="Select"
                     style={{borderColor:"rgba(145,158,171,0.32",padding:"9px" , borderRadius:"5px"}}
                     options={options1}
                     onChange={handleChangeCity}
                     placeholder={t('Search')}
                    >
                    </Select>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Course Description(En)')}</label>
                    <textarea className="form-control" name="aboutUsEn" onChange={handleChange} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Course Description(Ar)')}</label>
                    <textarea className="form-control" name="aboutUsAr" onChange={handleChange}/>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>{t('Institute logo')}</label>
                    <div className="image-upload">
                      <input type="file" onChange={handleFileChange}/>
                      <div className="image-uploads">
                        <img src={Upload} alt="img" />
                        <h4>{t('Drag and drop a file to upload')}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <button className="btn btn-submit me-2">{t('Submit')}</button>
                  <button className="btn btn-cancel" onClick={()=> history.push("/Kader/people/Institutelist-people")}>{t('Cancel')}</button>
                </div>
              </div>
              </form>
            </div>
          </div>
          {/* /add */}
        </div>
      </div>
    </>
  );
};

export default AddInstitute;
