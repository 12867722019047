/* eslint-disable no-unused-vars */
import React, { useState , useEffect  , useRef} from "react";
import Table from "../../EntryFile/datatable";
import Tabletop from "../../EntryFile/tabletop"
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {
  ClosesIcon,
  Noimage,
  Excel,
  Filter,
  Pdf,
  PlusIcon,
  Printer,
  Search,
  search_whites,
  EditIcon,
  DeleteIcon,
} from "../../EntryFile/imagePath";
import axios from "axios";
import { baseURL } from "../../InitialPage/Authentecation";
import { Country, State }  from 'country-state-city';
import Select from "react-dropdown-select";
import { useTranslation } from "react-i18next";

const InstituteList = () => {  
  const {t} = useTranslation();
  const options = [];
  const options1 = [];

const loadData = ()=>{
Country.getAllCountries().map((item)=>{
  return(
    options.push({label: item.name , value:item.isoCode})
  )
});
}
  loadData();
  const [value , setValue] = useState([]);
  const [value1 , setValue1] = useState([]);
  const [formData , setFormData] = useState({});
  const handleChangeCountry = (value)=>{
    setValue1(value) ; 
    setFormData({
      Country : value[0]?.label
    })
  }
  const handleChangeCity = (value) => {
    setValue(value);
    setFormData({
      ...formData , 
      City : value[0]?.label
    })
  }
  value1.map((item)=>{
    options1.length = 0 ;
    return(
      State.getStatesOfCountry(item?.value).map((item )=>{
          options1.push({label:item.name , value: Math.random()})
      })
    )
  })

  const handleChangeName = (e)=>{
    setFormData({
      ...formData , 
      PartialName: e.target.value
    })
  }
  const handleTest=()=>{
    const apiURL =`${baseURL}/Courses/Institute?PartialName=${formData.PartialName? formData.PartialName : ""}&City=${formData.City? formData.City : ""}&Country=${formData.Country? formData.Country : ""}&WithImages=${pagination.WithImages}&PageIndex=${pagination.PageIndex}&PageSize=${pagination.PageSize}`;
    axios.get(apiURL,{
      headers:{
        Authorization : `Bearer ${window.localStorage.getItem('result')}`
      }
    })
    .then((res)=> {
      setData(res.data.result)
      setCountItems(res.data.totalItems)
    })
    .catch(err => console.log(err))
  }
  const ImageDecoder = (imgDecoder) => {
    // Decode base64 string to binary
    const binaryString = atob(imgDecoder);
  
    // Convert binary string to Uint8Array
    const uint8Array = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }
  
    // Create a Blob from Uint8Array
    const blob = new Blob([uint8Array], { type: "image/jpeg" });
  
    // Create a data URL from the Blob
    const imageUrl = URL.createObjectURL(blob);
  
    return imageUrl ; 
  };
  const [inputfilter, setInputfilter] = useState(false);
  
  const togglefilter = (value) => {
    setInputfilter(value);
  };

  
  const [data , setData] = useState([]);
  const [countItems , setCountItems] = useState(0);
  const [pagination , setPagination] = useState({
    PageIndex : 1 , 
    PageSize : 10 ,
    WithImages: true,
  });

  let data1 = [] ; 
  const newName = "id" ;

  data?.map(item=>{
    data1.push({
      ...item , 
      [newName]: item.Id , 
    })   
  })

  data1.map(item => delete item.Id);

  let title , icon = "";
  const confirmText = (id) => {
    Swal.fire({
      title: `${t('Are you sure?')}`,
      text: `${t("You won't be able to revert this!")}`,
      type: "warning",
      showCancelButton: !0,
      confirmButtonText: `${t('Yes, delete it!')}`,
      cancelButtonText : `${t('Cancel')}`,
      customClass:{
        confirmButton : "btn btn-primary",
        cancelButton : "btn btn-primary"
      },
      buttonsStyling: !1,
    })
    .then(function (n) {
      if(n.value){
       try{
        Swal.fire({
          title: `${t('Now Loading')}` , 
          allowEscapeKey: false , 
          allowOutsideClick : false ,
          didOpen:()=>{
            Swal.showLoading();
            axios.delete(`${baseURL}/Courses/Institute/${id}`, {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem("result")}`,
              },
            }).then(res =>{
              if(res.data.valid){
                title = `${t('Finished!')}`;
                icon = "success";
                setData(data.filter(item => item.Id !== id));
                setCountItems(data.length - 1);
                Swal.close();
              }else{
                  title = `${t('Institute Not Deleted')}` ;
                  icon = "error" ;
                  Swal.close();
              }
            })
          },
          didClose:()=>{
            Swal.fire({
              title: title ,  
              icon: icon , 
              timer : "5000" , 
              showConfirmButton : false
            })
          }
        })
       }catch(err){
        console.error(err);
       }
      }
    });
  };
  const changePageSizeAndPageIndex = (index , size)=>{
    setPagination({WithImages:true , PageIndex: index , PageSize:size});
  }

  const [isNetwork , setIsNetwork] = useState();
  useEffect(()=>{
    const fetchData = async ()=>{
     try{
       const apiURL =`${baseURL}/Courses/Institute?PartialName=&City=&Country=&WithImages=${pagination.WithImages}&PageIndex=${pagination.PageIndex}&PageSize=${pagination.PageSize}`;
       const result = await axios.get(apiURL,{
         headers:{
           Authorization : `Bearer ${window.localStorage.getItem('result')}`
         }
       })
       setData(result.data.result);
       setCountItems(result.data.totalItems);
       setIsNetwork(true);
     }catch(err){
       console.error("Error Handling Data" , err);
       setIsNetwork(false);
     }
    };
    fetchData();
  },[pagination]);

  const columns = [
    {
      title: `${t('Image')}`,
      dataIndex: "Image",
      render: (text, record) => (
        <div className="productimgname">
          <Link to="#" style={{ width: "70%" }} className="product-img">
            <img alt="" src={ImageDecoder(record.Image)} />
          </Link>
        </div>
      ),
      width: "130px",
    },
    {
      title: `${t('Name')}`,
      dataIndex: "NameEn",
      sorter: (a, b) => a.FirstNameEn.length - b.FirstNameEn.length,
    },
    {
      title: `${t('Phone')}`,
      dataIndex: "Phone",
    },
    {
      title: `${t('Email')}`,
      dataIndex: "Email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: `${t('City')}`,
      dataIndex: "City",
      sorter: (a, b) => a.City.length - b.City.length,
    },
    {
      title: `${t('Action')}`,
      render: (text , record) => (
        <>
          <Link className="me-3" to={`/Kader/people/editInstitute-people/${record.id}?IncludeCourses=false&WithImage=true`}>
            <img src={EditIcon} alt="img" />
          </Link>
          <Link className="confirm-text" to="#" onClick={()=>confirmText(record.id)}>
            <img src={DeleteIcon} alt="img" />
          </Link>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>{t('Institute List')}</h4>
              <h6>{t('Manage your Institutes')}</h6>
            </div>
            <div className="page-btn">
              <Link
                to="/Kader/people/addInstitute-people"
                className="btn btn-added"
              >
                <img src={PlusIcon} alt="img" className="me-1" />
                {t('Add Institute')}
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
            <Tabletop inputfilter={inputfilter} togglefilter={togglefilter} />
              {/* /Filter */}
              <div
                className={`card mb-0 ${ inputfilter ? "toggleCls" : ""}`}
                id="filter_inputs"
                style={{ display: "block"}}
              >
                <div className="card-body pb-0">
                  <div className="row" style={{visibility: inputfilter ? "visible" : "hidden"}}>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="form-group">
                        <label htmlFor="filterPlaceholder">{t('Name')}</label>
                        <input type="text" placeholder={t('Name')} 
                         id="filterPlaceholder" onChange={handleChangeName}/>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('Country')}</label>
                    <Select
                    name="Select"
                    style={{borderColor:"rgba(145,158,171,0.32",padding:"9px" , borderRadius:"5px"}}
                    options={options}
                    onChange={handleChangeCountry}
                    placeholder={t('Search')}
                    >
                    </Select>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-6 col-12">
                  <div className="form-group">
                    <label>{t('City')}</label>
                    <Select
                     name="Select"
                     style={{borderColor:"rgba(145,158,171,0.32",padding:"9px" , borderRadius:"5px"}}
                     options={options1}
                     placeholder={t('Search') + '...'}
                     onChange={handleChangeCity}
                    >
                    </Select>
                  </div>
                </div>
                    <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                      <div className="form-group">
                          <br></br>
                        <button className="btn btn-filters" onClick={handleTest}>
                          <img src={search_whites} alt="img" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              {/* {isNetwork ? (data.length? <div className="table-responsive" style={{marginTop : inputfilter ? "0px" : "-90px"}}>
                <Table columns={columns} dataSource={data1} handleChange={changePageSizeAndPageIndex} totalItems={countItems} />
              </div>: <div className="loading-container" style={{marginTop:"-50px"}}>
                <div className="loading-spinner"></div>
              </div>) : <div className="table-responsive" style={{marginTop : inputfilter ? "0px" : "-90px"}}>
                <Table columns={columns} dataSource={data1} handleChange={changePageSizeAndPageIndex} totalItems={countItems} />
              </div>} */}
               <div className="table-responsive" style={{marginTop : inputfilter ? "0px" : "-90px"}}>
                <Table columns={columns} dataSource={data1} handleChange={changePageSizeAndPageIndex} totalItems={countItems} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </>
  );
};
export default InstituteList;
