/* eslint-disable react/prop-types */
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import Purchaseorder from './purchaseorder'
import Inventry from './inventry'
import Sales from './sales'
import Invoices from './Companies'
import Purchase from './purchase'
import Supplier from './supplier'
import Customer from './customer'
import addCompany from './addCompany';
import JobCategoryList from './JobCategory';
import AddJobCategory from './addJobCategory';
import EditCompany from './editCompany';
import EditJobCategory from './editJobCategoryList';





const AppIndex = ({ match}) =>(
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/purchaseorderreport`} />
        <Route path={`${match.url}/Companies/addCompany`} component={addCompany} />
        <Route path={`${match.url}/Companies/editCompany/:id`} component={EditCompany}/>
        <Route path={`${match.url}/jobCategoryList`} component={JobCategoryList} />
        <Route path={`${match.url}/jobCategoryListEdit/:id`} component={EditJobCategory}/>
        <Route path={`${match.url}/addjobCategoryList`} component={AddJobCategory} />
        <Route path={`${match.url}/purchaseorderreport`} component={Purchaseorder} />
        <Route path={`${match.url}/inventoryreport`} component={Inventry} />
        <Route path={`${match.url}/salesreport`} component={Sales} />
        <Route path={`${match.url}/Companies`} component={Invoices} />
        <Route path={`${match.url}/purchasereport`} component={Purchase} />
        <Route path={`${match.url}/supplierreport`} component={Supplier} />
        <Route path={`${match.url}/customerreport`} component={Customer} />

    </Switch>
)

export default AppIndex