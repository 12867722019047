import React, { useState } from 'react'
import { Upload } from '../../EntryFile/imagePath';
import Swal from "sweetalert2";
import axios from "axios";
import { baseURL } from "../../InitialPage/Authentecation";
import {useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const AddJobCategory = () => {
  const {t} = useTranslation();
    const history = useHistory();
    const [formData , setFormData] = useState({});
    
    // To handle Change 
    const handleChange = (e)=>{
        const {name , value} = e.target ; 
        setFormData({
          ...formData , 
          [name] : value
        })
      }

    // To handle Image  
  const handleFileChange = (e)=>{
    const file = e.target.files[0] ;
      setFormData({
        ...formData , 
        Image : file
      })
}

let title , icon = "";
const handleSubmit = (e)=>{
    e.preventDefault();
    Swal.fire({
      title: `${t('Now Loading')}` , 
      allowEscapeKey: false , 
      allowOutsideClick : false ,
      didOpen:()=>{
        Swal.showLoading();
        axios.post(`${baseURL}/Careers/JobCategory`, formData,{
          headers:{
            Authorization : `Bearer ${window.localStorage.getItem("result")}`,  
            "Content-Type" :"multipart/form-data"
          }
        }).then((res)=> {
          if(res.data.message === "Saved Successfully"){
            title = `${t('Finished!')}`;
            icon = "success";
            Swal.close();
            history.replace("/Kader/Carrers/jobCategoryList");
          }if(res.data.message === "Already Existing"){
            title = `${t('Category is Already Exist')}` ;
            icon = 'warning' ;
            Swal.close();
          }
        }).catch(()=>{
          Swal.fire({
            title: `${t('Faild Process')}` ,
            text : `${t('Try Again')}` , 
            timer: 3000,
            showConfirmButton: false 
          })
        })
      },
      didClose:()=>{
        Swal.fire({
          title: title ,  
          icon: icon , 
          timer : "5000" , 
          showConfirmButton : false
        })
      }
    })
}
return (
        <>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="page-title">
                            <h4>{t('Add Job Category')}</h4>
                            <h6>{t('Create new Job Category')}</h6>
                        </div>
                    </div>
                    {/* /add */}
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>{t('Category Name(En)')}</label>
                                        <input type="text" required name='nameEn' onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>{t('Category Name(Ar)')}</label>
                                        <input type="text" required name='nameAr' onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>{t('Description(En)')}</label>
                                        <textarea className="form-control" name='descriptionEn' required defaultValue={""} maxLength={70} onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>{t('Description(Ar)')}</label>
                                        <textarea className="form-control" name='descriptionAr' required defaultValue={""} maxLength={70} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>{t('SEO')}</label>
                                        <textarea className="form-control" name='seo' />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>{t('Job Category Image')}</label>
                                        <div className="image-upload">
                                            <input type="file" required onChange={handleFileChange}/>
                                            <div className="image-uploads">
                                                <img src={Upload} alt="img" />
                                                <h4>{t('Drag and drop a file to upload')}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <button className="btn btn-submit me-2" type="submit">
                                        {t('Submit')}
                                    </button>
                                    <button  className="btn btn-cancel" onClick={()=> history.push("/Kader/Carrers/jobCategoryList")}>
                                        {t('Cancel')}
                                    </button>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                    {/* /add */}
                </div>
            </div>

        </>
    )
}

export default AddJobCategory;