/* eslint-disable react/prop-types */
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import Joblist from './jobslist'
import Addsales from './postJob'
import Editsales from './editsales'
import Saledetails from './saledetails'
import SalesReturnList from '../Return/SalesReturnList'
import AddSalesReturn from '../Return/AddSalesReturn'
import RecievedJobsApp from './RecievedJob';

const SalesIndex = ({ match}) =>(
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/generalsettings`} />
        <Route path={`${match.url}/Jobslist`} component={Joblist} />
        <Route path={`${match.url}/RecievedJobsApp`} component={RecievedJobsApp} />
        <Route path={`${match.url}/Post-Job`} component={Addsales} />
        <Route path={`${match.url}/edit-Job/:id`} component={Editsales} />
        <Route path={`${match.url}/sales-details`} component={Saledetails} />
        <Route path={`${match.url}/salesreturnlist-return`} component={SalesReturnList} />
        <Route path={`${match.url}/addsalesreturn-return`} component={AddSalesReturn} />



    </Switch>
)

export default SalesIndex