/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState , useEffect} from "react";
import Table from "../../EntryFile/datatable";
import { Link } from "react-router-dom";
import Tabletop from "../../EntryFile/tabletop";
import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  PlusIcon,
  Printer,
  Search,
  MacbookIcon,
  OrangeImage,
  PineappleImage,
  StawberryImage,
  AvocatImage,
  EyeIcon,
  EditIcon,
  DeleteIcon,
  search_whites,
} from "../../EntryFile/imagePath";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import Swal from "sweetalert2";
import axios from "axios";
import { baseURL } from "../../InitialPage/Authentecation";
import { useTranslation } from "react-i18next";

const CategoryList = () => {
    const {t} = useTranslation();
  const ImageDecoder = (imgDecoder) => {
    // Decode base64 string to binary
    const binaryString = atob(imgDecoder);
  
    // Convert binary string to Uint8Array
    const uint8Array = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }
  
    // Create a Blob from Uint8Array
    const blob = new Blob([uint8Array], { type: "image/jpeg" });
  
    // Create a data URL from the Blob
    const imageUrl = URL.createObjectURL(blob);
  
    return imageUrl ; 
  };
  const [data , setData] = useState([]);
  const [countItems , setCountItems] = useState(0);

  const [pagination , setPagination] = useState({
    PageIndex : 1 , 
    PageSize : 10 ,
    WithImages: true,
  });

  let data1 = [] ; 
  const newName = "id" ;
  
  data?.map(item=>{
    data1.push({
      ...item , 
      [newName]: item.Id , 
    })
  })
  
  data1.map(item => delete item.Id);

  let title , icon = "" ;
  const confirmText = (id) => {
    Swal.fire({
      title: `${t('Are you sure?')}`,
      text: `${t("You won't be able to revert this!")}`,
      type: "warning",
      showCancelButton: !0,
      confirmButtonText: `${t('Yes, delete it!')}`,
      cancelButtonText : `${t('Cancel')}`,
      customClass:{
        confirmButton : "btn btn-primary",
        cancelButton : "btn btn-primary"
      },
      buttonsStyling: !1,
    })
    .then(function (n) {
      if(n.value){
       try{
        Swal.fire({
          title: `${t('Now Loading')}` , 
          allowEscapeKey: false , 
          allowOutsideClick : false ,
          didOpen:()=>{
            Swal.showLoading();
            axios.delete(`${baseURL}/Courses/CourseCategory/${id}`, {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem("result")}`,
              },
            }).then(res =>{
              if(res.data.valid){
                title = `${t('Finished!')}`;
                icon = "success";
                setData(data.filter(item => item.Id !== id));
                setCountItems(data.length - 1);
                Swal.close();
              }else{
                  title = `${t('Course Category Not Deleted')}` ;
                  icon = "error" ;
                  Swal.close();
              }
            })
          },
          didClose:()=>{
            Swal.fire({
              title: title ,  
              icon: icon , 
              timer : "5000" , 
              showConfirmButton : false
            })
          }
        })
       }catch(err){
        console.error("Error handling" , err);
       }
      }
    });
  };
 
  const [selectedId , setSelectedId] = useState();
  const chooseSelectUser = (id)=>{
    setSelectedId(id) ; 
  }

  const changePageSizeAndPageIndex = (index , size)=>{
    setPagination({WithImages:true , PageIndex: index , PageSize:size , ShowDeletedUsers:false});
  }

  const [isNetwork , setIsNetwork] = useState();
  const fetchData = async()=>{
    try{
      const apiURL =`${baseURL}/Courses/CourseCategory?WithImages=${pagination.WithImages}&PageIndex=${pagination.PageIndex}&PageSize=${pagination.PageSize}`;
      const result = await axios.get(apiURL, {
        headers:{
          Authorization : `Bearer ${window.localStorage.getItem('result')}`
        }
      })
      setData(result.data.result);
      setCountItems(result.data.totalItems);
      setIsNetwork(true);
    }catch(err){
      console.error("Error Handling Data" , err);
      setIsNetwork(false);
    }
  };

  useEffect(()=>{
    fetchData();
  },[pagination ]);
  const columns = [
    {
      title: `${t('Image')}`,
      dataIndex: "Image",
      render: (text, record) => (
        <div className="productimgname">
          <Link to="#" style={{ width: "70%" }} className="product-img">
            <img alt="" src={ImageDecoder(record.Image)} />
          </Link>
        </div>
      ),
      width: "150px",
    }
    ,
    {
      title: `${t('Category Name')}`,
      dataIndex: "NameEn",
      sorter: (a, b) => a.FirstNameEn.length - b.FirstNameEn.length,
      width : "300px"
    },
    {
      title: `${t('Description')}`,
      dataIndex: "DescriptionEn",
      sorter: (a, b) => a.description.length - b.description.length,
    },
    {
      title: `${t('Action')}`,
      render: (text , record) => (
        <>
          <>
            <Link className="me-3" to={`/Kader/course/editcategory-product/${record.id}?IncludeCourses=false&WithImage=true`}>
              <img src={EditIcon} alt="img" />
            </Link>
            <Link className="confirm-text" to="#" onClick={()=> confirmText(record.id)}>
              <img src={DeleteIcon} alt="img" />
            </Link>
          </>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>{t('Courses Category List')}</h4>
              <h6>{t('View/Add Course Category')}</h6>
            </div>
            <div className="page-btn">
              <Link
                to="/Kader/course/addcategory-product"
                className="btn btn-added"
              >
                <img src={PlusIcon} alt="img" className="me-1" />
                {t('Add Category')}
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
             {/* {isNetwork?(data.length?  <div className="table-responsive">
                <Table columns={columns} dataSource={data1} handleChange={changePageSizeAndPageIndex} handleSelect={chooseSelectUser} totalItems={countItems}/> 
              </div>:<div className="loading-container" style={{marginTop:"50px"}}>
                <div className="loading-spinner"></div>
              </div>): <div className="table-responsive">
                <Table columns={columns} dataSource={data1} handleChange={changePageSizeAndPageIndex} handleSelect={chooseSelectUser} totalItems={countItems}/> 
              </div>} */}
              <div className="table-responsive">
                <Table columns={columns} dataSource={data1} handleChange={changePageSizeAndPageIndex} handleSelect={chooseSelectUser} totalItems={countItems}/> 
                </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </>
  );
};
export default CategoryList;
