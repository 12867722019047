import React from 'react';
import { LoginImage, Logo, MailIcon } from '../EntryFile/imagePath';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import { PropTypes } from 'prop-types' ; 
import * as Yup from 'yup';
import axios from 'axios';
import { baseURL } from './Authentecation';
import { useTranslation } from 'react-i18next';

const ForgotPassword = (props) => {
    const {t} = useTranslation();
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid')
    })

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });


    const onSubmit = (data) => {
        // Handle the form submission logic here
        axios.post(`${baseURL}/Authentication/Account/ForgetPassword` , {...data})
        .then(()=>{
            const encodedData = encodeURIComponent(data.email);
            props.history.push(`/ChangePassword?email=${encodedData}`);
        })
    };

    return (
        <>
            <div className="main-wrapper">
                <Helmet>
                    <title>Forgot Password - Dream POS</title>
                    <meta name="description" content="ForgetPassword page" />
                </Helmet>
                <div className="account-content">
                    <div className="login-wrapper">
                        <div className="login-content">
                            <div className="login-userset ">
                                <div className="login-logo">
                                    <img src={Logo} alt="img" />
                                </div>
                                <div className="login-userheading">
                                    <h3>{t('Forgot Password?')}</h3>
                                    <h4>
                                    {t('Please write your email to be sent a password reset link.')}
                                    </h4>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-login">
                                        <label>{t('Email')}</label>
                                        <div className="form-addons">
                                            <input
                                                type="text"
                                                {...register('email')}
                                                className={` ${errors.email ? 'is-invalid' : ''}`}
                                                placeholder="Enter your email address"
                                                defaultValue="admin@gmail.com"
                                            />
                                            <img src={MailIcon} alt="img" style={{right:"10px"}}/>
                                            <div className="invalid-feedback">{errors.email?.message}</div>

                                        </div>
                                    </div>
                                    <div className="form-login">
                                        <button type="submit" className="btn btn-login">
                                            {t('Submit')}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="login-img">
                            <img src={LoginImage} alt="img" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

ForgotPassword.propTypes = {
    history : PropTypes.any
}
export default ForgotPassword;
