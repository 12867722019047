/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React,{useEffect, useState} from "react";
import { Table } from "antd";
import "./antd.css";
import { itemRender, onShowSizeChange } from "../components/pagination";

const Datatable = ({ props, columns, dataSource , handleChange , handleSelect , totalItems}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

 const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    console.log("New SelectedRowKeys: " , newSelectedRowKeys);
    handleSelect(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <Table
      key={props}
      className="table datanew dataTable no-footer"
      columns={columns}
      dataSource={dataSource}
      pagination={{
        total: totalItems,
        pageSizeOptions:[10 , 20, 50 , 99]
        ,
        showTotal: (total, range) =>
          ` ${range[0]} to ${range[1]} of ${total} items`,
        showSizeChanger: true,
        onShowSizeChange: (current , size)=>{
          onShowSizeChange(current , size) ; 
          handleChange(current , size) ; 
        },
        onChange: (page , pageSize)=>{
          handleChange(page, pageSize);
        } 
      }}
      rowKey={(record) => record.id}
    />
  );
};

export default Datatable;
